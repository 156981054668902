import React from 'react';
import {
  required,
  TextInput,
  SimpleForm,
  Edit,
} from 'react-admin';

import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();

const ApiKeysEdit = () => {
  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm
        toolbar={<EditDeleteConfirmationButton />}
      >
        <TextInput
          label="ra.label.label"
          value=""
          source="label"
          sx={{ marginLeft: '15px' }}
          validate={validateRequired}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ApiKeysEdit;
