import React from 'react';
import { NumberField, Labeled, useRecordContext } from 'react-admin';
import discount from '../../assets/discount.png';

const PriceField = ({ source, priceLabel, parse, options }) => {
  const record = useRecordContext();
  const value = record[source];
  const parseFunction = parse || ((v) => v);
  const formattedRecord = {
    price: parseFunction(value),
  };

  if (priceLabel) {
    return (
      <Labeled label={priceLabel}>
        <NumberField
          style={{ letterSpacing: '-0.02em', color: '#555555' }}
          record={formattedRecord}
          source="price"
          options={options}
        />
      </Labeled>
    );
  }

  if (record?.totalDiscounts) {
    return (
      <div style={{ display: 'flex' }}>
        <NumberField
          style={{ letterSpacing: '-0.02em', color: '#555555' }}
          record={formattedRecord}
          source="price"
          options={options}
        />
        <img src={discount} alt="" style={{ height: '20px', width: '20px', marginLeft: '5px' }} />
      </div>
    );
  }

  return (
    <NumberField
      style={{ letterSpacing: '-0.02em', color: '#555555' }}
      record={formattedRecord}
      source="price"
      options={options}
    />
  );
};

export default PriceField;
