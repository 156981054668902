export const SESSION_STATUSES = {
  searching_courier: 'SEARCHING COURIER',
  awaiting_confirmation: 'AWAITING CONFIRMATION',
  awaiting_stop: 'AWAITING STOP',
  no_couriers_found: 'NO COURIERS FOUND',
  courier_confirmed: 'COURIER CONFIRMED',
  stopped_by_admin: 'STOPPED BY ADMIN',
};

export const SORT_OPTIONS_CHOICES = [
  { name: 'IDLE_FIRST', id: 'idle_first' },
  { name: 'PICKING_UP_FIRST', id: 'picking_up_first' },
];
