import * as React from 'react';
import { NumberInput, required } from 'react-admin';

const validateRequired = required();

const LatLngInput = () => {
  return (
    <>
      <NumberInput source="geo.lat" label="ra.label.lat" validate={validateRequired} />
      <NumberInput source="geo.lng" label="ra.label.lng" validate={validateRequired} />
      <NumberInput source="geo.defaultZoom" label="ra.label.default_zoom" isRequired={false} />
    </>
  );
};
export default LatLngInput;
