import React, { useCallback, useEffect, useState } from 'react';
import {
  IconButton,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  FormControl,
  TextField,
  Switch,
  FormGroup,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslate } from 'react-admin';
import { patchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import { PAYMENT_METHODS } from '../../../config/payment.config';
import useStyles from './styles';
import { REFUSED_ORDER_ACTIONS } from '../RefuseOrderButton/constants';
import url from '../../../config/connection';

const RefuseOrderModal = ({ order, handleClose }) => {
  const isCash = order?.payment?.type === PAYMENT_METHODS.CASH;
  const translate = useTranslate();

  const [error, setError] = useState(true);
  const [refunded, setRefunded] = useState(false);
  const [refusedOrderComment, setRefusedOrderComment] = useState('');
  const [refusedOrderNextSteps, setRefusedOrderNextSteps] = useState('');

  useEffect(() => {
    if (refusedOrderComment) {
      setError(false);
    } else {
      setError(true);
    }
  }, [refusedOrderComment]);

  const { RETURN_TO_OFFICE: returnToOffice, DO_NOTHING: doNothing } = REFUSED_ORDER_ACTIONS;

  const styles = useStyles();

  const postResufedOrderComment = useCallback(
    (action) => {
      if (action === 'save') {
        return patchWithAuthorization(`${url}/order/${order.id}/refuse`, {
          body: JSON.stringify({
            comment: refusedOrderComment,
            shouldRefund: refunded,
            refusedOrderAction: refusedOrderNextSteps,
          }),
        })
          .then((res) => res.json())
          .then(() => handleClose())
          .catch((err) => {
            throw new Error(err);
          });
      }

      return handleClose();
    },
    [refunded, refusedOrderComment, refusedOrderNextSteps, handleClose, order],
  );

  return (
    <>
      <DialogTitle id="customized-dialog-title" className={styles.closeButton}>
        <Typography variant="h2" style={{ fontSize: '20px', opacity: '0.8' }}>
          {translate('ra.label.refusedOrderComment')}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={(e) => {
            e.stopPropagation();
            postResufedOrderComment('close');
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl className={styles.formControl}>
          <TextField
            label={translate('ra.label.comment')}
            multiline
            variant="outlined"
            rows="5"
            margin="normal"
            error={error}
            helperText={translate('ra.validation.required')}
            onChange={(e) => {
              e.stopPropagation();
              setRefusedOrderComment(e.target.value);
            }}
          />
        </FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={refunded}
                onChange={(e) => {
                  e.stopPropagation();
                  setRefunded(e.target.checked);
                }}
                disabled={isCash}
              />
            }
            label={translate('ra.label.refund')}
          />
        </FormGroup>
        <FormControl className={styles.formControl}>
          <InputLabel id="demo-simple-select-label">{translate('ra.label.nextSteps')}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            style={{ width: '35%' }}
            value={refusedOrderNextSteps}
            error={!refusedOrderNextSteps}
            helperText={translate('ra.validation.required')}
            onChange={(e) => {
              e.stopPropagation();
              setRefusedOrderNextSteps(e.target.value);
            }}
          >
            <MenuItem value={returnToOffice}>{translate('ra.label.returnToOffice')}</MenuItem>
            <MenuItem value={doNothing}>{translate('ra.label.doNothing')}</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={(e) => {
            e.stopPropagation();
            postResufedOrderComment('save');
          }}
          color="primary"
          disabled={error || !refusedOrderNextSteps}
        >
          {translate('ra.action.save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default RefuseOrderModal;
