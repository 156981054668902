const formatDateTime = (dateToFormat, editHours) => {
  if (!dateToFormat) {
    return {
      date: '',
      time: '',
    };
  }

  const date = new Date(dateToFormat);
  const year = date.getFullYear();
  const month = date.getMonth();
  const parsedDay = parseDateTime(date.getDate());
  const parsedMonth = parseDateTime(date.getMonth() + 1);
  const parsedHours = parseDateTime(date.getHours());
  const parsedMinutes = parseDateTime(date.getMinutes());
  const parsedSeconds = parseDateTime(date.getSeconds());
  const today = new Date().toLocaleDateString() === date.toLocaleDateString();

  return {
    today,
    date: `${parsedDay}/${parsedMonth}/${year}`,
    formattedDate: `${parsedDay} ${monthNames[month]}`,
    time: editHours
      ? `${parsedHours + editHours}:${parsedMinutes}:${parsedSeconds}`
      : `${parsedHours}:${parsedMinutes}:${parsedSeconds}`,
  };
};

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const parseDateTime = (dataToParse) => {
  return dataToParse > 9 ? dataToParse : `0${dataToParse}`;
};

export default formatDateTime;
