import React from 'react';

import {
  required,
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  useGetList,
  useCreate,
  useRedirect,
  useResourceContext,
  useRefresh,
  number,
  minValue,
} from 'react-admin';

import { useStyles } from '../../styles';

import { TRANSPORT_TYPES, DEFAULT_VALUES, BILL_PHOTO_CONFIG } from '../../constants';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();
const validateMinNumber = [required(), number(), minValue(0.1)];
const validateInterval = [required(), number(), minValue(0)];

const CourierPayoutPolicyCreate = () => {
  const styles = useStyles();
  const [create] = useCreate();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const organizationId = localStorage.getItem('organizationId');
  const { total, isFetched } = useGetList('policy', { filter: { organizationId } });
  const payoutPolicyDefaultValues = () => {
    if (isFetched && !total) {
      return { organizationId, isActive: true };
    }

    return { organizationId, isActive: false };
  };

  const additionalValues = payoutPolicyDefaultValues();

  const policyUpdate = (data) => {
    create(
      'policy',
      { data: { ...data, ...additionalValues } },
      {
        onSuccess: () => {
          redirect('list', resource);
          refresh();
        },
      },
    );
  };

  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm onSubmit={policyUpdate}>
        <TextInput
          label="ra.label.name"
          source="name"
          sx={{ marginLeft: '15px' }}
          validate={validateRequired}
        />
        <NumberInput
          label="ra.label.courier_logout_interval"
          source="courierLogoutInterval"
          className={styles.spaceBetween}
          sx={{ marginLeft: '15px !important' }}
          validate={validateInterval}
        />
        <SelectInput
          label="ra.label.bill_photo_config"
          source="billPhotoConfiguration"
          choices={BILL_PHOTO_CONFIG}
          sx={{ marginLeft: '15px !important', width: '218px' }}
          validate={validateRequired}
        />
        <ArrayInput
          source="courierPayoutSettings"
          className={styles.line}
          defaultValue={DEFAULT_VALUES}
        >
          <SimpleFormIterator disableAdd disableRemove disableReordering>
            <SelectInput
              label="ra.label.transport_type"
              source="type"
              choices={TRANSPORT_TYPES}
              className={styles.spaceBetween}
              validate={validateRequired}
            />
            <NumberInput
              label="ra.label.coefficient_value"
              source="coefficient"
              className={styles.spaceBetween}
              validate={validateMinNumber}
            />
            <ReferenceInput
              label="ra.label.delivery_settings"
              source="deliverySettingId"
              filter={{ organizationId }}
              reference="deliverySettings"
              perPage={150}
              className={styles.spaceBetween}
              validate={validateRequired}
            >
              <SelectInput optionText="name" optionValue="id" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default CourierPayoutPolicyCreate;
