import React from 'react';
import { withStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import { cryptoRandomStringAsync } from 'crypto-random-string';

import styles from './styles';
import { headersObj } from '../../constants';

function PreviewData(props) {
  const { classes, data, fields } = props;
  let reportLabels = [];

  if (fields) {
    reportLabels = Object.keys(fields).filter((key) => fields[key]);
  }

  const renderColumns = (columnkey, row) => {
    if (columnkey && columnkey === 'distance') {
      return row[columnkey] ? `${row[columnkey] / 1000} km` : '';
    }
    if (columnkey && columnkey === 'courierCost') {
      return row[columnkey] ? `${row[columnkey] / 100}` : '';
    }

    return row[columnkey];
  };

  return data.length ? (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {reportLabels.map((label) => (
              <TableCell key={label}>{headersObj[label]}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow key={cryptoRandomStringAsync({ length: 20 })}>
                {reportLabels.map((columnkey) => {
                  return <TableCell key={columnkey}>{renderColumns(columnkey, row)}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  ) : null;
}

export default withStyles(styles)(PreviewData);
