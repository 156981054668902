import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRefresh,
  useShowController,
  useTranslate,
} from 'react-admin';
import { FormControl, FormControlLabel, FormGroup, FormLabel, Switch } from '@mui/material';
import { useController, useForm } from 'react-hook-form';
import { putWithAuthorization } from '../../../../utils/fetchWithAuthorization';
import { baseUrl } from '../../../../config/connection';
import { USER_PERMISSIONS } from '../../../constants';

const AddUserToolbar = (props) => {
  const { disabled } = props;

  return <Toolbar {...props}>{disabled && <SaveButton alwaysEnable />}</Toolbar>;
};

const validate = required();

const AddUserDialog = ({ handleClose, open }) => {
  const [permissions, setPermissions] = useState([]);

  const showController = useShowController();
  const refresh = useRefresh();
  const translate = useTranslate();

  const notify = useNotify();

  const { control, reset } = useForm();
  const { field: userField } = useController({ name: 'userId', control });

  const handleChange = (event) => {
    if (event.target.checked) {
      setPermissions((prev) => [...prev, event.target.name]);
    } else {
      setPermissions((prev) => prev.filter((item) => item !== event.target.name));
    }
  };

  const handleSubmit = async () => {
    try {
      const addPermissionRequest = await putWithAuthorization(
        `${baseUrl}/user/${userField.value}/permission`,
        {
          body: JSON.stringify({
            resourceId: showController.record.id,
            resourceTypeName: 'OrganizationEntity',
            permissions,
          }),
        },
      );

      if (addPermissionRequest?.status > 300) {
        const parsedError = await addPermissionRequest.json();

        throw new Error(parsedError?.message);
      }
    } catch (error) {
      notify(error.message, { type: 'error' });
    }

    reset();
    refresh();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Create sx={{ width: 500, '& .RaCreate-main': { mt: 0 } }}>
          <SimpleForm
            defaultValues={{ index: 0 }}
            onSubmit={handleSubmit}
            toolbar={<AddUserToolbar disabled={!!userField?.value && permissions.length > 0} />}
          >
            <ReferenceInput label="ra.label.user" source="userId" reference="user">
              <AutocompleteInput
                label="ra.label.user"
                optionText="email"
                fullWidth
                validate={validate}
                control={control}
              />
            </ReferenceInput>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Assign permissions</FormLabel>
              <FormGroup>
                {USER_PERMISSIONS.map((permission) => {
                  return (
                    <FormControlLabel
                      key={permission}
                      control={
                        <Switch
                          checked={permissions.includes(permission)}
                          onChange={handleChange}
                          name={permission}
                        />
                      }
                      label={translate(`ra.label.${permission}`)}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </SimpleForm>
        </Create>
      </Dialog>
    </div>
  );
};

export default AddUserDialog;
