import React, { useState } from 'react';
import { Title, useTranslate } from 'react-admin';
import { withStyles } from '@mui/styles';
import { CardContent, Card, Tabs, Tab } from '@mui/material';
import styles from './style';
import CourierReport from './components/CourierReport';

const getDataHeadings = (data) => {
  return data.reduce((keysList, dataRow) => {
    const rowKeys = Object.keys(dataRow);

    return Array.from(new Set([...rowKeys, ...keysList]));
  }, []);
};

const Report = ({ classes }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const translate = useTranslate();

  const handleTabChange = (_, value) => {
    setCurrentTab(value);
  };

  return (
    <Card className={classes.root}>
      <Title title={translate('ra.title.reports')} />
      <CardContent>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          TabIndicatorProps={{ style: { background: '#477890' } }}
          textColor="primary"
          style={{ color: '#477890' }}
        >
          <Tab label={translate('ra.tab.courier')} style={{ color: '#477890' }} />
        </Tabs>
        {currentTab === 0 && <CourierReport getDataHeadings={getDataHeadings} />}
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(Report);
