import { TextField } from '@mui/material';
import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import { DEFAULT_TIME_PERIOD } from './config';

const TimePicker = ({ label, sx: styles, defaultValue, ...props }) => {
  const translate = useTranslate();

  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput(props);

  return (
    <TextField
      {...field}
      error={(isTouched || isSubmitted) && invalid}
      helperText={(isTouched || isSubmitted) && invalid ? error : ''}
      required={isRequired}
      label={translate(label)}
      defaultValue={defaultValue || DEFAULT_TIME_PERIOD}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
      }}
      sx={styles}
      variant="standard"
      id="time"
      type="time"
    />
  );
};

export default TimePicker;
