export const courierReportHeaders = [
  { label: 'Order Id', key: 'orderId' },
  { label: 'Courier Id', key: 'courierId' },
  { label: 'Courier First Name', key: 'courierFirstName' },
  { label: 'Courier Last Name', key: 'courierLastName' },
  { label: 'Client Address', key: 'clientAddress' },
  { label: 'Payment Type', key: 'orderPaymentType' },
  { label: 'Order creation time', key: 'orderOrderedAt' },
  { label: 'Status of delivery', key: 'deliveryStatus' },
  { label: 'Total Price', key: 'orderTotalPrice' },
  { label: 'Courier Cost', key: 'courierCost' },
];

export const MONTHLY_REPORT_HEADERS = [
  { label: 'Order Creation Time', key: 'orderOrderedAt' },
  { label: 'Payment Type', key: 'orderPaymentType' },
  { label: 'Credit Card', key: 'creditCard' },
  { label: 'Credit Card Online', key: 'creditCardOnline' },
  { label: 'Cash', key: 'cashPrice' },
  { label: 'Courier Cost', key: 'courierCost' },
];

export const headersObj = {
  courierId: 'Courier Id',
  courierFirstName: 'Courier First Name',
  courierLastName: 'Courier Last Name',
  orderOrderedAt: 'Order creation time',
  orderId: 'Order Id',
  orderPaymentType: 'Payment Type',
  orderSupplier: 'Supplier',
  orderBranch: 'Branch',
  deliveryStatus: 'Status of delivery',
  clientFullName: 'Client Full Name',
  clientPhone: 'Client Phone',
  clientAddress: 'Client Address',
  orderItemsPrice: 'Items Price',
  orderTotalPrice: 'Total Price',
  courierFullName: 'Courier Full Name',
  orderOriginalPrice: 'Order Original Price',
  validationDuration: 'Validation Duration ',
  confirmationDuration: 'Confirmation Duration',
  courierAssignmentDuration: 'Courier Assignment Duration',
  preparationDuration: 'Preparation Duration',
  courierArrivalDuration: 'Courier Arrival Duration',
  courierWaitDuration: 'Courier Wait Duration',
  restaurantWaitDuration: 'Restaurant Wait Duration',
  deliveryDuration: 'Delivery Duration',
  totalDuration: 'Total Duration',
  fiscalDifference: 'Fiscal Difference',
  distance: 'Distance',
  courierCost: 'Courier Cost',
  isReturnedToOffice: 'Returned To Office',
  isRefunded: 'Refunded',
};

export const ON_CHANGE_ACTION = {
  PAYMENT_METHOD: 'changePaymentMethod',
  REPORT_FIELDS: 'changeField',
};
