import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  timeBlock: {
    marginBottom: 10,
  },
  blockTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  statusHistory: {
    marginTop: 10,
    borderTop: '1px black solid',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: 10,
  },
  statusHistoryItem: {
    marginTop: 4,
  },
  statusName: {
    padding: 0,
    textTransform: 'none',
    backgroundColor: 'transparent',
  },
});

export default useStyles;
