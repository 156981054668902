import React from 'react';
import {
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useTranslate,
} from 'react-admin';

import ResourceTitle from '../../../components/ResourceTitle';
import formatDateTime from '../../../utils/formatDateTime';
import OrganizationUsersTab from '../OrganizationUsersTab';

const OrganizationShow = () => {
  const translate = useTranslate();

  return (
    <Show title={<ResourceTitle field="firstName" />}>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TextField label="ra.label.id" source="id" />
          <TextField label="ra.label.name" source="name" />
          <TextField label="ra.label.slug" source="slug" />
          <FunctionField
            label="ra.label.created_at"
            render={({ createdAt }) => {
              const parsedDateAndTime = formatDateTime(createdAt);

              return `${parsedDateAndTime.date} ${parsedDateAndTime.time}`;
            }}
          />
          <FunctionField
            label="ra.label.updated"
            render={({ updatedAt }) => {
              const parsedDateAndTime = formatDateTime(updatedAt);

              return `${parsedDateAndTime.date} ${parsedDateAndTime.time}`;
            }}
          />
          <FunctionField
            label="ra.label.status"
            render={({ deletedAt }) => {
              return !deletedAt ? translate('ra.label.active') : translate('ra.label.inactive');
            }}
          />
        </Tab>
        <Tab label="ra.tab.organization_users" path="organization">
          <OrganizationUsersTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default OrganizationShow;
