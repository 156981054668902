import React from 'react';
import cn from 'classnames';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useRecordContext, useTranslate } from 'react-admin';
import { ORDER_STATUSES_TRANSLATIONS } from '../../config/statuses.config';
import useStyles from './style';
import formatDateTime from '../../utils/formatDateTime';

const getStatusTranslation = (type, translate) => {
  const translationKey = ORDER_STATUSES_TRANSLATIONS[type];

  return translationKey ? translate(`ra.status.${translationKey}`) : type;
};

const StatusLabel = ({ status, className }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const orderStatus = status || record?.deliveryStatus;
  const styles = useStyles({ status: orderStatus });

  if (record?.scheduled?.scheduledAt) {
    const timeScheduledAt = formatDateTime(record?.scheduled.scheduledAt);
    const isOrderScheduledForToday = timeScheduledAt.today;

    return (
      <div className={cn(styles.scheduledWrapper, className)}>
        <span className={cn(styles.label, className)}>
          {getStatusTranslation(orderStatus, translate)}
        </span>
        <div className={cn(styles.timeWrapper, className)}>
          <AccessTimeIcon style={{ fontSize: 20 }} />
          <span className={cn(styles.timeLabel, className)}>
            {`${!isOrderScheduledForToday ? timeScheduledAt.formattedDate : ''} ${
              timeScheduledAt.time
            }`}
          </span>
        </div>
      </div>
    );
  }

  return (
    <span className={cn(styles.label, className)}>
      {getStatusTranslation(orderStatus, translate)}
    </span>
  );
};

export default StatusLabel;
