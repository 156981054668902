import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  line: {
    '& ul': {
      '& li': {
        borderBottom: 'none !important',
        display: 'flex',
        alignItems: 'center',
        '& section': {
          display: 'flex',
          flexDirection: 'row !important',
          flex: 'none !important',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& p': {
          display: 'none',
        },
      },
    },
  },
  spaceBetween: {
    margin: '10px 7px',
  },
  aaa: {
    backgroundColor: 'red',
  },
});

export const useTableStyles = makeStyles({
  tableMain: () => ({
    '& .column-isActive': {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
});
