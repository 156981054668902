import React, { useCallback, useState } from 'react';
import { Fab, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslate, useRecordContext, useRefresh, useUpdate } from 'react-admin';
import useStyles, { DefaultWrapper } from './style';
import Modal from '../StatusModal';
import { ROLE_STATUSES, DELIVERY_STATUS_NAMES } from '../../../config/statuses.config';
import { USER_ROLES } from '../../../config/user.config';
import {
  courierAssignStatuses,
  courierConfirmedStatuses,
  STATUS_BTN_NAME_MAP,
  courierSourceDetails,
} from './constants';
import DeliveryActions from './DeliveryActions';

const generateButtonName = (stName) => `ra.status.button.${STATUS_BTN_NAME_MAP[stName]}` || stName;

const getIsCancel = (key) => key === DELIVERY_STATUS_NAMES.CANCELED;

const ShowActions = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const orderStatus = record?.paymentStatus;
  const transitions = orderStatus?.transitions;
  const currentStatusKey = orderStatus?.key;
  const deliveryDetails = record?.deliveryDetails;
  const deliveryStatus = record?.deliveryStatus;

  const [modalState, setModalState] = useState(false);
  const [nextStatus, setNextStatus] = useState({ key: '' });
  const [modalType, setModalType] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [update] = useUpdate();

  const styles = useStyles();
  const translate = useTranslate();

  const currentUserStatus = localStorage.getItem('role');
  const currentOrganizationPermissions = localStorage.getItem('organizationPermissions');
  const isEditPermitted = currentOrganizationPermissions.includes('edit');

  const isCurrentUserAdmin = isEditPermitted;
  const isCurrentCourierAssignStatus = courierAssignStatuses?.includes(currentStatusKey);
  const courierSource = deliveryDetails?.courierSource || courierSourceDetails.STANDARD;
  const isStandardCourierSource = courierSource === courierSourceDetails.STANDARD;
  const courierConfirmed = courierConfirmedStatuses?.includes(deliveryStatus);

  const handleTransitionClick = useCallback(
    (nextStatusKey) => {
      const nextIsAccepted = DELIVERY_STATUS_NAMES.ACCEPTED === nextStatusKey;
      const nextIsCanceled = DELIVERY_STATUS_NAMES.CANCELED === nextStatusKey;
      const currentIsProcessing = DELIVERY_STATUS_NAMES.PROCESSING === currentStatusKey;

      setDisabled(true);

      if ((nextIsAccepted && currentIsProcessing) || nextIsCanceled) {
        setNextStatus({ key: nextStatusKey });
        setModalType('timeConfirm');

        return setModalState(true);
      }

      update(
        'order',
        {
          id: record.id,
          data: { ...record, supplierStatus: nextStatusKey, previousData: record },
        },
        {
          onSuccess: () => {
            setDisabled(false);
            refresh();
          },
        },
      );
    },
    [currentStatusKey, record, update, refresh],
  );

  if (!record || !record.deliveryStatus) {
    return <DefaultWrapper />;
  }

  if (!ROLE_STATUSES[courierSource][currentUserStatus]?.includes(currentStatusKey)) {
    return (
      <DefaultWrapper className={styles.assignCourierWrapper}>
        <DeliveryActions
          isCurrentUserAdmin={isCurrentUserAdmin}
          isCurrentCourierAssignStatus={isCurrentCourierAssignStatus}
          isStandardCourierSource={isStandardCourierSource}
          courierConfirmed={courierConfirmed}
          record={record}
        />
      </DefaultWrapper>
    );
  }

  const sortedTransitions = transitions.sort(({ key }) => getIsCancel(key));

  return (
    <DefaultWrapper className={styles.root}>
      <Modal
        open={modalState}
        nextStatus={nextStatus}
        handleClose={() => {
          setDisabled(false);
          setModalState(false);
        }}
        changeOrderStatus={update}
        currentOrder={record}
        modalType={modalType}
      />
      <>
        {sortedTransitions.map(({ key }) => {
          const isCancel = getIsCancel(key);

          return (
            <Tooltip title={translate(generateButtonName(key))} key={key}>
              <Fab
                size="small"
                color={isCancel ? 'error' : 'primary'}
                disabled={disabled}
                className={styles.statusButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTransitionClick(key);
                }}
              >
                {isCancel ? <CloseIcon /> : <CheckIcon />}
              </Fab>
            </Tooltip>
          );
        })}
        {isCurrentUserAdmin && (
          <DeliveryActions
            isCurrentUserAdmin={isCurrentUserAdmin}
            isCurrentCourierAssignStatus={isCurrentCourierAssignStatus}
            isStandardCourierSource={isStandardCourierSource}
            courierConfirmed={courierConfirmed}
            record={record}
          />
        )}
      </>
    </DefaultWrapper>
  );
};

export default ShowActions;
