import React from 'react';
import {
  Tab,
  Show,
  TextField,
  FunctionField,
  TabbedShowLayout,
} from 'react-admin';

import ShowActions from '../ShowActions';
import ResourceTitle from '../../../components/ResourceTitle';
import { formatPhoneNumber } from '../../../utils/parsePhoneNumber';

const UserShow = (props) => {
  return (
    <Show title={<ResourceTitle field="username" />} actions={<ShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TextField label="ra.label.id" source="id" />
          <TextField label="ra.label.email" source="email" />
          <TextField label="ra.label.first_name" source="firstName" />
          <TextField label="ra.label.last_name" source="lastName" />
          <FunctionField source="phone" render={({ phone }) => formatPhoneNumber(phone)} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
