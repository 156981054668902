import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumber = (phone) => {
  const isValidPhone = isValidPhoneNumber(phone || '');

  if (phone && isValidPhone) {
    const phoneNumber = parsePhoneNumber(phone);

    return phoneNumber.formatInternational();
  }

  return phone;
};
