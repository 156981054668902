export const COURIER_REPORT_FIELDS = {
  ORDER_ID: 'orderId',
  COURIER_ID: 'courierId',
  COURIER_FIRST_NAME: 'courierFirstName',
  COURIER_LAST_NAME: 'courierLastName',
  COURIER_COST: 'courierCost',
  CLIENT_ADDRESS: 'clientAddress',
  ORDER_PAYMENT_TYPE: 'orderPaymentType',
  ORDER_DELIVERY_PRICE: 'orderDeliveryPrice',
  ORDER_ORDERED_AT: 'orderOrderedAt',
  ORDER_DELIVERY_STATUS: 'deliveryStatus',
  ORDER_TOTAL_PRICE: 'orderTotalPrice',
};

export const DEFAULT_REPORT_FIELDS = {
  COURIER: {
    [COURIER_REPORT_FIELDS.ORDER_ID]: true,
    [COURIER_REPORT_FIELDS.COURIER_ID]: true,
    [COURIER_REPORT_FIELDS.COURIER_FIRST_NAME]: true,
    [COURIER_REPORT_FIELDS.COURIER_LAST_NAME]: true,
    [COURIER_REPORT_FIELDS.CLIENT_ADDRESS]: true,
    [COURIER_REPORT_FIELDS.ORDER_PAYMENT_TYPE]: true,
    [COURIER_REPORT_FIELDS.ORDER_ORDERED_AT]: true,
    [COURIER_REPORT_FIELDS.ORDER_DELIVERY_STATUS]: true,
    [COURIER_REPORT_FIELDS.ORDER_TOTAL_PRICE]: true,
    [COURIER_REPORT_FIELDS.COURIER_COST]: true,
  },
};

export default DEFAULT_REPORT_FIELDS;
