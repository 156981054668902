export const deliveryStatusesOptions = () => [
  { id: 'NEW', name: 'New' },
  { id: 'ACCEPTED', name: 'Accepted' },
  { id: 'CANCELED', name: 'Canceled' },
  { id: 'DELIVERING', name: 'Delivering' },
  { id: 'DELIVERED', name: 'Delivered' },
  { id: 'PROCESSING', name: 'Processing' },
  { id: 'PREPARING', name: 'Preparing' },
  { id: 'PREPARED', name: 'Prepared' },
  { id: 'SCHEDULED', name: 'Scheduled' },
  { id: 'CONFIRMED', name: 'Confirmed' },
  { id: 'REFUSED', name: 'Refused' },
  { id: 'COURIER_ASSIGNED', name: 'Courier assigned' },
  { id: 'COURIER_CONFIRMED', name: 'Courier confirmed' },
  { id: 'COURIER_REJECTED', name: 'Courier rejected' },
  { id: 'COURIER_IS_IN_RESTAURANT', name: 'Courier is in restaurant' },
];
