import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, TablePagination } from '@mui/material';
import { useShowController, useTranslate } from 'react-admin';
import Row from './TableRow';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import url from '../../../config/connection';
import AddUserDialog from './AddUserDialog';

function createData({ email, firstName, lastName, phone, role, permissions = [], id }) {
  return {
    email,
    firstName,
    lastName,
    phone,
    role,
    permissions,
    id,
  };
}

export default function OrganizationUsersTab() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [open, setOpen] = useState(false);

  const translate = useTranslate();

  const showContext = useShowController();

  useEffect(() => {
    const { id: resourceId } = showContext.record;

    const getPermissions = async () => {
      const result = await fetchWithAuthorization(`${url}/organization/${resourceId}/users`);
      const usersWithPermissions = await result.json();
      const filteredUsers = usersWithPermissions.filter(
        (user) => Boolean(user.id) && !user.deletedAt,
      );

      const parsedUsers = filteredUsers.map((user) => createData(user));

      setRows(parsedUsers);
    };

    getPermissions();
  }, [showContext.record]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Paper>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{translate('ra.label.email')}</TableCell>
                <TableCell align="right">{translate('ra.label.first_name')}</TableCell>
                <TableCell align="right">{translate('ra.label.last_name')}</TableCell>
                <TableCell align="right">{translate('ra.label.phone')}</TableCell>
                <TableCell align="right">{translate('ra.action.edit_permissions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return <Row key={row.email} row={row} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <AddUserDialog open={open} handleClose={handleClose} users={rows} />
      <Button
        variant="contained"
        sx={{ maxWidth: 200, marginTop: '30px' }}
        onClick={handleClickOpen}
      >
        Add user
      </Button>
    </>
  );
}
