import React, { useMemo, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useTranslate } from 'react-admin';

import Row from '../SessionTableRow';
import url from '../../../config/connection';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import createData from '../../helpers/createSessionData';
import usePollingEffect from '../../../hooks/usePollingEffect';

const SessionTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sessions, setSessions] = useState([]);

  const translate = useTranslate();

  const rows = useMemo(() => {
    return (
      sessions?.map((session) => {
        const details = {
          startTime: session.startTime,
          retryTimes: session.retryTimes,
          courierUnconfirmed: session.courierUnconfirmed,
          courierAssignedTime: session.courierAssignedTime,
        };

        return createData(
          session.deliveryRequestId,
          session.currentCourierUserId,
          session.sessionStatus,
          details,
          session.courierAssignedLogs, // is not used at the moment
        );
      }) || []
    );
  }, [sessions]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  usePollingEffect(
    async () => {
      try {
        const fetchedData = await fetchWithAuthorization(`${url}/courier-auto-settings`);
        const response = await fetchedData.json();

        const sortedSessions = response.data[0].sessions.sort(
          (a, b) => new Date(a.startTime) - new Date(b.startTime),
        );

        return setSessions(() => sortedSessions);
      } catch (error) {
        throw new Error(error.message);
      }
    },
    [], // dependencies
    { interval: 15000 }, // interval to poll data from api
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">{translate('ra.label.deliveryRequestId')}</TableCell>
            <TableCell align="center">{translate('ra.label.courierUserId')}</TableCell>
            <TableCell align="center">{translate('ra.label.sessionStatus')}</TableCell>
            <TableCell align="center">{translate('ra.label.stopAutomation')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <Row key={row.deliveryRequestId} row={row} />
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Table>
    </TableContainer>
  );
};

export default SessionTable;
