import { makeStyles } from '@mui/styles';
import { DELIVERY_STATUS_NAMES, ORDER_STATUSES_COLOR } from '../../config/statuses.config';

const useStyles = makeStyles({
  label: ({ status }) => ({
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    width: '151px',
    color: ORDER_STATUSES_COLOR[status],
    backgroundColor: status === DELIVERY_STATUS_NAMES.DELIVERED ? '#F0F0F0' : 'transparent',
    padding: '6px 8px',
    borderRadius: 2,
  }),
  timeWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 7px 0',
  },
  timeLabel: {
    display: 'block',
    fontSize: '14px',
    padding: '0 5px',
  },
  scheduledWrapper: {
    padding: '5px 0',
    minWidth: '125px',
    maxWidth: '150px',
  },
});

export default useStyles;
