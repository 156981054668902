import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Card } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getConfig } from '../../../utils/getInitialConfig';

const OrderItem = ({ orderItem: { quantity, name, price, totalPrice, basePrice } }) => (
  <div>
    {`${quantity}x ${name} | ${totalPrice / 100} ${getConfig('currency')} (${
      basePrice / 100
    } ${getConfig('currency')})`}
  </div>
);

const ItemWithOptions = ({ orderItem }) => {
  const { quantity, options } = orderItem;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <OrderItem orderItem={orderItem} />
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {options.map((option, index) => {
            const optionDivider = index < options.length - 1 ? ',' : '';
            const optionPrice =
              option.price === 0 ? '' : `| ${option.price / 100} ${getConfig('currency')}`;

            return (
              <span key={option.id} style={{ display: 'block' }}>
                {`${quantity}x ${option.name} ${optionPrice}${optionDivider}`}
              </span>
            );
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const OrderItemsList = ({ orderItems }) => {
  return orderItems.map((orderItem) => {
    const key = `${orderItem.id}${
      orderItem.options ? orderItem.options.reduce((acc, { id }) => `${acc}+${id}`, '') : ''
    }`;

    return orderItem.options && orderItem.options.length ? (
      <ItemWithOptions orderItem={orderItem} key={key} />
    ) : (
      <Card key={key} style={{ padding: '10px 25px' }}>
        <OrderItem orderItem={orderItem} />
      </Card>
    );
  });
};

export default OrderItemsList;
