import { ClearOutlined, DoneOutlined } from '@mui/icons-material';
import React from 'react';
import {
  BooleanField,
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

const WebHooksShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TextField label="ra.label.callbackUrl" source="callbackUrl" />
          <FunctionField
            source="events"
            label="ra.label.events"
            render={({ events }) => {
              return events.join(', ');
            }}
          />
          <BooleanField label="ra.label.enabled" source="enabled" />
          <FunctionField
            label="ra.label.secured"
            source="credentials"
            render={({ credentials }) => {
              return credentials ? (
                <DoneOutlined fontSize="small" />
              ) : (
                <ClearOutlined fontSize="small" />
              );
            }}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default WebHooksShow;
