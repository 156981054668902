import React from 'react';
import { List, Datagrid, TextField, FunctionField } from 'react-admin';
import { USER_ROLES } from '../../../config/user.config';
import { PostFilter } from '../PostFilter';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import { ListTopToolBar } from '../../../components/ListTopToolBar';
import { formatPhoneNumber } from '../../../utils/parsePhoneNumber';

const UserList = (props) => {
  const role = localStorage.getItem('role');
  const filter = role === USER_ROLES.ADMIN;

  return (
    <List {...props} {...filter} filters={<PostFilter />} actions={<ListTopToolBar />}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField source="id" />
        <TextField source="email" />
        <FunctionField source="phone" render={({ phone }) => formatPhoneNumber(phone)} />
        <TextField source="firstName" />
        <TextField source="lastName" />
      </Datagrid>
    </List>
  );
};

export default UserList;
