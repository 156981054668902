import { Button, Dialog } from '@mui/material';
import React, { useState } from 'react';
import MapIcon from '@mui/icons-material/Map';
import { useTranslate } from 'react-admin';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import './styles.css';

const markerIcon = new L.Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.8.0/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.8.0/dist/images/marker-icon-2x.png',
  iconSize: new L.Point(25, 41),
});

const ShowAddressOnMap = ({ deliveryAddress }) => {
  const [open, setOpen] = useState(false);
  const { locality, street, buildingNumber, geo } = deliveryAddress;
  const orderGeo = [geo?.lat, geo?.lng];

  const translate = useTranslate();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: '#325E8C',
          marginBottom: '10px',
          marginTop: '10px',
          width: '100%',
        }}
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        <MapIcon />
        {translate('ra.title.map')}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <MapContainer center={orderGeo} zoom={50} scrollWheelZoom>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={orderGeo} icon={markerIcon}>
            <Popup>{[locality, street, buildingNumber].filter(Boolean).join(', ')}</Popup>
          </Marker>
        </MapContainer>
      </Dialog>
    </>
  );
};

export default ShowAddressOnMap;
