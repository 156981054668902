const MARGIN_TOP_MULTIPLIER = 3;

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(MARGIN_TOP_MULTIPLIER),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

export default styles;
