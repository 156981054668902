import { makeStyles } from '@mui/styles';

const translate = 'translate(-50%, -50%)';
const scale = 'scale(1.4)';
const selectedBackgroundColor = 'rgba(255,165,0, 0.8)';

const useStyles = makeStyles(() => ({
  icon: {
    transition: 'all 0.1s ease-in',
    transform: translate,
    '&:hover': {
      transform: `${translate} ${scale}`,
    },
  },
  hovered: {
    transform: `${translate} ${scale}`,
  },
  balloon: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  mainArea: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selected: {
    backgroundColor: selectedBackgroundColor,
    '&:hover': {
      backgroundColor: selectedBackgroundColor,
    },
  },
}));

export default useStyles;
