import CourierPayoutPolicyList from './components/List';
import CourierPayoutPolicyCreate from './components/Create';
import CourierPayoutPolicyEdit from './components/Edit';
import CourierPayoutPolicyShow from './components/Show';

export default {
  list: CourierPayoutPolicyList,
  create: CourierPayoutPolicyCreate,
  edit: CourierPayoutPolicyEdit,
  show: CourierPayoutPolicyShow,
};
