import React, { useState } from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import 'react-day-picker/lib/style.css';
import Switch from '@mui/material/Switch';
import { useTranslate } from 'react-admin';
import { headersObj, ON_CHANGE_ACTION } from '../../constants';
import { PAYMENT_METHODS_LABELS } from '../../../config/payment.config';
import { styles } from './style';

const CheckBoxTable = ({ data, onChangeAction, type }) => {
  const [allTrue, setAllTrue] = useState(true);
  const dataArr = Object.keys(data);
  let labels = headersObj;

  const translate = useTranslate();

  if (type === ON_CHANGE_ACTION.PAYMENT_METHOD) {
    labels = PAYMENT_METHODS_LABELS;
  }

  return (
    <>
      <FormGroup row style={styles.formGroup}>
        {dataArr.map((checkbox) => {
          return (
            <FormControlLabel
              key={`formcontrol-${checkbox}`}
              control={
                <Checkbox
                  key={checkbox}
                  color="primary"
                  style={{ color: '#477890' }}
                  checked={data[checkbox]}
                  onChange={(event) => {
                    onChangeAction(checkbox, event.target.checked);
                  }}
                  value={checkbox}
                />
              }
              label={labels[checkbox]}
            />
          );
        })}
      </FormGroup>
      <FormControlLabel
        style={{ marginTop: '12px' }}
        control={
          <Switch
            color="primary"
            checked={allTrue}
            onChange={() => {
              setAllTrue((prevStatus) => !prevStatus);
              dataArr.forEach((checkbox) => {
                onChangeAction(checkbox, !allTrue);
              });
            }}
          />
        }
        label={translate('ra.action.select_all')}
      />
    </>
  );
};

export default CheckBoxTable;
