import React, { useCallback } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './style.css';

// TODO: Update react-day-picker library to new version

const adjustRange = (currentRange) => {
  if (!currentRange.to) {
    return currentRange;
  }

  let { from: currentFrom, to: currentTo } = currentRange;

  if (currentFrom === currentTo) {
    currentFrom = new Date(currentFrom);
    currentTo = new Date(currentTo);
  }

  currentFrom.setHours(0, 0, 0);
  currentTo.setHours(23, 59, 59);

  return {
    from: currentFrom,
    to: currentTo,
  };
};

const TimeRangePicker = ({ range, changeRange }) => {
  const { from, to } = range;
  const modifiers = { start: from, end: to };

  const handleDayClick = useCallback(
    (day) => {
      const newRange = DateUtils.addDayToRange(day, range);
      const adjustedRange = adjustRange(newRange);

      changeRange(adjustedRange);
    },
    [changeRange, range],
  );

  return (
    <div>
      <DayPicker
        className="Selectable"
        selectedDays={[from, { from, to }]}
        modifiers={modifiers}
        onDayClick={handleDayClick}
      />
    </div>
  );
};

export default TimeRangePicker;
