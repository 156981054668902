import React, { useEffect, useState } from 'react';
import './prevent-roboto';
import './config/firebase';
import { Admin, CustomRoutes, resolveBrowserLocale } from 'react-admin';
import { Provider } from 'react-redux';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { BrowserRouter, Route } from 'react-router-dom';
import url from './config/connection';
import authProvider from './services/authProvider.service';
import MyLayout from './components/Layout/MyLayout';
import renderRoutes from './services/routes.service';
import roTranslations from './translations/roTranslations';
import ruTranslations from './translations/ruTranslations';
import enTranslations from './translations/enTranslations';
import LoginWithTheme from './components/Layout/Login/Login';
import customStore from './redux';
import dataProvider from './services/dataProvider.service';
import { fetchWithAuthorization } from './utils/fetchWithAuthorization';
import { getConfig } from './utils/getInitialConfig';
import { USER_ROLES } from './config/user.config';
import ResetPassword from './components/Layout/ResetPassword';

const translations = {
  ro: { ...roTranslations },
  ru: { ...ruTranslations },
  en: { ...enTranslations },
};

const tokenFromQuery = window.location.href.match(/\?.+/);

if (tokenFromQuery) {
  const params = new URLSearchParams(tokenFromQuery[0]);
  const authToken = params.get('token');
  const refreshToken = params.get('refreshToken');
  const expirationTime = params.get('expirationTime');
  const role = params.get('role');
  const organizationPermissions = params.get('organizationPermissions');
  const organizationId = params.get('organizationId');
  const organizationGeo = params.get('organizationGeo');

  if (authToken && organizationPermissions && role) {
    localStorage.setItem('token', authToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('role', role);
    localStorage.setItem('organizationPermissions', organizationPermissions);
  }

  if (role !== USER_ROLES.ADMIN) {
    localStorage.setItem('organizationId', organizationId);
    localStorage.setItem('organizationGeo', organizationGeo);

    window.location.href = window.location.origin;
  }
}

const App = () => {
  const [isLoading, setIsLoading] = useState(false);

  const currentLocale = localStorage.getItem('currentLocale') || getConfig('defaultLocale');

  const i18nProvider = polyglotI18nProvider((locale) => translations[locale], currentLocale, {
    allowMissing: true,
  });

  useEffect(() => {
    setIsLoading(true);

    fetchWithAuthorization(`${url}/config`)
      .then((results) => results.json())
      .then((data) => {
        // Each time will reset data from localStorage
        localStorage.setItem(
          'config',
          JSON.stringify({ ...data, defaultLocale: data.defaultLocale.toLowerCase() }),
        );

        if (!localStorage.getItem('currentLocale')) {
          localStorage.setItem('currentLocale', data.defaultLocale.toLowerCase());
        }

        // will set document title from config, not hardcoded
        document.title = getConfig('title');

        setIsLoading(false);
      })
      .catch(() => {
        if (!localStorage.getItem('currentLocale')) {
          localStorage.setItem('currentLocale', resolveBrowserLocale());
        }
      });
  }, []);

  if (isLoading && !currentLocale) return <span>Loading...</span>;

  return (
    <Provider store={customStore}>
      <BrowserRouter>
        <Admin
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          title={getConfig('title')}
          layout={MyLayout}
          loginPage={LoginWithTheme}
          locale={currentLocale}
        >
          <CustomRoutes noLayout key="reset-password">
            <Route exact path="/reset-password" element={<ResetPassword />} />
          </CustomRoutes>
          {(permissions) => renderRoutes(permissions)}
        </Admin>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
