import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import { useTranslate } from 'react-admin';

import { patchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import url from '../../../config/connection';
import formatDateTime from '../../../utils/formatDateTime';

const Row = (props) => {
  const { row } = props;
  const details = row.details;
  const [open, setOpen] = useState(false);

  const translate = useTranslate();

  const startTime = formatDateTime(details.startTime);
  const courierAssignedTime = formatDateTime(details.courierAssignedTime);

  const stopAutomationHandler = () => {
    patchWithAuthorization(`${url}/courier/stop-auto/${row.deliveryRequestId}`);
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {row.deliveryRequestId}
        </TableCell>
        <TableCell align="center">{row.currentCourierUserId}</TableCell>
        <TableCell align="center">{row.sessionStatus}</TableCell>
        <TableCell align="center">
          <IconButton aria-label="expand row" size="small" onClick={() => stopAutomationHandler()}>
            <PlayCircleFilledWhiteOutlinedIcon fontSize="medium" color="primary" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{translate('ra.label.startTime')}</TableCell>
                    <TableCell align="center">{translate('ra.label.retryTimes')}</TableCell>
                    <TableCell align="center">{translate('ra.label.courierUnconfirmed')}</TableCell>
                    <TableCell align="center">
                      {translate('ra.label.courierAssignedTime')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" align="center">
                      {`${startTime.date} ${startTime.time}`}
                    </TableCell>
                    <TableCell align="center">{details.retryTimes}</TableCell>
                    <TableCell align="center">{details.courierUnconfirmed}</TableCell>
                    <TableCell align="center">
                      {`${courierAssignedTime.date} ${courierAssignedTime.time}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
