import React, { useState } from 'react';
import { required, Form, TextInput, useNotify, useTranslate } from 'react-admin';
import { Button, Box, CircularProgress, Card, Typography, CardActions } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link } from 'react-router-dom';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import url from '../../../config/connection';

const imageUrl = process.env.REACT_APP_FORGOT_PASSWORD_LOGO;

const firstBoxCss = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundColor: 'grey.300',
};

const cardCss = {
  width: '80%',
  maxWidth: '400px',
  margin: '1em',
  padding: '1em',
  borderRadius: '12px',
};

const ResetPassword = (props) => {
  const { className } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const submitAction = async (values) => {
    setLoading(true);

    try {
      const request = await fetch(`${url}/user/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      const response = await request.json();

      if (response.statusCode > 300) {
        throw new Error(response.message);
      }

      setIsSuccess(true);
      setLoading(false);
    } catch (error) {
      notify(error?.message, { type: 'warning' });
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={submitAction} mode="onChange" noValidate className={className}>
      <Box sx={firstBoxCss}>
        <Card sx={cardCss}>
          {(!isSuccess || isLoading) && (
            <>
              {imageUrl && (
                <Box sx={{ margin: '0.5em', display: 'flex', justifyContent: 'center' }}>
                  <img src={imageUrl} alt="logo" height="100pt" />
                </Box>
              )}
              <Box
                sx={{
                  margin: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography color="text.primary" variant="h6">
                  Forgot Password?
                </Typography>
                <Typography color="text.secondary" variant="body2" sx={{ textAlign: 'center' }}>
                  Enter a emaill address associated with your account and we&apos;ll send you a link
                  to reset your password
                </Typography>
              </Box>
              <Box sx={{ margin: '1em' }}>
                <TextInput
                  autoFocus
                  source="email"
                  label={translate('ra.label.email')}
                  disabled={isLoading}
                  isRequired
                  helperText="Email address"
                  validate={[required('Provide an email.')]}
                  fullWidth
                />
                <CardActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '-1em',
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                    sx={(theme) => ({
                      alignmentBaseline: 'center',
                      marginTop: theme.spacing(2),
                      width: '100%',
                      textTransform: 'none',
                    })}
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={25}
                        thickness={4.0}
                        color="primary"
                        sx={{ padding: '2px' }}
                      />
                    ) : (
                      <>Request reset link</>
                    )}
                  </Button>
                </CardActions>
              </Box>
            </>
          )}

          {!isLoading && isSuccess && (
            <>
              <Box
                sx={{
                  margin: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <CheckCircleOutlineIcon
                  fontSize="large"
                  color="success"
                  sx={{ width: 250, height: 250 }}
                />
              </Box>
              <Box
                sx={{
                  margin: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography color="text.secondary" variant="h5" sx={{ textAlign: 'center' }}>
                  Reset link sent successfully to provided email address!
                </Typography>
              </Box>
            </>
          )}
          <Link to="/login" style={{ textDecoration: 'none' }}>
            <Typography
              variant="body1"
              sx={{
                textAlign: 'center',
                padding: '1em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BackIcon sx={{ color: 'inherit' }} />
              Back to login
            </Typography>
          </Link>
        </Card>
      </Box>
    </Form>
  );
};

export default ResetPassword;
