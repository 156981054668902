import React from 'react';
import { IconButton, Popover, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import useAnchor from '../../../hooks/useAnchor';
import useStyles from './styles';
import TRANSPORT_TYPES from '../../../config/courier.config';
import { ReactComponent as CarIcon } from '../icons/Car.svg';
import { ReactComponent as BicycleIcon } from '../icons/Bicycle.svg';
import { ReactComponent as MotorcycleIcon } from '../icons/Motorcycle.svg';
import { ReactComponent as WalkIcon } from '../icons/Walk.svg';
import { formatPhoneNumber } from '../../../utils/parsePhoneNumber';

const getTimeSinceUpdated = (updatedAt) => {
  const now = Date.now();
  const upd = new Date(updatedAt).getTime();
  const diff = now - upd;

  const seconds = diff / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  return {
    diff,
    days: Math.floor(days),
    hours: Math.floor(hours % 24),
    minutes: Math.floor(minutes % 60),
  };
};

const constructUpdatedTimeMessage = (updatedAt, t) => {
  const { days, hours, minutes } = getTimeSinceUpdated(updatedAt);

  if (!days && !hours && !minutes) {
    return `${t('ra.label.updated')} ${t('ra.label.just_now')}`;
  }

  const getTimeString = (val, label) =>
    (val ? `${val} ${t(`ra.label.${label}${val > 1 ? 's' : ''}`)}` : '');
  const daysPart = getTimeString(days, 'day');
  const hoursPart = getTimeString(hours, 'hour');
  const minutesPart = getTimeString(minutes, 'minute');

  return `${t('ra.label.updated')} ${daysPart} ${hoursPart} ${minutesPart} ${t('ra.label.ago')}`;
};

export const getIconFromTransportType = (transportType) =>
  transportIconMap[transportType] || CarIcon;

const transportIconMap = {
  [TRANSPORT_TYPES.CAR]: CarIcon,
  [TRANSPORT_TYPES.BICYCLE]: BicycleIcon,
  [TRANSPORT_TYPES.MOTO]: MotorcycleIcon,
  [TRANSPORT_TYPES.ONFOOT]: WalkIcon,
};

const getColorFormUpdatedTime = (updatedAt) => {
  const { diff } = getTimeSinceUpdated(updatedAt);
  const colorsMap = { recent: 'green', average: 'yellow', outdated: 'gray' };
  const timeframeMap = [
    { timeLessThen: 5 * 60 * 1000, status: 'recent' },
    { timeLessThen: 60 * 60 * 1000, status: 'average' },
    { timeLessThen: Infinity, status: 'outdated' },
  ];

  const timeframe = timeframeMap.find(({ timeLessThen }) => diff < timeLessThen);
  const status = timeframe.status;

  return colorsMap[status];
};

const CourierPlacemark = ({
  courier: { firstName, lastName, updatedAt, transportType, userId, phone },
  actions,
  onClick,
  onMouseEnter,
  onMouseLeave,
  selectedCourier,
  hoveredCourier,
  iconStyles,
}) => {
  const { anchorEl, open, handleClose, handleOpen } = useAnchor();
  const styles = useStyles();
  const translate = useTranslate();

  const VehicleIcon = getIconFromTransportType(transportType);

  return (
    <div>
      <IconButton
        onClick={(e) => {
          if (onClick) {
            onClick();
          }

          handleOpen(e);
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`
          ${styles.icon}
          ${hoveredCourier === userId ? styles.hovered : ''} 
          ${selectedCourier === userId ? styles.selected : ''}
        `}
      >
        <VehicleIcon fill={getColorFormUpdatedTime(updatedAt)} className={iconStyles || ''} />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={styles.balloon}>
          <div className={styles.mainArea}>
            <Typography variant="h6">{`${firstName} ${lastName}`}</Typography>
            {actions}
          </div>
          <Typography variant="body1">
            {constructUpdatedTimeMessage(updatedAt, translate)}
          </Typography>
          <Typography variant="body1">
            {`${translate('ra.label.phone')}: ${formatPhoneNumber(phone)}`}
          </Typography>
        </div>
      </Popover>
    </div>
  );
};

export default CourierPlacemark;
