const styles = {
  root: {
    flexGrow: 1,
  },
};

export const TabStyle = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    flexGrow: 1,
    alignItems: 'flex-start',
  },
};

export const exportSection = {
  margin: '15px 0',
};

export default styles;
