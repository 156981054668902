import React from 'react';
import { Datagrid, List, TextField, FunctionField } from 'react-admin';

import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const RestaurantList = () => {
  const organizationId = localStorage.getItem('organizationId');

  return (
    <List filter={{ organizationId }}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.name" source="name" />
        <FunctionField
          label="ra.label.geo"
          render={({ geo }) => {
            return `Lat: ${geo.lat}, Lng: ${geo.lng}`;
          }}
        />
        <FunctionField
          label="ra.label.address"
          render={({ address }) => {
            const {
              country,
              locality,
              area,
              street,
              house,
            } = address;

            return Object.keys(address).length !== 0 ? `${country}, ${locality},
              ${area}, ${street} ${house}
            ` : '-';
          }}
        />
      </Datagrid>
    </List>
  );
};

export default RestaurantList;
