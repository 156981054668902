import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import { useRefresh } from 'react-admin';
import { CourierSelectStyles } from './style';
import url from '../../../config/connection';
import {
  postWithAuthorization,
  fetchWithAuthorization,
  patchWithAuthorization,
} from '../../../utils/fetchWithAuthorization';
import { DEFAULT_ZOOM, GOOGLE_MAPS_API_KEY } from '../../../config/maps.config';
import CourierPlacemark from '../../../components/maps/CourierPlacemark';
import { ReactComponent as AssignIcon } from '../../../components/maps/icons/CourierAssign.svg';
import CouriersList from './CouriersList';
import ClientPlacemark from '../../../components/maps/ClientPlacemark';
import { DELIVERY_STATUS_NAMES } from '../../../config/statuses.config';
import SupplierPlacemark from '../../../components/maps/SupplierPlacemark';

const mapOptions = () => ({
  fullscreenControl: false,
  zoomControl: false,
});

const updatedRecently = (updatedAt) => Date.now() - new Date(updatedAt).getTime() < 60 * 60 * 1000;

const CourierAssign = ({ classes, currentOrder, handleClose }) => {
  const [couriers, setCouriers] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState(
    currentOrder.courier && currentOrder.courier.id,
  );
  const [hoveredCourier, setHoveredCourier] = useState(null);
  const [googleMapsApi, setGoogleMapsApi] = useState();
  const [currentCourierPaths, setCurrentCourierPaths] = useState([]);
  const [clientGeoLocations, setClientGeoLocations] = useState([]);
  const [currentCourierOrders, setCurrentCourierOrders] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState(null);

  const organizationId = localStorage.getItem('organizationId');

  const refresh = useRefresh();

  useEffect(() => {
    const orderId = currentOrder.id.toString();
    fetchWithAuthorization(`${url}/delivery/${orderId}/nearBy`)
      .then((results) => results.json())
      .then((data) => {
        data.sort((a, b) => a.distance - b.distance);
        const sortedCouriers = [
          ...data.filter(
            ({ currentOrderIds, updatedAt }) =>
              !currentOrderIds.length && updatedRecently(updatedAt),
          ),
          ...data.filter(({ currentOrderIds }) => currentOrderIds.length),
          ...data.filter(
            ({ currentOrderIds, updatedAt }) =>
              !currentOrderIds.length && !updatedRecently(updatedAt),
          ),
        ];

        setCouriers(sortedCouriers);
      });
  }, [currentOrder.id]);

  useEffect(() => {
    const fetchGeoData = async () => {
      const response = await fetchWithAuthorization(`${url}/organization/${organizationId}`);
      const data = await response.json();

      setDefaultCenter(data.geo);
    };

    fetchGeoData();
  }, [organizationId]);

  const assignCourier = async (userId) => {
    await patchWithAuthorization(`${url}/courier/stop-auto/${currentOrder.id.toString()}`);
    await postWithAuthorization(`${url}/delivery/assign`, {
      body: JSON.stringify({
        courierId: userId || selectedCourier,
        deliveryRequestId: currentOrder.id.toString(),
      }),
    }).then(() => {
      handleClose();
      refresh();
    });
  };

  const handleGoogleMapApi = useCallback((google) => setGoogleMapsApi(google), []);

  const clearPaths = useCallback(() => {
    if (currentCourierPaths.length) {
      currentCourierPaths.forEach((currentCourierPath) => {
        currentCourierPath.setMap(null);
      });
    }

    setClientGeoLocations([]);
    setCurrentCourierOrders([]);
  }, [currentCourierPaths]);

  const drawCourierPath = useCallback(
    async ({ currentOrderIds, location: { coordinates } }) => {
      clearPaths();

      if (!currentOrderIds.length) {
        return;
      }

      const { map, maps } = googleMapsApi;

      const courierGeo = {
        lat: coordinates[1],
        lng: coordinates[0],
      };

      const fetchedCourierOrders = currentOrderIds.map(async (deliveryRequestId) => {
        const result = await fetchWithAuthorization(
          `${url}/delivery/today/${deliveryRequestId}`,
        ).then((res) => {
          return res.json();
        });

        return result;
      });

      if (!fetchedCourierOrders.length) {
        return;
      }

      const allCourierOrders = await Promise.all(fetchedCourierOrders);

      const courierPaths = allCourierOrders.map((item) => {
        if (item.supplierStatus === DELIVERY_STATUS_NAMES.DELIVERING) {
          return [
            {
              ...courierGeo,
            },
            {
              ...item.deliveryAddress.geo,
            },
          ];
        }

        setCurrentCourierOrders((prev) => [...prev, item]);

        return [
          {
            ...courierGeo,
          },
          {
            ...item.sourceAddress.geo,
          },
          {
            ...item.deliveryAddress.geo,
          },
        ];
      });

      const drawnPaths = courierPaths.map((pathItem) => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);

        if (pathItem.length > 2) {
          setClientGeoLocations((prev) => [...prev, pathItem[2]]);
        } else {
          setClientGeoLocations((prev) => [...prev, pathItem[1]]);
        }

        return new maps.Polyline({
          path: pathItem,
          strokeColor: `#${randomColor}`,
          strokeOpacity: 1,
          strokeWeight: 5,
        });
      });

      drawnPaths.forEach((item) => {
        if (item) {
          item.setMap(map);
          setCurrentCourierPaths((prev) => [...prev, item]);
        }
      });
    },
    [clearPaths, googleMapsApi],
  );

  if (!defaultCenter) {
    return <span>Loading...</span>;
  }

  return (
    <div className={classes.courierModal}>
      <div className={classes.map}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          defaultCenter={defaultCenter}
          defaultZoom={DEFAULT_ZOOM}
          options={mapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleGoogleMapApi}
        >
          {couriers.map((courier) => {
            return (
              <CourierPlacemark
                key={courier.userId}
                lat={courier.location.coordinates[1]}
                lng={courier.location.coordinates[0]}
                courier={courier}
                selectedCourier={selectedCourier}
                hoveredCourier={hoveredCourier}
                actions={
                  <IconButton className={classes.assignButton} onClick={() => assignCourier()}>
                    <AssignIcon />
                  </IconButton>
                }
                onClick={() => {
                  setSelectedCourier(courier.userId);
                  drawCourierPath(courier);
                }}
                onMouseEnter={() => setHoveredCourier(courier.userId)}
                onMouseLeave={() => setHoveredCourier(null)}
                iconStyles={courier.currentOrderIds.length ? classes.busyCourier : null}
              />
            );
          })}
          <SupplierPlacemark
            {...currentOrder.sourceAddress.geo}
            supplier={currentOrder.supplier}
            order={currentOrder}
          />
          <ClientPlacemark {...currentOrder.deliveryAddress.geo} />
          {clientGeoLocations.length &&
            clientGeoLocations.map((clientLocation, index) => {
              return <ClientPlacemark {...clientLocation} key={clientLocation?.lat || index} />;
            })}
          {currentCourierOrders.length !== 0 &&
            currentCourierOrders.map((order) => {
              return (
                <SupplierPlacemark key={order.id} {...order.sourceAddress.geo} order={order} />
              );
            })}
        </GoogleMapReact>
        <CouriersList
          couriers={couriers}
          selectedCourier={selectedCourier}
          hoveredCourier={hoveredCourier}
          onClick={(courier) => {
            setSelectedCourier(courier.userId);
            drawCourierPath(courier);
          }}
          onMouseEnter={(userId) => setHoveredCourier(userId)}
          onMouseLeave={() => setHoveredCourier(null)}
          assignCourier={assignCourier}
          selectCourier={setSelectedCourier}
        />
      </div>
    </div>
  );
};

const mapState = (state) => ({
  orders: state?.admin?.resources['delivery/today'].data,
});

export default withStyles(CourierSelectStyles)(connect(mapState)(CourierAssign));
