export const TRANSPORT_TYPES = [
  { id: 'BICYCLE', name: 'BICYCLE' },
  { id: 'MOTO', name: 'MOTO' },
  { id: 'CAR', name: 'CAR' },
  { id: 'ONFOOT', name: 'ONFOOT' },
];

export const DEFAULT_VALUES = [
  { type: 'BICYCLE', coefficient: 1, deliverySettingId: null },
  { type: 'MOTO', coefficient: 1, deliverySettingId: null },
  { type: 'CAR', coefficient: 1, deliverySettingId: null },
  { type: 'ONFOOT', coefficient: 1, deliverySettingId: null },
];

export const BILL_PHOTO_CONFIG = [
  { id: 'REQUIRED', name: 'REQUIRED' },
  { id: 'OPTIONAL', name: 'OPTIONAL' },
  { id: 'NONE', name: 'NONE' },
];
