import React, { useState } from 'react';
import { CardActions } from '@mui/material';
import {
  EditButton,
  Button,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  useNotify,
  useRefresh,
} from 'react-admin';

import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconContentAdd from '@mui/icons-material/Add';
import { postWithAuthorization } from '../../../utils/fetchWithAuthorization';
import url from '../../../config/connection';

import useStyles from './styles';

const ShowActions = ({ basePath, data }) => {
  const [open, setOpen] = useState(false);
  const styles = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CardActions className={styles.root}>
      <EditButton record={data} />

      {/* <Button color="primary" onClick={handleOpen} label="ra.action.give_bonus"> */}
      {/*  <IconContentAdd /> */}
      {/* </Button> */}

      <Modal
        disableEnforceFocus
        className={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 1500,
        }}
      />
    </CardActions>
  );
};

export default ShowActions;
