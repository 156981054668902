import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1024px',
    padding: '0 15px',
    margin: '0 auto',
    height: 'inherit',
  },
  organizationsWrapper: {
    display: 'flex',
    width: '1024px',
    flexWrap: 'wrap',
    margin: '10px',
    justifyContent: 'center',
  },
});

export default useStyles;
