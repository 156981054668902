import OrganizationCreate from './components/Create';
import OrganizationEdit from './components/Edit';
import OrganizationList from './components/List';
import OrganizationShow from './components/Show';

export default {
  list: OrganizationList,
  edit: OrganizationEdit,
  show: OrganizationShow,
  create: OrganizationCreate,
};
