const style = {
  body: {
    minWidth: '300px',
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },

  cancelBtn: {
    color: '#F44336',
  },
};

export const TimeInputStyles = {
  root: {
    textAlign: 'center',

    '& input': {
      textAlign: 'center',
      width: '100px',
      fontSize: '22px',
      padding: '5px 0',
    },
  },
};

export const CourierSelectStyles = {
  courierModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  selectEmpty: {
    marginTop: '16px',
  },
  map: {
    width: '80vw',
    height: '80vh',
  },
  assignButton: {
    border: '2px orange solid',
    margin: 5,
    '&:hover': {
      backgroundColor: 'orange',
    },
  },
  busyCourier: {
    padding: 2,
    border: '2px red solid',
    borderRadius: '50%',
  },
};

export const CommentOrderStyles = {
  commentModal: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-around',
    padding: '15px 0',
    flexDirection: 'column',
  },
  formControl: {
    margin: '8px',
    minWidth: '220px',
  },
  textField: {
    marginLeft: '16px',
    marginRight: '16px',
    width: 400,
  },
};

export default style;
