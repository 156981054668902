import React from 'react';
import TimeField from 'react-simple-timefield';
import { Typography, Button, TextField } from '@mui/material';
import { useRefresh, useTranslate } from 'react-admin';
import style from './style';
import { convertPreparationTimeToMinutes } from '../../../utils/timeOperations';

const TimeConfirm = ({
  changeOrderStatus,
  classes,
  time,
  setTime,
  handleClose,
  order,
  nextStatus,
}) => {
  const translate = useTranslate();
  const refresh = useRefresh();

  const { cancelBtn, footer } = style;
  const monthScheduledAt = new Date(order.scheduled?.scheduledAt).toString().split(' ')[1];
  const dateScheduledAt = new Date(order.scheduled?.scheduledAt).toString().split(' ')[2];
  const timeScheduledAt = new Date(order.scheduled?.scheduledAt).toString().split(' ')[4];

  const getNeededDataByNextStatus = ({ key }) => {
    return {
      supplierStatus: key,
      preparationDetails: {
        estimatedDuration: convertPreparationTimeToMinutes(time),
      },
    };
  };

  const newOrderData = getNeededDataByNextStatus(nextStatus);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          padding: '15px 0',
          flexDirection: 'row',
        }}
      >
        <Typography variant="h2" style={{ fontSize: '20px', opacity: '0.8' }}>
          {translate('ra.message.set_time')}
        </Typography>
        <TimeField
          value={time}
          style={{ textAlign: 'center' }}
          input={<TextField classes={{ root: classes.root }} />}
          onChange={(event, value) => setTime(value)}
          colon=":"
        />
      </div>
      {order.scheduled?.scheduledAt && (
        <Typography
          variant="h2"
          style={{
            fontSize: '20px',
            opacity: '0.8',
            padding: '10px 5px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`${translate(
            'ra.message.scheduled_at',
          )} ${dateScheduledAt} ${monthScheduledAt}, ${timeScheduledAt}`}
        </Typography>
      )}
      <div style={footer}>
        <Button
          style={cancelBtn}
          onClick={(e) => {
            handleClose();
          }}
        >
          {translate('ra.action.close')}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            changeOrderStatus(
              'order',
              { id: order.id, data: { ...order, ...newOrderData }, previousData: order },
              {
                onSuccess: () => {
                  handleClose();
                  refresh();
                },
              },
            );
          }}
        >
          {translate('ra.action.confirm')}
        </Button>
      </div>
    </>
  );
};

export default TimeConfirm;
