import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { useRecordContext, useResourceContext, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import showIcon from './showIcon';
import useStyles from './styles';

const ShowOrder = () => {
  const classes = useStyles();
  const record = useRecordContext();
  const navigate = useNavigate();
  const translate = useTranslate();
  const resource = useResourceContext();

  const redirectToShow = (id) => {
    if (id) {
      navigate(`/${resource}/${id}/show`);
    }
  };

  return (
    <Tooltip title={translate('ra.title.show_order')}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          redirectToShow(record?.id);
        }}
        className={classes.root}
        size="small"
      >
        {showIcon()}
      </IconButton>
    </Tooltip>
  );
};

export default ShowOrder;
