import React from 'react';
import { TextInput, Filter } from 'react-admin';

const ListFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="ra.label.id" source="id" alwaysOn />
      <TextInput label="ra.label.name" source="name" alwaysOn />
      {/* Need backend implementation for filter with select input */}
      {/* method $eq, $cont, etc.. are not implemented */}
    </Filter>
  );
};

export default ListFilter;
