import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import LocaleSwitcher from '../LocaleSwitcher';

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  toolbar: {
    flex: 1,
  },
};

const CustomAppBar = ({ classes, ...props }) => (
  <AppBar {...props} classes={classes} style={{ background: '#1f8ade' }}>
    <Typography
      variant="inherit"
      color="inherit"
      className={classes.title}
      id="react-admin-title"
    />
    <LocaleSwitcher />
  </AppBar>
);

export default withStyles(styles)(CustomAppBar);
