import React from 'react';
import { useRecordContext } from 'react-admin';

const ResourceTitle = ({ field }) => {
  const record = useRecordContext();

  return record && field ? <span>{record[field]}</span> : null;
};

export default ResourceTitle;
