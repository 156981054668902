import { fetchUtils } from 'react-admin';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { stringify } from 'query-string';
import apiUrl from '../config/connection';

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
    });
  }

  const token = localStorage.getItem('token');
  options.headers.set('X-Locale', localStorage.getItem('currentLocale'));
  options.headers.set('Authorization', `Bearer ${token}`);
  options.headers.set('Access-Control-Expose-Headers', 'Content-Range');

  return fetchUtils.fetchJson(url, options);
};

const composeFilter = (paramsFilter) => {
  const flatFilter = fetchUtils.flattenObject(paramsFilter);

  return Object.keys(flatFilter).map((key) => {
    const splitKey = key.split('||');

    let field = splitKey[0];
    let ops = splitKey[1];
    if (!ops) {
      if (
        typeof flatFilter[key] === 'boolean' ||
        typeof flatFilter[key] === 'number' ||
        (typeof flatFilter[key] === 'string' && flatFilter[key].match(/^\d+$/))
      ) {
        ops = CondOperator.EQUALS;
      } else {
        ops = CondOperator.CONTAINS;
      }
    }

    if (field.startsWith('_') && field.includes('.')) {
      field = field.split(/\.(.+)/)[1];
    }

    return { field, operator: ops, value: flatFilter[key] };
  });
};

const composeQueryParams = (queryParams = {}) => {
  return stringify(fetchUtils.flattenObject(queryParams), { skipNull: true });
};

const mergeEncodedQueries = (...encodedQueries) => encodedQueries.map((query) => query).join('&');

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;

    const { q: queryParams, $OR: orFilter, ...filter } = params.filter || {};

    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter: composeFilter(filter),
      or: composeFilter(orFilter || {}),
    })
      .setLimit(perPage)
      .setPage(page)
      .sortBy(params.sort)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
        total: json.total,
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = RequestQueryBuilder.create()
      .setFilter({
        field: 'id',
        operator: CondOperator.IN,
        value: `${params.ids}`,
      })
      .query();

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }) => ({ data: json.data || json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { q: queryParams, ...otherFilters } = params.filter || {};
    const filter = composeFilter(otherFilters);

    filter.push({
      field: params.target,
      operator: CondOperator.EQUALS,
      value: params.id,
    });

    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter,
    })
      .sortBy(params.sort)
      .setLimit(perPage)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  update: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) => {
    return Promise.all(
      params.ids.map((id) => {
        return httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        });
      }),
    ).then((responses) => ({
      data: responses.map(({ json }) => json),
    }));
  },

  create: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: { ...json, id: params.id } }));
  },

  deleteMany: (resource, params) => {
    return Promise.all(
      params.ids.map((id) => {
        return httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        });
      }),
    ).then((responses) => ({ data: responses.map(({ json }) => json) }));
  },
};
