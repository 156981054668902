export const convertPreparationTimeToMinutes = (timeString) => {
  const [hourString, minuteString] = timeString.split(':');
  const hour = Number(hourString);
  const minute = Number(minuteString);
  const hourToMins = hour * 60;

  return hourToMins + minute;
};

export const convertMinutesToPreparationTime = (preparingMinutes) => {
  if (preparingMinutes < 60) {
    return `00:${preparingMinutes}`;
  }

  const hours = parseInt(preparingMinutes / 60);
  const remainingMinutes = preparingMinutes - (hours * 60);

  return `${hours}:${remainingMinutes}`;
};

export const parseDateToDDMMYYY = (date, separator = '/') => {
  const dateObj = new Date(date);

  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  return [day, month, year].join(separator);
};
