import CourierAutomationSettingsCreate from './components/Create';
import CourierAutomationSettingsEdit from './components/Edit';
import CourierAutomationSettingsList from './components/List';
import CourierAutomationSettingsShow from './components/Show';

export default {
  list: CourierAutomationSettingsList,
  create: CourierAutomationSettingsCreate,
  show: CourierAutomationSettingsShow,
  edit: CourierAutomationSettingsEdit,
};
