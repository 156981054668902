import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const CreateDialog = ({ handleClose, open, createdToken }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">API Access Key</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <code>{createdToken}</code>
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Use this token in the headers of your requests with the key X-API-Key
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateDialog;
