import React, { useEffect, useState } from 'react';
import {
  Confirm,
  useListContext,
  useUnselectAll,
  useDeleteMany,
  useResourceContext,
  useTranslate,
  Button,
  useNotify,
  useRefresh,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';

const ListDeleteConfirmationButton = () => {
  const { data, selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const resource = useResourceContext();
  const unselectAll = useUnselectAll(resource);
  const translate = useTranslate();
  const [deleteMany] = useDeleteMany();
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    const items = data.filter((courier) => selectedIds.includes(courier.id));
    setSelectedItems(items);
  }, [selectedIds, data]);

  const translationOptions = () => {
    return selectedItems[0]?.name
      ? { name: selectedItems[0]?.name, smart_count: selectedIds.length }
      : {
        firstName: selectedItems[0]?.firstName,
        lastName: selectedItems[0]?.lastName,
        smart_count: selectedIds.length,
      };
  };

  const handleDelete = () => {
    const isPayoutSettings = data[0]?.courierPayoutSettings;
    const isDeliverySetting = data[0]?.settingType;
    if (data.length === 1 && (isPayoutSettings || isDeliverySetting)) {
      notify(
        translate('ra.notification.forbid_delete_one'),
        { type: 'warning' },
      );
      unselectAll();

      return;
    }

    if (data.length === selectedIds.length && (isPayoutSettings || isDeliverySetting)) {
      notify(
        translate('ra.notification.forbid_delete_all'),
        { type: 'warning' },
      );

      unselectAll();

      return;
    }

    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    notify(translate('ra.notification.deleted', { smart_count: selectedIds.length }), {
      undoable: true,
    });
    unselectAll();
    refresh();
  };

  const handleConfirm = () => {
    deleteMany(resource, { ids: selectedIds }, { onSuccess, mutationMode: 'undoable' });
    setOpen(false);
  };

  return (
    <>
      <Button
        label={translate('ra.action.delete')}
        sx={{ padding: '10px', lineHeight: '1 !important' }}
        onClick={handleDelete}
        color="error"
      >
        <DeleteIcon />
      </Button>
      <Confirm
        isOpen={open}
        sx={{ padding: '8px 10px', marginLeft: '10px', textAlign: 'center' }}
        title={translate(data[0]?.firstName
          ? 'ra.message.bulk_delete_full_name'
          : 'ra.message.bulk_delete_name', translationOptions())}
        content=""
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        color="error"
      />
    </>
  );
};

export default ListDeleteConfirmationButton;
