import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography, IconButton } from '@mui/material';
import { getIconFromTransportType } from '../../../../components/maps/CourierPlacemark';
import { ReactComponent as AssignIcon } from '../../../../components/maps/icons/CourierAssign.svg';
import useStyles from './styles';

const CouriersList = ({
  couriers,
  selectedCourier,
  hoveredCourier,
  onMouseEnter,
  onMouseLeave,
  onClick,
  assignCourier,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <List>
          {couriers.map((courier) => {
            const { firstName, lastName, distance, userId, transportType, currentOrderIds } =
              courier;

            const busy = !!currentOrderIds.length;
            const [d] = distance.toString().split('.');
            const distanceKm = d / 1000;
            const isSelected = userId === selectedCourier;
            const isHovered = userId === hoveredCourier;
            const VehicleIcon = getIconFromTransportType(transportType);

            return (
              <ListItem
                button
                key={userId}
                className={`${styles.courier} ${isSelected ? styles.selected : ''} ${
                  isHovered ? styles.hovered : ''
                }`}
                onMouseEnter={() => onMouseEnter(userId)}
                onMouseLeave={onMouseLeave}
                onClick={() => onClick(courier)}
              >
                <ListItemIcon className={styles.vehicleIcon}>
                  <VehicleIcon className={busy ? styles.busyCourier : null} />
                </ListItemIcon>
                <ListItemText>
                  <Typography display="inline" className={styles.name}>
                    {`${lastName} ${firstName[0]}. `}
                  </Typography>
                  <Typography display="inline" className={styles.distance}>
                    {`${distanceKm.toString().substring(0, 4)}km`}
                  </Typography>
                </ListItemText>
                <IconButton
                  className={styles.assignIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    assignCourier(userId);
                  }}
                >
                  {busy ? (
                    <span className={styles.currentOrdersCount}>{currentOrderIds.length}</span>
                  ) : (
                    <AssignIcon />
                  )}
                </IconButton>
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default CouriersList;
