import React from 'react';
import { useTranslate } from 'react-admin';
import { IconButton, Popover, Typography } from '@mui/material';
import useAnchor from '../../../hooks/useAnchor';
import useStyles from './styles';
import supplierIcon from '../icons/Restaurant.svg';
import { formatPhoneNumber } from '../../../utils/parsePhoneNumber';

const RestaurantPlacemark = ({ restaurant }) => {
  const { anchorEl, open, handleClose, handleOpen } = useAnchor();
  const styles = useStyles();
  const translate = useTranslate();

  const { name, phoneNumber, address } = restaurant;
  const { country, locality, area, street, house } = address;

  return (
    <div>
      <IconButton onClick={handleOpen} className={styles.icon}>
        <img src={supplierIcon} alt="Restaurant" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={styles.balloon}>
          <Typography variant="h6">{name}</Typography>
          <Typography variant="body1">
            {`
              ${translate('ra.label.address')}: 
              ${country}, ${locality}, ${area}, ${street}, ${house}
            `}
          </Typography>
          <Typography variant="body1">
            {`${translate('ra.label.phone')}: ${formatPhoneNumber(phoneNumber)}`}
          </Typography>
        </div>
      </Popover>
    </div>
  );
};

export default RestaurantPlacemark;
