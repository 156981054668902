import React from 'react';
import { BooleanField, Datagrid, FunctionField, List, TextField } from 'react-admin';
import LockIcon from '@mui/icons-material/Lock';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import PowerIcon from '@mui/icons-material/Power';

import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import WebHooksListActions from '../ListActions';

const WebHooksList = () => {
  return (
    <List actions={<WebHooksListActions />}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.callbackUrl" source="callbackUrl" />
        <FunctionField
          source="events"
          label="ra.label.events"
          render={({ events }) => {
            return events.join(', ');
          }}
        />
        <BooleanField
          label="ra.label.enabled"
          source="enabled"
          TrueIcon={PowerIcon}
          FalseIcon={PowerOffIcon}
        />
        <FunctionField
          label="ra.label.secured"
          source="credentials"
          render={({ credentials }) => {
            return credentials ? (
              <LockIcon fontSize="small" />
            ) : (
              <NoEncryptionGmailerrorredIcon fontSize="small" />
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export default WebHooksList;
