export const baseUrl = process.env.REACT_APP_API_URL;
export const staticUrl = 'https://static.ifood.md/';
const url = `${baseUrl}/admin`;

export const urlAuth = `${baseUrl}/auth`;

export const mainDomainUrl = process.env.REACT_APP_MAIN_URL;
export const adminRoleUrl = process.env.REACT_APP_ADMIN_URL;
export const httpProtocol = process.env.REACT_APP_PROTOCOL;

export default url;
