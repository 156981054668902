import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 10px',
    borderBottom: '1px solid #aaa',
    textTransform: 'uppercase',
  },
  dropdownWrapper: {
    display: 'grid',
    gridTemplate: 'repeat(2, auto) / repeat(5, 1fr)',
    background: 'rgba(76, 134, 168, 0.1)',
    width: 'calc(100% + 48px)',
    margin: '-4px -24px',
  },
  cardStyle: {
    padding: '10px',
    margin: '20px 5px',
  },
  courierCarStyle: {
    padding: '0 10px 5px',
    height: '100%',
    textAlign: 'left',
  },
  buttonWithoutPadding: {
    padding: 0,
    margin: 0,
    '&:hover': {
      margin: 0,
      padding: 0,
    },
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: '64px',
    boxSizing: 'border-box',
    textTransform: 'unset',
  },
  orderInstructionCard: {
    padding: '10px',
    margin: '20px 5px',
    minWidth: '250px',
    backgroundColor: 'rgba(146, 209, 195, 0.5)',
  },
  block: {
    display: 'block',
  },
  w200: {
    width: 200,
  },
  divider: {
    borderBottom: '1px solid #aaa',
  },
  imgLighBox: {
    marginTop: '10px',
  },
  imgLighBoxInButton: {
    objectFit: 'contain',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'end',
    padding: '5px 15px',
  },
});

export default useStyles;
