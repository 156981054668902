import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useRecordContext, useResourceContext, useTranslate } from 'react-admin';
import { ReactComponent as EditIcon } from './editIcon.svg';

const useStyles = makeStyles({
  root: {
    minWidth: '25px',
    '& > *': {
      minWidth: '25px',
    },
  },
});

const EditOrder = () => {
  const classes = useStyles();
  const record = useRecordContext();
  const translate = useTranslate();
  const navigate = useNavigate();
  const resource = useResourceContext();

  const canEdit = (record.deliveryStatus !== 'CANCELED') && (record.deliveryStatus !== 'DELIVERED');

  const recordId = record && record.id;

  const redirectToEdit = (order) => {
    if (order.id && (order.deliveryStatus !== 'CANCELED') && (order.deliveryStatus !== 'DELIVERED')) {
      navigate(`/${resource}/${order.id}/edit`);
    }
  };

  if (canEdit) {
    return (
      <Tooltip title={translate('ra.title.edit_order')}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            redirectToEdit(record);
          }}
          className={classes.root}
          size="small"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return <div />;
};

export default EditOrder;
