import React from 'react';
import { Button, TopToolbar, useTranslate, useRedirect, useResourceContext } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';

const CancelButton = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const resource = useResourceContext();

  const handleRedirect = () => {
    redirect('list', resource);
  };

  return (
    <TopToolbar>
      <Button
        label={translate('ra.action.cancel')}
        onClick={handleRedirect}
        sx={{ padding: '10px' }}
      >
        <CloseIcon />
      </Button>
    </TopToolbar>
  );
};

export default CancelButton;
