import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import DeliverySettingsInput from '../DeliverySettingsFieldInput';

const organizationId = localStorage.getItem('organizationId');

const deliverySettingsDefaultValues = () => ({ organizationId });

const DeliverySettingsCreate = () => {
  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm defaultValues={deliverySettingsDefaultValues}>
        <DeliverySettingsInput />
      </SimpleForm>
    </Create>
  );
};

export default DeliverySettingsCreate;
