import React, { useState } from 'react';
import { Button } from '@mui/material';
import { TopToolbar, useRefresh, useTranslate } from 'react-admin';
import url from '../../../config/connection';
import { postWithAuthorization } from '../../../utils/fetchWithAuthorization';
import CreateDialog from '../Dialogs/CreateDialog';

const ListActions = () => {
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState('');

  const permissions = localStorage.getItem('organizationPermissions');

  const hasCreateAccess = permissions.includes('create_api_key');

  const translate = useTranslate();
  const refresh = useRefresh();

  const organizationId = localStorage.getItem('organizationId');

  const handleClickOpen = async () => {
    const response = await postWithAuthorization(`${url}/api-access`, {
      body: JSON.stringify({
        organization: {
          id: organizationId,
        },
      }),
    });

    const data = await response.json();

    setToken(data.accessTokenHash);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  if (!hasCreateAccess) {
    return null;
  }

  return (
    <TopToolbar sx={{ gap: 0 }}>
      <Button sx={{ maxHeight: 25 }} onClick={handleClickOpen}>
        {translate('ra.action.create_api_key')}
      </Button>
      <CreateDialog open={open} handleClose={handleClose} createdToken={token} />
    </TopToolbar>
  );
};

export default ListActions;
