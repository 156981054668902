import { baseUrl } from '../config/connection';

export const getPermissions = async (user, token) => {
  const response = await fetch(`${baseUrl}/user/${user.id}/permission`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const userPermissions = await response.json();

  const permissions = userPermissions.types.reduce((acc, permissionResourceType) => {
    const permitedResources = userPermissions.byTypeName[permissionResourceType].resources;
    const permisionsForResource = permitedResources.reduce((accumulator, resource) => {
      return {
        ...accumulator,
        [resource]:
          userPermissions.byTypeName[permissionResourceType].byResourceId[resource].permissions,
      };
    }, {});

    return {
      ...acc,
      [permissionResourceType]: permisionsForResource,
    };
  }, {});

  return permissions;
};
