export const styles = {
  // wrapper: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  // },

  dateField: {
    paddingBottom: '1px',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#B3B3B3',
  },

  timeField: {
    marginTop: '1px',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#555555',
  },

  divider: {
    borderBottom: '1px solid black',
    width: '70px',
  },
};

export default styles;
