/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { useNotify } from 'react-admin';
import { Navigate, useNavigate } from 'react-router-dom';
import url, { adminRoleUrl, httpProtocol } from '../../../config/connection';
import { USER_ROLES } from '../../../config/user.config';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import OrganizationCard from '../../OrganizationCard';
import useStyles from './styles';
import CustomSnackbar from '../../Snackbar';

const warningMessage = 'Organization was deactivated. Please contact the administrator!';

const css = { width: '100%' };

const Organization = () => {
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Snackbar props
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const notify = useNotify();

  const styles = useStyles();

  const auth = getAuth();

  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  const expirationTime = localStorage.getItem('expirationTime');
  const role = localStorage.getItem('role');
  const permissions = localStorage.getItem('permissions');
  const parsedPermissions = JSON.parse(permissions);

  const handleCustomLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('permissions');
    localStorage.removeItem('organizationGeo');
    auth.signOut();

    navigate('/login', { replace: true });
  };

  const handleClickOpen = useCallback(
    (organizationName, organizationId, geo, deletedAt) => {
      if (deletedAt) {
        setOpen(true);

        return;
      }

      const organizationPermissions = JSON.stringify(
        parsedPermissions.OrganizationEntity[organizationId],
      );

      const organizationGeo = JSON.stringify({ ...geo });

      const organizationData = `organizationPermissions=${organizationPermissions}&organizationId=${organizationId}&organizationGeo=${organizationGeo}`;

      const query = `token=${token}&role=${role}&${organizationData}&refreshToken=${refreshToken}&expirationTime=${expirationTime}`;

      if (organizationName && organizationPermissions) {
        localStorage.clear();
        const redirectUrl = `${httpProtocol}://${organizationName}.${window.location.host}?${query}`;

        window.location.href = redirectUrl;
      } else {
        notify('Access to organization denied ', { type: 'error' });
      }
    },
    [notify, parsedPermissions.OrganizationEntity, role, token, refreshToken, expirationTime],
  );

  useEffect(() => {
    const getOrganizations = async () => {
      const response = await fetchWithAuthorization(`${url}/organization`);
      const { data } = await response.json();
      setOrganizations(data);
      setIsLoading(false);
    };

    getOrganizations();
  }, []);

  if (role === USER_ROLES.ADMIN && organizations.length && !isLoading) {
    const allowedPermissions = JSON.stringify(['view', 'create', 'edit', 'delete']);
    const adminPermissions = `organizationPermissions=${allowedPermissions}`;

    const query = `token=${token}&role=${role}&${adminPermissions}&refreshToken=${refreshToken}&expirationTime=${expirationTime}`;

    localStorage.clear();

    const redirectUrl = `${adminRoleUrl}?${query}`;

    window.location.href = redirectUrl;

    return;
  }

  if (!organizations.length && !isLoading) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.wrapper}>
      <div style={{ margin: 'auto' }}>
        <Typography gutterBottom variant="h5" component="span">
          Chose your organization...
        </Typography>
        <div className={styles.organizationsWrapper}>
          {organizations.map((organization) => {
            return (
              <OrganizationCard
                organization={organization}
                handleClickOpen={handleClickOpen}
                key={organization.name + organization.slug}
              />
            );
          })}
        </div>
      </div>
      <Button
        sx={{ maxWidth: '150px', margin: '30px auto' }}
        variant="contained"
        onClick={handleCustomLogout}
      >
        Logout
      </Button>

      {/* Snackbar */}
      <CustomSnackbar
        severity="warning"
        open={open}
        setOpen={setOpen}
        message={warningMessage}
        styles={css}
      />
    </div>
  );
};

export default Organization;
