import React, { useCallback, useEffect, useState } from 'react';
import {
  useEditController,
  Title,
  SimpleForm,
  SelectInput,
  useTranslate,
  useUpdate,
  useResourceContext,
  useNotify,
  useRedirect,
  useRefresh,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  TextInput,
  Edit,
} from 'react-admin';
import { Card, TextField } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import GoogleMap from 'google-map-react';
import useStyles from './styles';
import ClientPlacemark from '../../../components/maps/ClientPlacemark';
import { GOOGLE_MAPS_API_KEY } from '../../../config/maps.config';
import { ORDER_STATUSES, DELIVERY_STATUS_NAMES } from '../../../config/statuses.config';
import url from '../../../config/connection';
import {
  fetchWithAuthorization,
  patchWithAuthorization,
  postWithAuthorization,
} from '../../../utils/fetchWithAuthorization';
import CancelButton from '../../../components/CancelButton';

const OrderEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton alwaysEnable />
  </Toolbar>
);

const OrderEdit = () => {
  const { control, reset, setValue } = useForm();
  const { id } = useParams();

  const resource = useResourceContext();
  const editContext = useEditController();
  const styles = useStyles();

  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const [order, setOrder] = useState(null);
  const [orderComment, setOrderComment] = useState('');
  const [cancelCommentErr, setCancelCommentErr] = useState(true);

  const [update] = useUpdate();

  const values = useWatch({
    defaultValue: editContext?.record,
    control,
  });

  useEffect(() => {
    if (!editContext?.isFetching) {
      reset(editContext?.record);
    }
  }, [editContext?.isFetching, editContext?.record, reset]);

  const isCancelling = values.deliveryStatus === DELIVERY_STATUS_NAMES.CANCELED;

  const isDeliveryStatusChanging = values.deliveryStatus !== order?.deliveryStatus;

  useEffect(() => {
    setCancelCommentErr(isCancelling && !orderComment);
  }, [isCancelling, orderComment]);

  const placeCommentToOrder = useCallback(async () => {
    await patchWithAuthorization(`${url}/delivery/${id}/comment`, {
      body: JSON.stringify({
        comment: orderComment,
      }),
    });
  }, [id, orderComment]);

  const stopAutomationHandler = () => {
    patchWithAuthorization(`${url}/courier/stop-auto/${order?.id}`);
  };

  const updateOrder = useCallback(() => {
    fetchWithAuthorization(`${url}/delivery/today/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setOrder(data);
      })
      .catch(() => {
        throw new Error('failed to fetch');
      });
  }, [id]);

  useEffect(() => {
    updateOrder();
  }, [updateOrder]);

  const handleSave = async () => {
    await update(
      resource,
      {
        id,
        // update with actual data and don't override the old data in objects
        data: {
          ...editContext?.record,
          deliveryAddress: { ...editContext?.record?.deliveryAddress, ...values?.deliveryAddress },
          deliveryStatus: values?.deliveryStatus ?? editContext.record.deliveryStatus,
        },
        previousData: editContext?.record,
      },
      {
        onSuccess: () => {
          if (isCancelling) {
            placeCommentToOrder();
          }

          if (isDeliveryStatusChanging) {
            stopAutomationHandler();
          }

          redirect('list', resource);
          refresh();
        },
      },
    ).catch(() => {
      notify(translate('ra.notification.http_error'), { type: 'warning' });
    });
  };

  const setGeoForm = (newGeo) => {
    setValue('deliveryAddress.geo', newGeo);
  };

  if (editContext.isLoading) {
    return null;
  }

  return (
    <Edit actions={<CancelButton />}>
      <div>
        <Title title="Book Edition" />
        <Card>
          <SimpleForm onSubmit={handleSave} toolbar={<OrderEditToolbar />}>
            <SelectInput
              sx={{ maxWidth: '218px', width: '100%' }}
              label="ra.label.status"
              source="deliveryStatus"
              choices={ORDER_STATUSES}
              control={control}
            />
            {isCancelling && (
              <TextField
                required={isCancelling}
                label={translate('ra.label.comment')}
                multiline
                rows="4"
                margin="normal"
                value={orderComment}
                onChange={(e) => setOrderComment(e.target.value)}
                error={cancelCommentErr}
                helperText={translate('ra.validation.required')}
              />
            )}

            {/* <OrderItems label="Order items" updateOrder={updateOrder} order={order} /> */}
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <>
                    <div className={styles.addressFormRow}>
                      {[
                        'country',
                        'locality',
                        'street',
                        'streetNumber',
                        'buildingNumber',
                      ].map((part) => (
                        <TextInput
                          control={control}
                          key={part}
                          label={`ra.label.${part}`}
                          source={`deliveryAddress.${part}`}
                          className={styles.addressInput}
                        />
                      ))}
                    </div>
                    <div className={styles.addressFormRow}>
                      {[
                        { label: 'porch', source: 'entrance' },
                        { label: 'porch_code', source: 'intercom' },
                        'floor',
                        { label: 'apartment', source: 'apartment' },
                        { label: 'instruction', source: 'extraInfo' },
                      ].map((part) => {
                        const label = part.label || part;
                        const source = part.source || part;

                        return (
                          <TextInput
                            control={control}
                            key={source}
                            label={`ra.title.${label}`}
                            source={`deliveryAddress.${source}`}
                            className={styles.addressInput}
                          />
                        );
                      })}
                    </div>
                    <div className={styles.map}>
                      <GoogleMap
                        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                        defaultCenter={editContext?.record?.deliveryAddress?.geo}
                        defaultZoom={17}
                        onClick={({ lat, lng }) => {
                          setGeoForm({
                            lat,
                            lng,
                          });
                        }}
                        control={control}
                      >
                        <ClientPlacemark {...formData.deliveryAddress?.geo} />
                      </GoogleMap>
                    </div>
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleForm>
        </Card>
      </div>
    </Edit>
  );
};

export default OrderEdit;
