import React from 'react';
import {
  Datagrid,
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  ArrayField,
  ReferenceField,
} from 'react-admin';

const CourierPayoutPolicyShow = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="ra.tab.policy">
        <TextField label="ra.label.name" source="name" sx={{ marginBottom: '20px' }} />
        <TextField
          label="ra.label.courier_logout_interval"
          source="courierLogoutInterval"
          sx={{ marginBottom: '20px' }}
        />
        <TextField
          label="ra.label.bill_photo_config"
          source="billPhotoConfiguration"
          sx={{ marginBottom: '20px' }}
        />
        <ArrayField source="courierPayoutSettings" label="ra.label.payout_setting">
          <Datagrid bulkActionButtons={false} sx={{ width: '700px' }}>
            <TextField label="ra.label.transport_type" source="type" />
            <TextField label="ra.label.coefficient_value" source="coefficient" />
            <ReferenceField
              label="ra.label.delivery_settings"
              source="deliverySettingId"
              reference="deliverySettings"
            >
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default CourierPayoutPolicyShow;
