import { Card } from '@mui/material';
import React from 'react';
import {
  BooleanInput,
  CheckboxGroupInput,
  Create,
  Labeled,
  PasswordInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import CancelButton from '../../../components/CancelButton';
import ResourceTitle from '../../../components/ResourceTitle';

import { deliveryStatusesOptions } from '../../constants';

const validateRequired = required();

const WebHooksCreate = () => {
  return (
    <Create title={<ResourceTitle field="webhook" />} actions={<CancelButton />} redirect="list">
      <SimpleForm redirect="list">
        <BooleanInput label="ra.label.enabled" source="enabled" validate={validateRequired} />
        <TextInput
          label="ra.label.callbackUrl"
          source="callbackUrl"
          validate={validateRequired}
          type="url"
        />
        <CheckboxGroupInput
          source="events"
          label="ra.label.events"
          choices={deliveryStatusesOptions()}
          validate={validateRequired}
          sx={{ maxWidth: '50%', width: '100%' }}
        />
        <Labeled label="ra.label.credentials">
          <Card variant="outlined">
            <TextInput
              label="ra.label.user_name"
              source="credentials.username"
              sx={{ display: 'block' }}
            />
            <PasswordInput
              label="ra.label.password"
              source="credentials.password"
              sx={{ display: 'block', maxWidth: '218px' }}
            />
          </Card>
        </Labeled>
      </SimpleForm>
    </Create>
  );
};

export default WebHooksCreate;
