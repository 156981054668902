import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useNotify, useTranslate } from 'react-admin';
import { Menu, MenuItem, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import useStyles from './styles';
import { baseUrl } from '../../../config/connection';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import { DELIVERY_STATUS_NAMES } from '../../../config/statuses.config';
import { DEFAULT_ZOOM, GOOGLE_MAPS_API_KEY, DEFAULT_GEO } from '../../../config/maps.config';
import SupplierPlacemark from '../../../components/maps/SupplierPlacemark';
import CourierPlacemark from '../../../components/maps/CourierPlacemark';
import RestaurantPlacemark from '../../../components/maps/RestaurantPlacemark';
import useAnchor from '../../../hooks/useAnchor';
import ClientPlacemark from '../../../components/maps/ClientPlacemark';

const courierStatuses = {
  online: 'ONLINE',
};

const mapOptions = () => ({
  fullscreenControl: false,
});

const DetailsMap = () => {
  const styles = useStyles();
  const { anchorEl, handleOpen, handleClose } = useAnchor();
  const [couriers, setCouriers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [googleMapsApi, setGoogleMapsApi] = useState();
  const [currentCourierPath, setCurrentCourierPath] = useState();
  const [clientGeo, setClientGeo] = useState(null);
  const [courierFilters, setCourierFilters] = useState({
    busy: true,
    available: true,
  });
  const translate = useTranslate();
  const notify = useNotify();

  const organizationGeo = localStorage.getItem('organizationGeo');
  const parsedGeo = JSON.parse(organizationGeo);
  const { lat, lng, defaultZoom } = parsedGeo;
  const currentOrganizationGeo = [lat, lng];
  const organizationId = localStorage.getItem('organizationId');

  useEffect(() => {
    const couriersRequest = () =>
      fetchWithAuthorization(`${baseUrl}/admin/courier?&limit=50&page=1&sort[0]=id,ASC&offset=0`)
        .then((res) => res.json())
        .then(({ data }) => {
          setCouriers(
            data.filter(({ status, location }) => status === courierStatuses.online && location),
          );
        })
        .catch((e) => notify(`${translate('ra.label.error')}: ${e}`, { type: 'warning' }));

    couriersRequest();
    const couriersInterval = setInterval(couriersRequest, 1000 * 60);

    return () => clearInterval(couriersInterval);
  }, [translate, notify]);

  useEffect(() => {
    const ordersRequest = () =>
      fetchWithAuthorization(
        `${baseUrl}/admin/delivery/today?&limit=50&page=1&sort[0]=id,ASC&offset=0`,
      )
        .then((res) => res.json())
        .then(({ data }) => {
          setOrders(
            data?.filter(({ deliveryStatus }) => {
              return ![
                DELIVERY_STATUS_NAMES.NEW,
                DELIVERY_STATUS_NAMES.CANCELED,
                DELIVERY_STATUS_NAMES.DELIVERED,
              ].includes(deliveryStatus);
            }),
          );
        })
        .catch((e) => notify(`${translate('ra.label.error')}: ${e}`, { type: 'warning' }));

    ordersRequest();
    const ordersInterval = setInterval(ordersRequest, 1000 * 60);

    return () => clearInterval(ordersInterval);
  }, [translate, notify]);

  useEffect(() => {
    const restaurantRequest = () =>
      fetchWithAuthorization(`
        ${baseUrl}/admin/restaurant?&filter%5B0%5D=organizationId%7C%7C%24eq%7C%7C${organizationId}
        &limit=50&page=1&sort[0]=id,ASC&offset=0
        `)
        .then((res) => res.json())
        .then(({ data }) => {
          const newArray = data.filter(
            (rest) => !orders.some(
              (order) => order.sourceAddress.geo.lat === rest.geo.lat &&
                order.sourceAddress.geo.lng === rest.geo.lng,
            ),
          );
          setRestaurants(newArray);
        })
        .catch((e) => notify(`${translate('ra.label.error')}: ${e}`, { type: 'warning' }));

    restaurantRequest();
    const restaurantsInterval = setInterval(restaurantRequest, 3000 * 60);

    return () => clearInterval(restaurantsInterval);
  }, [translate, notify, organizationId, orders]);

  const handleGoogleMapApi = (google) => setGoogleMapsApi(google);

  const clearPath = () => {
    if (currentCourierPath) {
      currentCourierPath.setMap(null);
    }

    setClientGeo(null);
  };

  const drawCourierPath = ({ currentOrderIds, location: { coordinates } }) => {
    clearPath();

    if (!currentOrderIds.length) return;

    const orderId = currentOrderIds[0];
    const currentOrder = orders.find(({ id }) => id === orderId);

    if (!currentOrder) return;

    const {
      sourceAddress: { geo: sourceGeo },
      deliveryAddress: { geo: targetGeo },
    } = currentOrder;

    const courierGeo = {
      lat: coordinates[1],
      lng: coordinates[0],
    };

    const path = [{ ...courierGeo }, { ...sourceGeo }, { ...targetGeo }];

    const { map, maps } = googleMapsApi;
    const courierPath = new maps.Polyline({
      path,
      strokeColor: 'orange',
      strokeOpacity: 1,
      strokeWeight: 5,
    });

    setCurrentCourierPath(courierPath);
    courierPath.setMap(map);
    setClientGeo(targetGeo);
  };

  return (
    <div className={styles.root}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        defaultCenter={currentOrganizationGeo || DEFAULT_GEO}
        defaultZoom={defaultZoom || DEFAULT_ZOOM}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleGoogleMapApi}
      >
        {orders.map((order) => (
          <SupplierPlacemark key={order.id} {...order.sourceAddress.geo} order={order} />
        ))}
        {restaurants.map((restaurant) => (
          <RestaurantPlacemark key={restaurant.id} {...restaurant.geo} restaurant={restaurant} />
        ))}
        {couriers.map((courier) => {
          const { userId, location, currentOrderIds } = courier;
          const busy = !!currentOrderIds.length;

          if (!((courierFilters.busy && busy) || (courierFilters.available && !busy))) {
            return null;
          }

          return (
            <CourierPlacemark
              key={userId}
              lat={location.coordinates[1]}
              lng={location.coordinates[0]}
              courier={courier}
              iconStyles={busy ? styles.busyCourier : null}
              onClick={() => drawCourierPath(courier)}
            />
          );
        })}
        {clientGeo && <ClientPlacemark {...clientGeo} />}
      </GoogleMapReact>
      <div className={styles.filter}>
        <Button variant="contained" onClick={handleOpen}>
          {translate('ra.label.courier_filters')}
        </Button>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              setCourierFilters({
                ...courierFilters,
                busy: !courierFilters.busy,
              });
              clearPath();
            }}
            className={styles.filterItem}
          >
            {translate('ra.label.busy')}
            {courierFilters.busy && <CheckIcon color="primary" />}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setCourierFilters({ ...courierFilters, available: !courierFilters.available });
            }}
            className={styles.filterItem}
          >
            {translate('ra.label.available')}
            {courierFilters.available && <CheckIcon color="primary" />}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default DetailsMap;
