import React from 'react';
import { useTranslate } from 'react-admin';
import { CSVLink } from 'react-csv';

const CSVExport = ({ data, headers, label }) => {
  const translate = useTranslate();

  return (
    <CSVLink
      data={data}
      style={{
        color: '#477890',
        textDecoration: 'none',
        padding: '15px 15px 15px 0',
      }}
      filename="report.csv"
      headers={headers}
    >
      {translate(label || 'ra.action.export_csv')}
    </CSVLink>
  );
};

export default CSVExport;
