/* eslint-disable max-len */
const messages = {
  ra: {
    week_day: {
      monday: 'Luni',
      tuesday: 'Marţi',
      wednesday: 'Miercuri',
      thursday: 'Joi',
      friday: 'Vineri',
      saturday: 'Sâmbătă',
      sunday: 'Duminică',
    },
    label: {
      customer: 'Client',
      date: 'Data',
      totalSum: 'Suma totala',
      totalPrice: 'Prețul total',
      basePrice: 'Prețul de baza',
      quantity: 'Cantitatea',
      firstName: 'Prenume',
      lastName: 'Nume',
      sourceAddress: 'Adresa sursei',
      deliveryAddress: 'Adresa de livrare',
      intercom: 'Interfon',
      instructions: 'Instrucții',
      entrance: 'Intrarea',
      floor: 'Scara',
      apartment: 'Apartmentul',
      extraInfo: 'Informație suplimentară',
      formattedValue: 'Valoarea formatată',
      paymentStatus: 'Statutul plății',
      paymentMethod: 'Metoda de plată',
      countryName: 'Țară',
      cityName: 'Oraș',
      streetNumber: 'Numarul strazii',
      buildingNumber: 'Numarul casei',
      cant_assign_courier: 'Curierul nu poate fi atribuit',
      launch_date: 'Data lansării',
      amount_lei: 'Sumă ( Lei )',
      day: 'zi',
      days: 'zile',
      hour: 'ora',
      hours: 'ore',
      ago: 'în urmă',
      updated: 'Actualizat',
      photo: 'Imagine',
      items: 'Itemi',
      id: 'ID',
      label: 'Label',
      action: 'Acțiune',
      filters: 'Filtre:',
      courier_filters: 'Filtru de curieri',
      perks: 'Avantaje:',
      name: 'Nume',
      discount: 'Discount',
      data: 'Valabil până la',
      notes: 'Notițe',
      supplier: 'Furnizor',
      supplierBranch: 'Sucursala furnizorului',
      country: 'Țară',
      area: 'Municipiul',
      locality: 'Oraș',
      street: 'Strada',
      house: 'Casă',
      store: 'Store',
      status: 'Status',
      slug: 'Numele domeniului',
      referrals: 'Referali',
      referrer: 'Referent',
      first_name: 'Prenume',
      last_name: 'Nume',
      phone: 'Telefon',
      busy: 'Ocupat',
      description: 'Descriere',
      cook_time: 'Timpul de gătit',
      available: 'Disponibil',
      available_products: 'Produse disponibile',
      price: 'Preț',
      weight: 'Greutate',
      serving_size: 'Mărimea porției',
      unit: 'Unitate',
      highlight: 'Notă',
      payment_methods: 'Metode de plată',
      payment_config: 'Configurare de plată',
      schedule: 'Orar',
      category: 'Categorie',
      active: 'Activ',
      inactive: 'Inactiv',
      is_multiple: 'Este multiplu',
      is_required: 'Este necesară',
      option: 'Opțiuni de produs',
      maxSelectedOptions: 'Numărul maxim de opțiuni selectate',
      is_free_option: 'Este opțiune gratuită',
      address: 'Adresă',
      order_instructions: 'Comentariul comenzii',
      client: 'Client',
      courier: 'Curier',
      payout_policy: 'Politica de plată a curierilor',
      payout_setting: 'Setari de plată a curierilor',
      options: 'Opțiuni',
      ordered_at: 'Comandat la',
      order_status: 'Starea comenzii',
      actions: 'Acțiuni',
      terminal: 'Terminal',
      supplier_select: 'Selectați furnizorul',
      supplier_branch_select: 'Selectați sucursala furnizorului',
      meta_title: 'Meta titlu',
      meta_description: 'Meta descriere',
      allow_under_age: 'Disponibil pentru minori',
      self_management: 'Autogestionare',
      cover_image: 'Copertă',
      card_image: 'Imaginea cardului',
      courier_source: 'Courier source',
      rank: 'Rank',
      price_range: 'Gama de prețuri',
      type: 'Tip furnizor',
      delivery_settings: 'Setări de livrare',
      delivery_settings_id: 'ID Setări de livrare',
      fixed: 'Fix',
      progressive: 'Progresist',
      calculation_type: 'Tipul de calcul',
      fixed_price: 'Preț fix',
      base_price: 'Pret de baza',
      distances: 'Distanțe',
      modifier: 'Modificator (%{mdl})',
      delivery_price: 'Prețul de livrare',
      peak_hours: 'Ore de varf',
      minute: 'minută',
      minutes: 'minute',
      order_price_ranges: 'Comandați modificatorii de preț',
      free_delivery: 'Livrare gratuita',
      setting_type: 'Tipul de setare',
      constant_price_modifier: 'Modificator constant de preț',
      estimatedPreparation: 'Timpul estimat de preparare',
      price_per_km: 'bani/km',
      enabled: 'Activat',
      events: 'Evenimente',
      secured: 'Securizat',
      message: 'Message',
      masked_card: 'Masked CARD',
      rrn: 'RRN',
      sum: 'Sumă',
      created_at: 'Creat la',
      created_by: 'Creat de',
      deleted_at: 'Șters la',
      deactivated_at: 'Dezactivat la',
      order_id: 'ID comenzii',
      contact_name: 'Nume de contact',
      user: 'Utilizator',
      user_name: 'Nume de utilizator',
      password: 'Parola',
      role: 'Rol',
      branch: 'Sucursala',
      email: 'Email',
      selected_count: 'Selectați %{count} din %{all} Itemi',
      just_now: 'chiar acum',
      filter: 'Filtru',
      comment: 'Comentariu',
      added: 'Adăugat',
      estimated: 'Estimat',
      actual: 'Real',
      external: 'Extern',
      current_courier: 'Curier curent',
      from: 'Din',
      to: 'La',
      supplier_id: 'ID Furnizor',
      transport_type: 'Tipul de transport',
      coefficient_value: 'Valoarea coeficientului',
      uppercase: {
        supplier: 'SUPPLIER',
        notification: 'NOTE',
        show: 'ARATĂ',
        comm: 'COMM',
        edit: 'EDIT',
        address: 'ADRESA',
        ordered_at: 'TIMP',
        price: 'PREȚ',
        status: 'STATUS',
        actions: 'ACȚIUNI',
        courier: 'CURIER',
      },
      unlimitedOptions: '0 sau gol = nelimitat',
      unlimited: 'Nelimitat',
      marketing_campaigns: 'Campanii de marketing',
      marketing_campaigns_action_type: 'Tipul acțiunii campaniei',
      marketing_campaign_source_type: 'Tipul filtrului campaniei',
      marketing_source_filter_type: 'Tipul filtrului',
      marketing_action_type: 'Tipul acției',
      launchDate: 'Data lansării',
      effectiveDate: 'Data efectivă',
      run_init: 'Inițiază run',
      run_simulate: 'Simulare run',
      validityInHours: 'Valabil... (ore)',
      discounts_sent: 'Reduceri trimise',
      discounts_used: 'Reduceri folosite',
      discounts_cost: 'Costul reducerilor folosite',
      ordersValue: 'Pretul produselor vîndute',
      file: {
        upload_several:
          'Trageți unele fișiere pentru încărcare sau faceți clic pentru a selecta unul.',
        upload_single: 'Trageți un fișier pentru încărcare sau faceți clic pentru al selecta.',
      },
      image: {
        upload_several:
          'Trageți câteva fotografii pe care le încărcați sau faceți clic pentru a selecta una.',
        upload_single: 'Trageți o fotografie pentru încărcare sau faceți clic pentru ao selecta.',
      },
      references: {
        all_missing: 'Imposibil de găsit date de referință.',
        many_missing: 'Cel puțin una dintre referințele asociate nu mai pare a fi disponibilă.',
        single_missing: 'Referința asociată nu mai pare disponibilă.',
      },
      returnToOffice: 'Returnează la oficiu',
      doNothing: 'Nicio acțiune',
      nextSteps: 'Pașii următori',
      refusedOrderComment: 'Comentariu pentru comanda refuzată',
      refund: 'Restituie banii',
      supplier_store: 'Store',
      callbackUrl: 'Callback URL',
      event: 'Evente',
      credentials: 'Credențiale',
      maxDistanceNearPickUpLocation: 'Distanta maximă dintre curier și localul',
      maxDistanceNearDestination: 'Distanta maxima pina la client',
      maxOrdersPerCourier: 'Număr maxim de comenzi per curier',
      retryTime: 'Timpul de reîncercare între căutare',
      timeAwaitingCourierConfirmation: 'Așteptarea confirmării de către curier',
      timeIgnoreCourier: 'Timpul de ignorare a curierului în caz curier nu răspunde',
      multiplierModifier: 'Coeficientul de multiplicare distantei de căutare nu ma mult de ',
      intervalGetCourierLocation: 'Intervalul de actualizarea geolocatie a curierilor',
      idle_first: 'Inactivi',
      picking_up_first: 'În așteptare',
      nearest: 'Cel mai apropiat',
      fastest: 'Cel mai rapid',
      startTime: 'Timpul de start',
      retryTimes: 'Reîncercări',
      courierUnconfirmed: 'Curieri neconfirmați',
      courierAssignedTime: 'Timpul de atașare a curierului',
      deliveryRequestId: 'Id-ul livrării',
      courierUserId: 'Id-ul curierului',
      sessionStatus: 'Statutul sesiunii',
      stopAutomation: 'Acțiuni',
      permissions: 'Permisiuni',
      view: 'Vedere',
      create: 'Creare',
      edit: 'Editare',
      delete: 'Ștergere',
      view_api_key: 'Vedere Api Key',
      create_api_key: 'Creare Api Key',
      delete_api_key: 'Ștergere Api Key',
      lat: 'Latitudine',
      lng: 'Longitudine',
      default_zoom: 'Default zoom',
      sortOptions: 'Opțiunea de sortare',
      geo: 'Locația',
      courier_logout_interval: 'Intervalul de delogare a curierului',
      bill_photo_config: 'Configurație foto cec',
    },
    tab: {
      summary: 'Rezumat',
      variants: 'Variații',
      option_groups: 'Grup de opțiuni',
      options: 'Opțiuni',
      courier: 'Curier',
      suppliers: 'Furnizori',
      supplier_addresses: 'Sucursalele furnizorului',
      goods: 'Bunuri',
      user_address: 'Adresa',
      policy: 'Policy',
      refusedOrders: 'Comenzi refuzate',
      campaign: 'Campania',
      run: 'Campania lansată',
      participants: 'Participanți (%{count})',
      statistics: 'Statistica',
      courier_automation_settings: 'Setări automatizare curieri',
      organization_users: 'Utilizatorii organizațiilor',
      sessions_table: 'Tabelul de sesiuni',
    },
    table: {
      column: {
        option_group_name: 'Numele grupului de opțiuni',
        activate_for_this_good: 'Activ pentru acest produs',
        variant_name: 'Numele variației',
        variant_weight: 'Greutatea variației',
        variant_price: 'Prețul variației',
      },
    },
    action: {
      add_option: 'Adăugați opțiune',
      add_option_group: 'Adăugați grup de opțiuni',
      add_variant: 'Adăugați variație',
      add_filter: 'Adăugați un filtru',
      add_address: 'Adăugați sucursala',
      add_good: 'Adăugați marfă',
      add_supplier: 'Adauga furnizor',
      agree: 'Acord',
      disagree: 'Dezacord',
      manage_product: 'Gestionați produsul',
      menu_save: 'Salvează meniu',
      menu_add_new_section: 'Adăugați o nouă secțiune',
      menu_parse_products: 'Descărcați produse',
      menu_manage_products: 'Gestionează produsele',
      menu_edit_section_title: 'Editați titlul secțiunii',
      add: 'Adăugați',
      back: 'Înapoi',
      bulk_actions: '1 element selectat |||| %{smart_count} elemente selectate',
      cancel: 'Anulare',
      clear_input_value: 'Sterge valoare',
      clone: 'Copie',
      confirm: 'Confirmă',
      create: 'Creează',
      delete: 'Șterge',
      edit: 'Editează',
      export: 'Exportă',
      list: 'Listă',
      refresh: 'Reîmprospătează',
      remove_filter: 'Șterge filtru',
      remove: 'Elimină',
      save: 'Salvează',
      save_settings: 'Salvează setarile',
      enable: 'Pornește',
      search: 'Cautare',
      show: 'Arată',
      sort: 'Sortează',
      undo: 'Anulează',
      expand: 'Extinde',
      close: 'Închide',
      stop: 'Stop',
      resume: 'Continuă',
      export_csv: 'Exportați ca .csv',
      select_section: 'Selectați secțiunea',
      remove_section: 'Ștergeți secțiunea',
      remove_discount: 'Șterge discount',
      set_discount: 'Atribuie discount',
      select_time_range: 'Selectați intervalul de timp',
      process_transaction: 'Procesează tranzacție',
      export_monthly_report: 'Exportați raportul lunar',
      generate_report: 'Generează raport',
      give_bonus: 'Oferiți bonus',
      select_all: 'Selectați tot',
      remove_all: 'Ștergeți tot',
      price_edit: 'Redactează prețul',
      edit_schedule: 'Redactează orarul',
      use_schedule: 'Utilizați programul',
      remove_access: 'Șterge accesul',
      access_key_remove_confirm: 'Confirmați ștergerea cheii de acces?',
      create_api_key: 'Creează cheie de acces',
      edit_permissions: 'Editează permisiunile',
      deactivate_organization: 'Dezactivează organizația',
      reactivate_organization: 'Reactivează organizația',
    },
    status: {
      button: {
        processing: 'Proceseză',
        confirmed: 'Confirmă',
        preparing: 'Se pregăteste',
        prepared: 'Pregătit',
        delivering: 'Livrare',
        delivered: 'Livrat',
        accepted: 'Accept',
        canceled: 'Anulare',
      },
      new: 'Nou',
      accepted: 'Acceptat',
      canceled: 'Anulat',
      processing: 'Procesare',
      confirmed: 'Confirmat',
      preparing: 'Se prepară',
      prepared: 'Pregătit',
      delivering: 'Se livrează',
      delivered: 'Livrat',
      awaiting_payment: 'În așteptarea plății',
      payment_failed: 'Plata esuata',
      courier_assign: 'Curier atribuit',
      courier_assigned: 'Curier atribuit',
      courier_reassign: 'Curier reatribuit',
      courier_confirmed: 'Curier confirmat',
      courier_in_restaurant: 'Curier în restaurant',
      in_progress: 'In progres',
      scheduled: 'Programat',
      refused: 'Refuzat',
    },
    boolean: {
      true: 'Da',
      false: 'Nu',
    },
    discount: {
      cannotHandleFilter: 'Nu este suport pentru filtre de tip %{type}!',
      cannotHandlePerk: 'Nu este suport pentru perk de tip %{type}!',
      paymentFilter: {
        set: 'Setați tipul de plată: ',
      },
      sumFilter: {
        set: 'Setați suma minimă: ',
      },
      percentPerk: {
        set: 'Setați procentajul: ',
      },
      amountPerk: {
        set: 'Setați suma: ',
      },
    },
    page: {
      create: 'Crearea %{name}',
      dashboard: 'Tablou de bord',
      edit: '%{name} #%{id}',
      error: 'Ceva n-a mers bine',
      list: '%{name}',
      loading: 'Se încarcă',
      not_found: 'Nu a fost găsit',
      show: '%{name} #%{id}',
      empty: 'Nu s-au găsit elemente',
    },
    title: {
      courier_cost: 'Costul curierului',
      address_data: 'Date de adresă',
      map: 'Vezi pe hartă',
      order_instruction: 'Comentariu pentru comandă',
      client_data: 'Date despre client',
      supplier_data: 'Date furnizor',
      courier_data: 'Date de curier',
      order_items: 'Elementele comenzii',
      total_price: 'Prețul total',
      total_discounts: 'Promo',
      order_price: 'Prețul comenzii',
      delivery_price: 'Prețul livrării',
      time_data: 'Date de timp',
      processing_time: 'Timp de procesare',
      preparing_time: 'Timpul pregătirei',
      delivery_time: 'Timpul de livrare',
      total_time: 'Timpul total',
      payment_method: 'Tipul de plată',
      porch: 'Scara',
      porch_code: 'Cod interfon',
      floor: 'Etaj',
      apartment: 'Ap.',
      today: 'Azi',
      total: 'Total',
      instruction: 'Comentariul',
      sum: 'Sumă',
      assign_courier: 'Asignați curier',
      re_assign_courier: 'Reasignați curier',
      not_confirmed_courier: 'Nu este confirmata de curier. Reasignați curierul',
      show_order: 'Afișează comanda',
      edit_order: 'Editează comanda',
      reports: 'Rapoarte',
      select_courier: 'Selectați curierul',
      comment_order: 'Adauga comentariu la comanda',
      order_staff_comment: 'Comentariul personalului',
      user_email: 'Email',
      status_history: 'Istoricul Stării',
      fiscal_difference: 'Diferența Fiscală',
      street_number: 'Numarul străzii',
      intercom: 'Interfon',
      entrance: 'Intrare',
      area: 'Zona',
      country: 'Țara',
      locality: 'Oraș',
      block: {
        address: 'Adresă',
        order_staff_comment: 'Comentariul personalului',
        order_comment: 'Comentariu pentru comandă',
        client_data: 'Client',
        supplier_data: 'Furnizor',
        courier_data: 'Curier',
        order_items: 'Elementele comenzii',
        price: 'Preț',
        time_data: 'Timp',
        payment_method: 'Tipul de plată',
        cancel_reason: 'Motivul anulării',
        restaurant_details: 'Detaliile restauranului',
      },
      add_new_item: 'Adauga product la comanda',
      select_item: 'Selectati marfa',
    },
    message: {
      no_number: 'Fără număr de telefon',
      menu_activate: 'Sunteți sigur că doriți să activați ',
      menu_deactivate: 'Sunteți sigur că doriți să dezactivați ',
      about: 'Despre',
      are_you_sure: 'Sunteți sigur?',
      user_is_not_referral: 'Utilizatorul nu a fost invitat',
      bulk_delete_full_name:
      'Sigur doriți să ștergeți elementul %{firstName} %{lastName}? |||| Sigur doriți să ștergeți aceste %{smart_count} elemente selectate?',
      bulk_delete_name:
      'Sigur doriți să ștergeți elementul %{name}? |||| Sigur doriți să ștergeți aceste %{smart_count} elemente selectate?',
      delete_content: 'Sigur doriți să ștergeți acest element?',
      delete_full_name: 'Sigur doriți să ștergeți elementul %{firstName} %{lastName}?',
      delete_name: 'Sigur doriți să ștergeți elementul %{name}?',
      details: 'Detalii',
      error: 'A apărut o eroare de client și solicitarea dvs. nu a putut fi finalizată.',
      invalid_form: 'Formularul nu este valabil. Verificați dacă există erori.',
      loading: 'Pagina se incarca.',
      no: 'Nu',
      not_found: 'Fie ați introdus o adresă URL greșita, sau ați urmat un link invalid.',
      yes: 'Da',
      cancel_order_confirm: 'Sunteți sigur că vreți să anulați această comandă?',
      yes_cancel_order: 'Da, anulează comanda',
      not_cancel_order: 'Nu anula comanda',
      set_time: 'Setați timpul de gătit',
      scheduled_at: 'Plasată pentru',
      invalid_login: 'Login sau parolă invalidă',
    },
    navigation: {
      no_results: 'Nici un rezultat găsit.',
      no_more_results:
        'Numărul paginii %{page} este în afara granițelor. Încercați pagina anterioară.',
      page_out_of_boundaries: 'Numărul paginii %{page} este în afara limitelor.',
      page_out_from_end: 'Nu pot merge după ultima pagină',
      page_out_from_begin: 'Nu pot merge înainte de pagina 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} din %{total}',
      page_rows_per_page: 'Rânduri pe pagină:',
      next: 'Următoare',
      prev: 'Anterioară',
      min: 'min',
      distance: 'Distanţă',
      km: 'km',
      m: 'm',
      money: 'bani',
      menu_management: 'Gestionarea Meniului',
      marketing: 'Marketing',
      cms: 'CMS',
      user_management: 'Gestionarea Utilizatorului',
      delivery_management: 'Gestionarea Livrării',
      supplier_management: 'Gestionarea Furnizorului',
      payment_config: 'Configurarea Plății',
      integrations: 'Integrări',
    },
    auth: {
      user_menu: 'Profilul',
      username: 'Username',
      password: 'Parola',
      sign_in: 'Conectați-vă',
      reset_password: 'Resetează parola',
      sign_in_error: 'Autentificarea a eșuat, reîncercați',
      logout: 'Deconectare',
    },
    notification: {
      updated: 'Elementul a fost actualizat |||| %{smart_count} elemente au fost actualizate',
      created: 'Elementul a fost creat',
      deleted: 'Elementul a fost șters |||| %{smart_count} elemente au fost șterse',
      bad_item: 'Element incorect',
      item_doesnt_exist: 'Elementul nu există',
      http_error: 'Eroare de comunicare cu serverul',
      data_provider_error: 'eroare de dataProvider. Verificați consola pentru detalii.',
      canceled: 'Acțiunea a fost anulată',
      logged_out: 'Sesiunea dvs. sa încheiat, vă rugăm să vă reconectați.',
      unsaved_changes: 'Modificări nesalvate!',
      forbid_delete_one:
      'Elementul nu poate fi sters. In lista trebuie sa ramana cel putin un element',
      forbid_delete_all:
      'Nu puteti starge toate elementele. In lista trebuie sa ramana cel putin un element',
    },
    validation: {
      required: 'Necesar',
      minLength: 'Trebuie să fie cel puțin %{min} caractere',
      maxLength: 'Trebuie să fie până la %{max} caractere',
      minValue: 'Trebuie să fie minim %{min}',
      maxValue: 'Trebuie să fie maxim %{max}',
      number: 'Trebuie să fie un număr',
      email: 'Email-ul trebuie să fie valid',
      oneOf: 'Trebuie să fie unul din: %{options}',
      regex: 'Trebuie să corespundă unui format specific (regexp): %{pattern}',
    },
  },
  resources: {
    reportsDiscount: {
      name: 'Raport Reduceri',
    },
    'courier-auto-settings': {
      name: 'Automatizare Curieri',
    },
    navigation: {
      name: 'Menu Navigare',
    },
    'delivery/today': {
      name: 'Comenzi',
    },
    'delivery/history': {
      name: 'Istoricul Comenzilor',
    },
    supplier: {
      name: 'Furnizori',
    },
    address: {
      name: 'Adrese',
    },
    store: {
      name: 'Stores',
    },
    'store-supplier': {
      name: 'Store Suppliers',
    },
    goods: {
      name: 'Marfa',
    },
    variants: {
      name: 'Variante',
    },
    category: {
      name: 'Categorii',
    },
    tag: {
      name: 'Taguri',
    },
    optionGroup: {
      name: 'Grupa de opții',
    },
    option: {
      name: 'Opții',
    },
    ingredient: {
      name: 'Ingrediente',
    },
    user: {
      name: 'Utilizator',
    },
    useraddress: {
      name: 'Adresele utilizatorilor',
    },
    orderitem: {
      name: 'Elemente comenzii',
    },
    courier: {
      name: 'Curieri',
    },
    transaction: {
      name: 'Tranzacţii',
    },
    menu: {
      name: 'Meniu',
    },
    paymentConfig: {
      name: 'Configurare de plată',
    },
    posts: {
      fields: { tag: 'Taguri' },
    },
    discount: {
      name: 'Reduceri',
    },
    deliverySettings: {
      name: 'Setări de livrare',
    },
    policy: {
      name: 'Plata curierilor',
    },
    'marketing-campaigns': {
      name: 'Campania',
    },
    'marketing-campaign-runs': {
      name: 'Porneste campania',
    },
    webhook: {
      name: 'WebHooks',
    },
    organization: {
      name: 'Organizații',
    },
    'api-access': {
      name: 'Api Keys',
    },
    restaurant: {
      name: 'Restaurante',
    },
  },
};

export default messages;
