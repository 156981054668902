import React from 'react';
import { TextInput, Filter } from 'react-admin';

export const PostFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Phone" source="phone" alwaysOn />
      <TextInput label="Email" source="email" alwaysOn />
    </Filter>
  );
};
