export const SOCKET_INIT = 'SOCKET_INIT';
export const SOCKET_STOP = 'SOCKET_STOP';

const initialState = {
  client: null,
};

export const initSocket = (payload) => ({ type: SOCKET_INIT, payload });
export const stopSocket = () => ({ type: SOCKET_STOP });
// eslint-disable-next-line default-param-last
export const socketReducer = (state = initialState, { payload, type }) => {
  const actionResolver = {
    SOCKET_INIT: () => {
      return payload
        ? ({
          ...state,
          client: payload,
        })
        : state;
    },

    SOCKET_STOP: () => ({
      ...state,
      client: null,
    }),

    default: () => state,
  };

  const actionHandler = actionResolver[type] ? actionResolver[type] : actionResolver.default;

  return actionHandler();
};
