import React from 'react';
import {
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import ResourceTitle from '../../../components/ResourceTitle';
import formatDateTime from '../../../utils/formatDateTime';

const RestaurantShow = () => {
  return (
    <Show title={<ResourceTitle field="firstName" />}>
      <SimpleShowLayout>
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.name" source="name" />
        <FunctionField
          label="ra.label.geo"
          render={({ geo }) => {
            return `Lat: ${geo.lat}, Lng: ${geo.lng}`;
          }}
        />
        <FunctionField
          label="ra.label.address"
          render={({ address }) => {
            const {
              country,
              locality,
              area,
              street,
              house,
            } = address;

            return Object.keys(address).length !== 0 ? `${country}, ${locality},
              ${area}, ${street} ${house}
            ` : '-';
          }}
        />
        <TextField label="ra.label.phone" source="phoneNumber" />
        <FunctionField
          label="ra.label.created_at"
          render={({ createdAt }) => {
            const parsedDateAndTime = formatDateTime(createdAt);

            return `${parsedDateAndTime.date} ${parsedDateAndTime.time}`;
          }}
        />
        <FunctionField
          label="ra.label.updated"
          render={({ updatedAt }) => {
            const parsedDateAndTime = formatDateTime(updatedAt);

            return `${parsedDateAndTime.date} ${parsedDateAndTime.time}`;
          }}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default RestaurantShow;
