import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  DateInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from 'react-admin';

import CancelButton from '../../../components/CancelButton';
import ResourceTitle from '../../../components/ResourceTitle';

const validateRequired = required();

const paymentMethodChoices = [
  {
    name: 'CREDIT CARD',
    id: 'credit-card',
  },
  {
    name: 'CASH',
    id: 'cash',
  },
  {
    name: 'CREDIT CARD ONLINE',
    id: 'credit-card-online',
  },
];

const paymentStatusChoices = [
  {
    name: 'SUCCESS',
    id: 'success',
  },
  {
    name: 'FAILED',
    id: 'failed',
  },
  {
    name: 'PENDING',
    id: 'pending',
  },
];

const CreateRequestCreate = (props) => {
  const organizationId = localStorage.getItem('organizationId');
  const translate = useTranslate();

  return (
    <Create
      {...props}
      title={<ResourceTitle field="name" />}
      actions={<CancelButton />}
      redirect="list"
    >
      <SimpleForm redirect="list">
        <TextInput label="ra.label.order_id" source="orderId" validate={validateRequired} />
        <DateInput label="ra.label.date" source="date" sx={{ width: '218px' }} />
        <NumberInput label="ra.label.totalSum" source="totalSum" validate={validateRequired} />
        {['firstName', 'lastName'].map((part) => (
          <TextInput
            key={part}
            label={`${translate('ra.label.customer')} - ${translate(`ra.label.${part}`)}`}
            source={`customer.${part}`}
            validate={validateRequired}
          />
        ))}
        <TextInput
          label={`${translate('ra.label.customer')} - ${translate('ra.label.phone')}`}
          source="customer.phoneNumber"
          validate={validateRequired}
        />
        <ReferenceInput source="restaurantId" reference="restaurant" filter={{ organizationId }}>
          <AutocompleteInput
            label="ra.label.sourceAddress"
            optionText="name"
            sx={{ width: '218px' }}
          />
        </ReferenceInput>
        {[
          'street',
          'streetNumber',
          'locality',
          'area',
          'country',
          'buildingNumber',
          'intercom',
          'entrance',
          'floor',
          'apartment',
          'extraInfo',
          'formattedValue',
        ].map((part) => (
          <TextInput
            key={part}
            label={`${translate('ra.label.deliveryAddress')} - ${translate(`ra.label.${part}`)}`}
            source={`deliveryAddress.${part}`}
            validate={validateRequired}
          />
        ))}
        <NumberInput
          label={`${translate('ra.label.deliveryAddress')} - ${translate('ra.label.lat')}`}
          source="deliveryAddress.geo.lat"
          validate={validateRequired}
        />
        <NumberInput
          label={`${translate('ra.label.deliveryAddress')} - ${translate('ra.label.lng')}`}
          source="deliveryAddress.geo.lng"
          validate={validateRequired}
        />
        <SelectInput
          label="ra.label.paymentMethod"
          source="paymentMethod"
          choices={paymentMethodChoices}
          validate={validateRequired}
          sx={{ width: '218px' }}
        />
        <SelectInput
          label="ra.label.paymentStatus"
          source="paymentStatus"
          choices={paymentStatusChoices}
          validate={validateRequired}
          sx={{ width: '218px' }}
        />
        <ArrayInput source="products">
          <SimpleFormIterator inline>
            <TextInput label="ra.label.id" source="id" helperText={false} />
            <TextInput label="ra.label.name" source="name" helperText={false} />
            <NumberInput label="ra.label.totalPrice" source="totalPrice" helperText={false} />
            <NumberInput label="ra.label.basePrice" source="basePrice" helperText={false} />
            <NumberInput label="ra.label.quantity" source="quantity" helperText={false} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateRequestCreate;
