import RestaurantCreate from './components/Create';
import RestaurantEdit from './components/Edit';
import RestaurantList from './components/List';
import RestaurantShow from './components/Show';

export default {
  list: RestaurantList,
  edit: RestaurantEdit,
  show: RestaurantShow,
  create: RestaurantCreate,
};
