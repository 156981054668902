import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  addressFormRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addressInput: {
    flex: 1,
    marginRight: 10,
  },
  map: {
    height: '700px',
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default useStyles;
