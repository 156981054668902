import React from 'react';
import { Pagination, useListContext } from 'react-admin';
import useStyles from './styles';

const handler = (
  event,
  setPage,
) => {
  const page = event.target.value;
  if (event.key === 'Enter' && page > 0) {
    setPage(page);
  }
};
const PostPagination = () => {
  const { page, setPage } = useListContext();
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <input
        className={styles.input}
        placeholder={page}
        onKeyPress={(e) => handler(e, setPage)}
      />
      <Pagination rowsPerPageOptions={[5, 10, 25, 35, 50]} />
    </div>
  );
};

export default PostPagination;
