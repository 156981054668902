import { makeStyles, styled } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '5px',
  },
  statusButton: {
    margin: '0 5px',
  },
  assignCourierWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});

export const DefaultWrapper = styled('div')({
  width: 110,
});

export default useStyles;
