import React from 'react';
import {
  Edit,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import ResourceTitle from '../../../components/ResourceTitle';
import CancelButton from '../../../components/CancelButton';
import LatLngInput from '../../../components/LatLngInput';

const validateRequired = required();

const EditToolbar = (props) => {
  return (
    <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton alwaysEnable />
    </Toolbar>
  );
};

const RestaurantEdit = () => {
  return (
    <Edit title={<ResourceTitle field="name" />} actions={<CancelButton />}>
      <SimpleForm redirect="list" toolbar={<EditToolbar />}>
        <TextInput label="ra.label.name" source="name" validate={validateRequired} />
        {[
          'locality',
          'area',
          'house',
          'street',
          'country',
        ].map((part) => (
          <TextInput
            key={part}
            label={`ra.label.${part}`}
            source={`address.${part}`}
            validate={validateRequired}
          />
        ))}
        <TextInput label="ra.label.phone" source="phoneNumber" validate={validateRequired} />
        <LatLngInput />
      </SimpleForm>
    </Edit>
  );
};

export default RestaurantEdit;
