import React, { useEffect, useMemo, useState } from 'react';
import { FunctionField, useListContext, useListController } from 'react-admin';
import OrderedAtField from '../../../components/OrderedAtField';
import PriceField from '../../../components/PriceField';
import StatusLabel from '../../../components/StatusLabel';
import EditOrder from '../EditOrderButton';
import { DELIVERY_STATUS_NAMES } from '../../../config/statuses.config';
import notification from '../../../assets/notification.wav';
import CustomDatagrid from './CustomDatagrid';
import ShowActions from '../Actions';
import ShowOrder from '../ShowOrderButton';

export const Supplier = ({ supplier }) => {
  const supplierValues = [supplier?.supplierName, supplier?.branchName].filter(Boolean);

  return supplierValues.join(', ');
};

export const Address = (record) => {
  return record.deliveryAddress.formattedValue;
};

const DailyId = ({ id, ids, total, page, perPage }) => {
  // let ordersBefore = total - page * perPage;

  // if (ordersBefore < 0) {
  //   ordersBefore = 0;
  // }
  const orderNumInReverse = ids.length - ids.findIndex((orderId) => orderId === id);

  // const dailyId = orderNumInReverse + ordersBefore;
  const dailyId = orderNumInReverse;

  return <span>{dailyId}</span>;
};

const getIsHighlighted = ({ deliveryStatus, updatedAt }) =>
  ![
    DELIVERY_STATUS_NAMES.CANCELED,
    DELIVERY_STATUS_NAMES.DELIVERED,
    DELIVERY_STATUS_NAMES.PAYMENT_FAILED,
    DELIVERY_STATUS_NAMES.SCHEDULED,
  ].includes(deliveryStatus) && Date.now() - new Date(updatedAt) > 40 * 60 * 1000;

const highlightRowStyle = (record) => {
  if (record.supplierStatus === DELIVERY_STATUS_NAMES.SCHEDULED) {
    return {
      background: '#d8d8d8',
    };
  }

  return {
    background: getIsHighlighted(record) ? '#FFDFDE' : 'none',
  };
};

const notificationAudio = new Audio(notification);

const OrderListDatagrid = ({ listProps }) => {
  const { data: rows, total, perPage, page, isLoading } = useListContext();

  const defaultIds = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return rows?.map((row) => row.id);
  }, [rows, isLoading]);

  const listController = useListController();

  const organizationPermissions = localStorage.getItem('organizationPermissions');
  const hasEditPermission = organizationPermissions.includes('edit');

  const [ids, setIds] = useState([]);

  useEffect(() => {
    if (rows?.length) {
      setIds(defaultIds);
    }

    const highlightedOrdersInterval = setInterval(() => {
      if (rows?.length) {
        setIds(defaultIds);
      }
    }, 1000 * 60);

    return () => clearInterval(highlightedOrdersInterval);
  }, [rows, defaultIds]);

  useEffect(() => {
    if (!defaultIds?.length) return;

    let orders = JSON.parse(localStorage.getItem('orders'));
    const arrivedOrdersIds = defaultIds.filter(
      (id, idx) => rows[idx].id === id && rows[idx].supplierStatus === DELIVERY_STATUS_NAMES.NEW,
    );

    if (!arrivedOrdersIds.length) return;

    if (!orders) {
      orders = arrivedOrdersIds.map((id) => ({ id, isRang: false }));
    }

    const orderIds = orders.map((order) => order.id);

    arrivedOrdersIds.reverse().forEach((id) => {
      if (!orderIds.includes(id)) orders.unshift({ id, isRang: false });
    });

    if (orders.length > 50) {
      orders = orders.slice(0, 50);
    }

    for (let i = orders.length - 1; i >= 0; i--) {
      const order = orders[i];
      if (!order.isRang) {
        notificationAudio.play();
        order.isRang = true;
        break;
      }
    }

    localStorage.setItem('orders', JSON.stringify(orders));
  }, [defaultIds, rows]);

  return (
    <CustomDatagrid
      {...listController}
      bulkActionButtons={false}
      rowStyle={highlightRowStyle}
      ids={ids}
    >
      <FunctionField
        label="&#8470;"
        render={({ id }) => {
          return <DailyId id={id} ids={defaultIds} total={total} page={page} perPage={perPage} />;
        }}
      />
      <FunctionField
        label="ra.label.order_id"
        render={({ orderId }) => {
          return orderId;
        }}
      />
      {/* <FunctionField
        style={{
          letterSpacing: '-0.02em',
          color: '#555555',
        }}
        label="ra.label.uppercase.supplier"
        render={Supplier}
      /> */}
      <FunctionField
        style={{
          letterSpacing: '-0.02em',
          color: '#555555',
        }}
        label="ra.label.uppercase.address"
        render={Address}
      />
      <OrderedAtField label="ra.label.uppercase.ordered_at" />
      <PriceField label="ra.label.uppercase.price" source="totalSum" parse={(v) => v / 100} />
      <StatusLabel label="ra.label.uppercase.status" />
      {/* <FunctionField */}
      {/*  label="ra.label.uppercase.courier" */}
      {/*  render={({ courier, supplierStatus }) => { */}
      {/*    return supplierStatus !== DELIVERY_STATUS_NAMES.DELIVERED */}
      {/*      ? [courier?.firstName, courier?.lastName].filter(Boolean).join(' ') */}
      {/*      : null; */}
      {/*  }} */}
      {/* /> */}
      <ShowActions label="ra.label.uppercase.actions" />
      <ShowOrder label="ra.label.uppercase.show" />
      {hasEditPermission && <EditOrder label="ra.label.uppercase.edit" />}
    </CustomDatagrid>
  );
};

export default OrderListDatagrid;
