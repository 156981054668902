import React, { useState, useEffect } from 'react';
import {
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  useRecordContext,
  useTranslate,
  useNotify,
} from 'react-admin';
import { fetchWithAuthorization } from '../../utils/fetchWithAuthorization';
import { baseUrl } from '../../config/connection';

const EditDeleteConfirmationButton = ({ disabled = false, ...props }) => {
  const [policyData, setPolicyData] = useState([]);
  const record = useRecordContext();
  const translate = useTranslate();
  const notify = useNotify();
  const organizationId = localStorage.getItem('organizationId');

  useEffect(() => {
    const couriersPayoutPolicyRequest = () =>
      fetchWithAuthorization(
        `${baseUrl}/admin/policy?&filter%5B0%5D=organizationId%7C%7C%24eq%7C%7C${organizationId}`,
      )
        .then((res) => res.json())
        .then((res) => {
          setPolicyData(res);
        })
        .catch((e) => notify(`${translate('ra.label.error')}: ${e}`, { type: 'warning' }));

    couriersPayoutPolicyRequest();
  }, [notify, organizationId, translate]);

  const translateOptions = () =>
    (record?.name
      ? { name: record.name }
      : { firstName: record.firstName, lastName: record.lastName });

  return (
    <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton alwaysEnable />
      {policyData?.length > 1 && <DeleteWithConfirmButton
        sx={{ padding: '8px 10px' }}
        confirmContent=""
        confirmTitle={translate(record.firstName && record.lastName
          ? 'ra.message.delete_full_name'
          : 'ra.message.delete_name')}
        translateOptions={translateOptions()}
        mutationMode="undoable"
        disabled={disabled}
      />}
    </Toolbar>
  );
};

export default EditDeleteConfirmationButton;
