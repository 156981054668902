import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '25px',
    '& > *': {
      minWidth: '25px',
    },
  },
  formControl: {
    minWidth: '500px',
  },
  closeButton: {
    '& > h2': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
  },
}));

export default useStyles;
