import React from 'react';
import { Datagrid, List, TextField, FunctionField, useTranslate } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import formatDateTime from '../../../utils/formatDateTime';
import ListActions from '../ListActions';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import EditOrderButton from './EditOrderButton';

const ApiKeysList = () => {
  const translate = useTranslate();

  return (
    <List actions={<ListActions />} empty={false}>
      <Datagrid bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.created_by" source="createdBy" />
        <FunctionField
          label="ra.label.status"
          render={({ deletedAt }) => {
            return !deletedAt ? translate('ra.label.active') : translate('ra.label.inactive');
          }}
        />
        <FunctionField
          label="ra.label.created_at"
          render={({ createdAt }) => {
            const parsedDateAndTime = formatDateTime(createdAt);

            return `${parsedDateAndTime.date} ${parsedDateAndTime.time}`;
          }}
        />
        <TextField label="ra.label.label" source="label" />
        <FunctionField
          label="ra.action.remove_access"
          render={({ id }) => {
            return <ConfirmDialog id={id} />;
          }}
        />
        <EditOrderButton label="ra.label.uppercase.edit" />
      </Datagrid>
    </List>
  );
};

export default ApiKeysList;
