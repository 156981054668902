import React from 'react';
import { Datagrid, List, TextField, DateField } from 'react-admin';

import ListFilter from '../ListFilter';

const OrganizationList = () => {
  return (
    <List filters={<ListFilter />}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.name" source="name" />
        <TextField label="ra.label.slug" source="slug" />
        <DateField label="ra.label.created_at" source="createdAt" showTime />
        <DateField label="ra.label.deactivated_at" source="deletedAt" showTime />
      </Datagrid>
    </List>
  );
};

export default OrganizationList;
