import React from 'react';
import { ChipField, Datagrid, List, TextField, FunctionField } from 'react-admin';

import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import { formatPhoneNumber } from '../../../utils/parsePhoneNumber';

const CourierList = () => {
  return (
    <List>
      <Datagrid
        rowClick="show"
        bulkActionButtons={<ListDeleteConfirmationButton />}
      >
        <TextField label="ra.label.first_name" source="firstName" />
        <TextField label="ra.label.last_name" source="lastName" />
        <FunctionField
          source="phone"
          label="ra.label.phone"
          render={({ phone }) => formatPhoneNumber(phone)}
        />
        <ChipField label="ra.label.status" color="secondary" source="status" />
      </Datagrid>
    </List>
  );
};

export default CourierList;
