import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  dropdown: {
    padding: '4px 24px',
  },
  row: {
    height: 48,
    cursor: 'pointer',
  },
}));

export default useStyles;
