import React from 'react';
import {
  required,
  TextInput,
  SimpleForm,
  Edit,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  ReferenceInput,
  number,
  minValue,
} from 'react-admin';
import { BILL_PHOTO_CONFIG, TRANSPORT_TYPES } from '../../constants';
import { useStyles } from '../../styles';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();
const validateMinNumber = [required(), number(), minValue(0.1)];
const validateInterval = [required(), number(), minValue(0)];

const CourierPayoutPolicyEdit = () => {
  const styles = useStyles();
  const organizationId = localStorage.getItem('organizationId');
  const payoutPolicyDefaultValues = () => ({ organizationId });

  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm
        defaultValues={payoutPolicyDefaultValues()}
        toolbar={<EditDeleteConfirmationButton />}
      >
        <TextInput
          label="ra.label.name"
          source="name"
          sx={{ marginLeft: '15px' }}
          validate={validateRequired}
        />
        <NumberInput
          label="ra.label.courier_logout_interval"
          source="courierLogoutInterval"
          className={styles.spaceBetween}
          sx={{ marginLeft: '15px !important' }}
          validate={validateInterval}
        />
        <SelectInput
          label="ra.label.bill_photo_config"
          source="billPhotoConfiguration"
          choices={BILL_PHOTO_CONFIG}
          sx={{ marginLeft: '15px !important', width: '218px' }}
          validate={validateRequired}
        />
        <ArrayInput
          source="courierPayoutSettings"
          className={styles.line}
          label="ra.label.payout_setting"
        >
          <SimpleFormIterator disableAdd disableRemove disableReordering>
            <SelectInput
              label="ra.label.transport_type"
              source="type"
              choices={TRANSPORT_TYPES}
              className={styles.spaceBetween}
              validate={validateRequired}
            />
            <NumberInput
              label="ra.label.coefficient_value"
              source="coefficient"
              className={styles.spaceBetween}
              validate={validateMinNumber}
            />
            <ReferenceInput
              label="ra.label.delivery_settings"
              source="deliverySettingId"
              reference="deliverySettings"
              filter={{ organizationId }}
              perPage={150}
              className={styles.spaceBetween}
              validate={validateRequired}
            >
              <SelectInput optionText="name" optionValue="id" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default CourierPayoutPolicyEdit;
