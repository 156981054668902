import React from 'react';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  ExportButton,
  FunctionField,
  List,
  TextField,
  TopToolbar,
  useListContext,
  useTranslate,
} from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const ListActions = () => {
  const { data } = useListContext();

  return (
    <TopToolbar>
      {data?.length === 0 && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};

const CourierAutomationSettingsList = () => {
  const translate = useTranslate();

  return (
    <List actions={<ListActions />}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <FunctionField
          label={`${translate('ra.label.maxDistanceNearPickUpLocation')} km`}
          render={({ maxDistanceNearPickUpLocation }) => maxDistanceNearPickUpLocation / 1000}
        />
        <FunctionField
          label={`${translate('ra.label.maxDistanceNearDestination')} km`}
          render={({ maxDistanceNearDestination }) => maxDistanceNearDestination / 1000}
        />
        <FunctionField
          label={`${translate('ra.label.retryTime')} sec.`}
          render={({ retryTime }) => retryTime / 1000}
        />
        <FunctionField
          source="timeAwaitingCourierConfirmation"
          label={`${translate('ra.label.timeAwaitingCourierConfirmation')} sec.`}
          render={({ timeAwaitingCourierConfirmation }) => timeAwaitingCourierConfirmation / 1000}
        />
        <FunctionField
          label={`${translate('ra.label.timeIgnoreCourier')} sec.`}
          render={({ timeIgnoreCourier }) => timeIgnoreCourier / 1000}
        />
        <TextField
          source="multiplierModifier"
          label={`${translate('ra.label.multiplierModifier')} <1`}
        />
        <FunctionField
          label={`${translate('ra.label.intervalGetCourierLocation')} sec.`}
          render={({ intervalGetCourierLocation }) => intervalGetCourierLocation / 1000}
        />
        <TextField label="ra.label.sortOptions" source="sortOptions" />
        <BooleanField label="ra.action.enable" source="enable" />
      </Datagrid>
    </List>
  );
};

export default CourierAutomationSettingsList;
