import React from 'react';
import { Dialog } from '@mui/material';
import { connect } from 'react-redux';
import CommentOrder from '../StatusModal/CommentOrder';
import CourierAssign from '../StatusModal/CourierAssign';
// import { DEFAULT_MAP_MD_ZOOM } from '../../../config/maps.config';
import {
  setCourierAssignOrder,
  setOrderCommentId,
  setOrderGeo,
  setRefusedOrder,
} from '../../../redux/customReducers/utils';
import RefuseOrderModal from '../RefuseOrderModal';

const OrderModals = ({
  commentId,
  setCommentId,
  orderGeo,
  setGeo,
  assignOrder,
  setAssignOrder,
  setRefused,
  refuseOrder,
}) => {
  const handleCommentClose = () => setCommentId(null);
  const handleAssignClose = () => setAssignOrder(null);
  const handleRefusedOrderClose = () => setRefused(null);

  return (
    <>
      <Dialog onClose={handleCommentClose} maxWidth="sm" open={!!commentId}>
        <CommentOrder handleClose={handleCommentClose} currentOrder={{ id: commentId }} />
      </Dialog>
      {/* <Dialog onClose={() => setGeo(null)} maxWidth="sm" open={!!orderGeo}>
        {orderGeo && (
          <Map width={600} height={600} {...orderGeo} zoom={DEFAULT_MAP_MD_ZOOM}>
            <Marker {...orderGeo} />
          </Map>
        )}
      </Dialog> */}
      {assignOrder && (
        <Dialog onClose={handleAssignClose} maxWidth="lg" open={!!assignOrder}>
          <CourierAssign currentOrder={assignOrder} handleClose={handleAssignClose} />
        </Dialog>
      )}
      <Dialog onClose={handleRefusedOrderClose} open={!!refuseOrder}>
        <RefuseOrderModal handleClose={handleRefusedOrderClose} order={refuseOrder} />
      </Dialog>
    </>
  );
};

const mapState = ({ utils }) => ({
  commentId: utils.orderComment.orderId,
  orderGeo: utils.orderGeo,
  assignOrder: utils.courierAssignOrder,
  refuseOrder: utils.refuseOrder,
});

const mapDispatch = {
  setCommentId: setOrderCommentId,
  setGeo: setOrderGeo,
  setAssignOrder: setCourierAssignOrder,
  setRefused: setRefusedOrder,
};

export default connect(mapState, mapDispatch)(OrderModals);
