import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 30,
    right: 30,
    height: '90%',
  },
  container: {
    backgroundColor: 'rgba(255,255,255, 0.7)',
    maxWidth: 320,
    maxHeight: '100%',
    overflowY: 'auto',
  },
  courier: {
    '&:hover': {
      background: 'rgb(255,142,0, 0.5)',
    },
  },
  vehicleIcon: {
    minWidth: 35,
    paddingRight: 5,
  },
  name: {
    flex: 3,
  },
  distance: {
    flex: 1,
  },
  selected: {
    backgroundColor: 'orange',
  },
  hovered: {
    background: 'rgb(255,142,0, 0.5)',
  },
  assignIcon: {
    border: '1px solid black',
    marginLeft: 5,
    zIndex: 99999,
  },
  busyCourier: {
    border: '2px red solid',
    borderRadius: '50%',
  },
  currentOrdersCount: {
    width: 22,
    height: 24,
    transform: 'translateY(-10%)',
  },
}));

export default useStyles;
