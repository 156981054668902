import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    float: 'right',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '320px',
    height: '220px',
    backgroundColor: 'rgba(40, 40, 40, 0.4)',
    border: '2px solid #000',
    padding: '10px',
    color: 'white',
  },
  discount: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  discountLabel: {
    fontSize: '28px',
    marginBottom: '6px',
  },
  discountSelect: {
    width: '144px',
    marginLeft: '14px',
    height: '26px',
  },
  discountSaveButton: {
    marginLeft: '40%',
    marginTop: '20%',
    cursor: 'pointer',
  },
});

export default useStyles;
