export const USER_ROLES = {
  ADMIN: 'ADMIN', // 0
  SUPPLIER_ADMIN: 'SUPPLIER_ADMIN', // 1
  USER: 'USER', // 2
  COURIER: 'COURIER', // 3
  SUPPLIER_BRANCH: 'SUPPLIER_BRANCH', // 4
};

export const AdminPermittedRoles = [
  USER_ROLES.ADMIN,
  USER_ROLES.COURIER,
];

export const adminRoleChoices = [
  {
    name: USER_ROLES.ADMIN,
    value: USER_ROLES.ADMIN,
  },
];

export const roleChoices = [
  {
    name: USER_ROLES.ADMIN,
    id: USER_ROLES.ADMIN,
  },
  {
    name: USER_ROLES.USER,
    id: USER_ROLES.USER,
  },
];
