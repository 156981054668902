import React from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import CancelButton from '../../../components/CancelButton';
import LatLngInput from '../../../components/LatLngInput';
import ResourceTitle from '../../../components/ResourceTitle';

const validateRequired = required();

const RestaurantCreate = (props) => {
  const organizationId = localStorage.getItem('organizationId');
  const restaurantDefaultValues = () => ({ organizationId });

  return (
    <Create
      {...props}
      title={<ResourceTitle field="name" />}
      actions={<CancelButton />}
      redirect="list"
    >
      <SimpleForm redirect="list" defaultValues={restaurantDefaultValues()}>
        <TextInput label="ra.label.name" source="name" validate={validateRequired} />
        {[
          'locality',
          'area',
          'house',
          'street',
          'country',
        ].map((part) => (
          <TextInput
            key={part}
            label={`ra.label.${part}`}
            source={`address.${part}`}
            validate={validateRequired}
          />
        ))}
        <TextInput label="ra.label.phone" source="phoneNumber" validate={validateRequired} />
        <LatLngInput />
      </SimpleForm>
    </Create>
  );
};

export default RestaurantCreate;
