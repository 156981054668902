import { useState } from 'react';

const useAnchor = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    open,
    anchorEl,
    handleOpen,
    handleClose,
  };
};

export default useAnchor;
