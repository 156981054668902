import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useRefresh, useShowController, useTranslate } from 'react-admin';
import {
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
} from '@mui/material';
import {
  deleteWithAuthorization,
  putWithAuthorization,
} from '../../../../utils/fetchWithAuthorization';
import { baseUrl } from '../../../../config/connection';
import { USER_PERMISSIONS } from '../../../constants';

const EditPermissionsDialog = ({ handleClose, open, user }) => {
  const [allowedPermissions, setAllowedPermissions] = useState(user.permissions);
  const [deletedPermissions, setDeletedPermissions] = useState([]);

  const showController = useShowController();
  const refresh = useRefresh();
  const translate = useTranslate();

  const handleChange = (event) => {
    if (event.target.checked) {
      setAllowedPermissions((prev) => [...prev, event.target.name]);
      setDeletedPermissions((prev) => {
        return prev.filter((permission) => permission !== event.target.name);
      });
    } else {
      setDeletedPermissions((prev) => [...prev, event.target.name]);
      setAllowedPermissions((prev) => {
        return prev.filter((permission) => permission !== event.target.name);
      });
    }
  };

  const handleSubmit = async () => {
    await putWithAuthorization(`${baseUrl}/user/${user.id}/permission`, {
      body: JSON.stringify({
        resourceId: showController.record.id,
        resourceTypeName: 'OrganizationEntity',
        permissions: allowedPermissions,
      }),
    });

    if (deletedPermissions.length > 0) {
      await deleteWithAuthorization(`${baseUrl}/user/${user.id}/permission`, {
        body: JSON.stringify({
          resourceId: showController.record.id,
          resourceTypeName: 'OrganizationEntity',
          permissions: deletedPermissions,
        }),
      });
    }
    refresh();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate('ra.action.edit_permissions')}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">{translate('ra.label.permissions')}</FormLabel>
            <FormGroup>
              {USER_PERMISSIONS.map((permission) => {
                return (
                  <FormControlLabel
                    key={permission}
                    control={
                      <Switch
                        checked={allowedPermissions.includes(permission)}
                        onChange={handleChange}
                        name={permission}
                      />
                    }
                    label={translate(`ra.label.${permission}`)}
                  />
                );
              })}
              <Button
                variant="contained"
                sx={{ maxWidth: 200, marginTop: '30px' }}
                onClick={handleSubmit}
              >
                {translate('ra.action.save')}
              </Button>
            </FormGroup>
          </FormControl>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditPermissionsDialog;
