import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import { IconButton } from '@mui/material';
import { useRefresh, useTranslate } from 'react-admin';
import { deleteWithAuthorization } from '../../../../utils/fetchWithAuthorization';
import url from '../../../../config/connection';

const ConfirmDialog = ({ id }) => {
  const [open, setOpen] = useState(false);

  const translate = useTranslate();
  const refresh = useRefresh();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const disableAccess = async () => {
    await deleteWithAuthorization(`${url}/api-access/${id}`);
    refresh();
  };

  return (
    <div>
      <IconButton color="error" onClick={handleClickOpen}>
        <GroupRemoveIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate('ra.action.access_key_remove_confirm')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>{translate('ra.action.disagree')}</Button>
          <Button
            onClick={(e) => {
              disableAccess(id);
              handleClose();
            }}
            autoFocus
          >
            {translate('ra.action.agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
