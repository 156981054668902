/* eslint-disable prefer-promise-reject-errors */
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { mainDomainUrl, urlAuth } from '../config/connection';
import { USER_ROLES } from '../config/user.config';
import { getPermissions } from '../utils/getPermissions';
import { firebaseConfig } from '../config/firebase';

const auth = getAuth();

const updateToken = async () => {
  const newTokenRequest = await fetch(
    `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`,
    {
      method: 'post',
      body: JSON.stringify({
        grant_type: 'refresh_token',
        refresh_token: localStorage.getItem('refreshToken'),
      }),
    },
  );
  const result = await newTokenRequest.json();

  const currentTime = new Date().getTime();
  const expirationTime = currentTime + 3600000;

  localStorage.setItem('token', result.id_token);
  localStorage.setItem('refreshToken', result.refresh_token);
  localStorage.setItem('expirationTime', expirationTime);
  const firebaseLoaded = () =>
    new Promise((resolve) => {
      auth.onAuthStateChanged(resolve);
    });
  await firebaseLoaded();
};

const authProvider = {
  login: async (params) => {
    const { email, password } = params;

    if (email && password) {
      const currentTime = new Date().getTime();
      const expirationTime = currentTime + 3600000;

      try {
        await setPersistence(auth, browserLocalPersistence);

        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        const errorMessage = error.message;
        // eslint-disable-next-line no-console
        console.error(errorMessage);
      }

      const idToken = await getAuth().currentUser?.getIdToken();

      const refreshToken = await getAuth().currentUser?.refreshToken;

      const response = await fetch(`${urlAuth}/loginByEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.status < 200 || [401, 403, 500].indexOf(response.status) > -1) {
        throw new Error(response.statusText);
      }

      const user = await response.json();

      const permissions = await getPermissions(user, idToken);

      localStorage.setItem('permissions', JSON.stringify(permissions));

      const allowedPermissions = ['view', 'edit', 'delete', 'create'];
      const adminPermissions = permissions && permissions['*'] && permissions['*']['*'];

      const isAdmin =
        adminPermissions && allowedPermissions.every((item) => adminPermissions.includes(item));

      const role = isAdmin ? USER_ROLES.ADMIN : USER_ROLES.USER;

      localStorage.setItem('role', role);
      localStorage.setItem('token', idToken);

      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('expirationTime', expirationTime);

      return user;
    }

    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('permissions');
    localStorage.removeItem('organizationGeo');

    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expirationTime');

    getAuth().signOut();

    window.location.href = `${mainDomainUrl}/login`;

    return Promise.resolve();
  },
  checkError: async () => {
    const now = new Date().getTime();
    const localStorageExpirationTime = Number(localStorage.getItem('expirationTime'));
    const remainingTime = localStorageExpirationTime - now;
    const fiveMinutesInMs = 60 * 5000;

    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken || refreshToken === 'undefined') {
      return Promise.reject();
    }

    if (remainingTime < fiveMinutesInMs) {
      await updateToken();

      window.location.reload();
    }

    return Promise.resolve();
  },
  checkAuth: async () => {
    const now = new Date().getTime();
    const localStorageExpirationTime = Number(localStorage.getItem('expirationTime'));
    const remainingTime = localStorageExpirationTime - now;
    const fiveMinutesInMs = 60 * 5000;

    if (remainingTime < fiveMinutesInMs) {
      await updateToken();
    }

    return Promise.resolve();
  },
  getPermissions: async () => {
    const role = localStorage.getItem('role');
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    const isAdmin = role === USER_ROLES.ADMIN;
    const adminPermissions = ['view', 'create', 'delete', 'edit', ''];
    const organizationPermissions = JSON.parse(localStorage.getItem('organizationPermissions'));

    const rolePermissions = !isAdmin ? organizationPermissions : adminPermissions;

    const currentLocation = window.location.href;
    const isResetPasswordPage = currentLocation.includes('reset-password');

    if (!role && !permissions && !isResetPasswordPage) {
      throw new Error('Unauthorized');
    }

    return { role, permissions, rolePermissions };
  },
};

export default authProvider;
