import React, { useState, useEffect } from 'react';
import { Create, required, regex, SimpleForm, TextInput } from 'react-admin';

import CancelButton from '../../../components/CancelButton';
import LatLngInput from '../../../components/LatLngInput';
import ResourceTitle from '../../../components/ResourceTitle';

import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import { baseUrl } from '../../../config/connection';

const OrganizationCreate = (props) => {
  const [slugList, setSlugList] = useState([]);

  useEffect(() => {
    fetchWithAuthorization(`${baseUrl}/admin/organization`)
      .then((res) => res.json())
      .then((res) => {
        const slugs = res.data.map((organization) => organization.slug);
        setSlugList(slugs);
      });
  }, []);

  const validateSlugUnique = (value) => {
    const isSlugExist = slugList.includes(value);

    if (isSlugExist) {
      return 'This domain name already exists, please choose another one';
    }

    return undefined;
  };

  const validateRequired = required();
  const validateSlug = [
    validateRequired,
    regex(/^[A-Za-z0-9]*$/, 'Should contain only letters and numbers'),
    validateSlugUnique,
  ];

  return (
    <Create
      {...props}
      title={<ResourceTitle field="firstName" />}
      actions={<CancelButton />}
      redirect="list"
    >
      <SimpleForm redirect="list">
        <TextInput label="ra.label.name" source="name" validate={validateRequired} />
        <TextInput label="ra.label.slug" source="slug" validate={validateSlug} />
        <LatLngInput />
      </SimpleForm>
    </Create>
  );
};

export default OrganizationCreate;
