import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import DeliverySettingsInput from '../DeliverySettingsFieldInput';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const organizationId = localStorage.getItem('organizationId');

const deliverySettingsDefaultValues = () => ({ organizationId });

const DeliverySettingsEdit = () => (
  <Edit actions={<CancelButton />}>
    <SimpleForm
      defaultValues={deliverySettingsDefaultValues}
      toolbar={<EditDeleteConfirmationButton />}
    >
      <DeliverySettingsInput />
    </SimpleForm>
  </Edit>
);

export default DeliverySettingsEdit;
