import { SESSION_STATUSES } from '../constants';

function createData(deliveryRequestId, currentCourierUserId, sessionStatus, details, logs) {
  return {
    deliveryRequestId,
    currentCourierUserId,
    sessionStatus: SESSION_STATUSES[sessionStatus],
    details: {
      startTime: details.startTime,
      retryTimes: details.retryTimes,
      courierUnconfirmed: details.courierUnconfirmed,
      courierAssignedTime: details.courierAssignedTime,
    },
    logs,
  };
}

export default createData;
