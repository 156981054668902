import React from 'react';
import { Chip } from '@mui/material';
import {
  useTranslate,
  useRecordContext,
  useRefresh,
  useUpdate,
  useNotify,
} from 'react-admin';
import useStyles from './style';

const ActivationButton = () => {
  const record = useRecordContext();
  const [update] = useUpdate();
  const isActive = record?.isActive;
  const refresh = useRefresh();
  const translate = useTranslate();
  const notify = useNotify();

  const classes = useStyles();

  const onSuccess = () => {
    notify(translate('ra.notification.updated', { smart_count: 1 }), {
      undoable: true,
    });
    refresh();
  };

  const handleClick = async (e) => {
    e.stopPropagation();
    await update(
      'policy',
      { id: record.id, data: { isActive: true }, previousData: record },
      { onSuccess },
    );
  };

  return (
    <Chip
      label={translate(isActive ? 'ra.label.active' : 'ra.label.inactive')}
      className={isActive ? classes.active : classes.inactive}
      sx={{
        textTransform: 'uppercase',
        width: '170px',
        fontWeight: '700',
        fontSize: '13px',
        color: '#FFFFFF',
        lineHeight: '1.2',
        letterSpacing: '0.5px',
        paddingTop: '1px',
      }}
      onClick={!isActive ? handleClick : null}
    />
  );
};

export default ActivationButton;
