// prevent roboto font to be downloaded by google maps
// https://github.com/google-map-react/google-map-react/issues/836
const head = document.getElementsByTagName('head')[0];

// Save the original method
const insertBefore = head.insertBefore;

// Replace it!
head.insertBefore = (newElement, referenceElement) => {
  if (
    newElement.href &&
    newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0
  ) {
    return;
  }

  insertBefore.call(head, newElement, referenceElement);
};
