import React from 'react';
import Summary from '../OrdersSummary';
import { USER_ROLES } from '../../../config/user.config';
import ReportLink from '../ReportLink';

const OrderListHeader = ({ orderSummary }) => {
  const localRole = localStorage.getItem('role');
  const isBranchAdmin = localRole === USER_ROLES.SUPPLIER_BRANCH;
  const isSuperAdmin = localRole === USER_ROLES.ADMIN;

  return (
    !isBranchAdmin && (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        {isSuperAdmin && <Summary orderSummary={orderSummary} />}
        <ReportLink />
      </div>
    )
  );
};

export default OrderListHeader;
