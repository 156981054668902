import React, { memo } from 'react';
import Button from '@mui/material/Button';
import { useLocaleState, useRefresh } from 'react-admin';
import { getConfig } from '../utils/getInitialConfig';

const LocaleSwitcher = () => {
  const locales = getConfig('locales');
  const refresh = useRefresh();
  const [, setLocale] = useLocaleState();

  const handleLocaleChange = (lang) => {
    localStorage.setItem('currentLocale', lang);
    refresh();
    setLocale(lang);
  };

  return (
    <div>
      {locales?.map(({ locale: language, short }) => (
        <Button key={language} onClick={() => handleLocaleChange(language)}>
          <span style={{ color: '#fff' }}>{short}</span>
        </Button>
      ))}
    </div>
  );
};

export default memo(LocaleSwitcher);
