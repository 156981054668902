/* eslint-disable max-len */
import React from 'react';

export default () => (
  <svg width={24} height={24} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.267 9.66634C16.5836 5.75801 13.417 3.33301 10.0003 3.33301C6.58363 3.33301 3.41697 5.75801 1.73363 9.66634C1.68775 9.77148 1.66406 9.88496 1.66406 9.99967C1.66406 10.1144 1.68775 10.2279 1.73363 10.333C3.41697 14.2413 6.58363 16.6663 10.0003 16.6663C13.417 16.6663 16.5836 14.2413 18.267 10.333C18.3129 10.2279 18.3365 10.1144 18.3365 9.99967C18.3365 9.88496 18.3129 9.77148 18.267 9.66634V9.66634ZM10.0003 14.9997C7.35863 14.9997 4.85863 13.0913 3.41697 9.99967C4.85863 6.90801 7.35863 4.99967 10.0003 4.99967C12.642 4.99967 15.142 6.90801 16.5836 9.99967C15.142 13.0913 12.642 14.9997 10.0003 14.9997ZM10.0003 6.66634C9.34103 6.66634 8.69656 6.86184 8.1484 7.22811C7.60024 7.59438 7.17299 8.11498 6.9207 8.72406C6.66841 9.33315 6.6024 10.0034 6.73102 10.65C6.85963 11.2966 7.1771 11.8905 7.64328 12.3567C8.10945 12.8229 8.7034 13.1403 9.35 13.269C9.9966 13.3976 10.6668 13.3316 11.2759 13.0793C11.885 12.827 12.4056 12.3997 12.7719 11.8516C13.1381 11.3034 13.3336 10.6589 13.3336 9.99967C13.3336 9.11562 12.9824 8.26777 12.3573 7.64265C11.7322 7.01753 10.8844 6.66634 10.0003 6.66634V6.66634ZM10.0003 11.6663C9.67066 11.6663 9.34843 11.5686 9.07435 11.3855C8.80027 11.2023 8.58665 10.942 8.4605 10.6375C8.33435 10.3329 8.30135 9.99783 8.36566 9.67452C8.42997 9.35122 8.5887 9.05425 8.82179 8.82116C9.05488 8.58808 9.35185 8.42934 9.67515 8.36503C9.99845 8.30072 10.3336 8.33373 10.6381 8.45987C10.9426 8.58602 11.2029 8.79964 11.3861 9.07372C11.5692 9.34781 11.667 9.67004 11.667 9.99967C11.667 10.4417 11.4914 10.8656 11.1788 11.1782C10.8662 11.4907 10.4423 11.6663 10.0003 11.6663Z"
      fill="#555555"
    />
  </svg>
);
