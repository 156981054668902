import RESOURCES_NAMES from '../../../config/resources.config';

export const MAIN_PART = [RESOURCES_NAMES.DELIVERY, RESOURCES_NAMES.DELIVERY_HISTORY];

export const SUPPLIER_MANAGEMENT = [RESOURCES_NAMES.SUPPLIER, RESOURCES_NAMES.ADDRESS];

export const USER_MANAGEMENT = [
  RESOURCES_NAMES.USER,
  RESOURCES_NAMES.USER_ADDRESS,
  RESOURCES_NAMES.ORGANIZATION,
];

export const DELIVERY_MANAGEMENT = [
  RESOURCES_NAMES.DELIVERY_REQUEST,
  RESOURCES_NAMES.DELIVERY_SETTINGS,
  RESOURCES_NAMES.COURIER,
  RESOURCES_NAMES.POLICY,
  RESOURCES_NAMES.COURIER_AUTOMATION_SETTINGS,
  RESOURCES_NAMES.RESTAURANTS,
];

export const INTEGRATIONS = [RESOURCES_NAMES.WEB_HOOKS, RESOURCES_NAMES.API_KEYS];
