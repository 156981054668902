import React, { useEffect, useState } from 'react';
import { Paper, Tabs, Tab as MuiTab } from '@mui/material';
import { List, useListController, useNotify, useRefresh, useResourceContext } from 'react-admin';
import { fromEvent, asyncScheduler } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { connect } from 'react-redux';
import { io } from 'socket.io-client';
import OrderListDatagrid from '../OrderListDatagrid';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import url, { baseUrl } from '../../../config/connection';
import PostPagination from '../PostPagination';
import { initSocket } from '../../../redux/socket';
import OrderListHeader from '../OrderListHeader';
import OrderModals from '../OrderModals';
import DetailsMap from '../DetailsMap';

const listResourceMap = {
  'delivery/today': 'delivery/today',
  'delivery/history': 'delivery/history',
};

const throttleConfig = {
  leading: true,
  trailing: true,
};

// with params
const socketIO = io(baseUrl, { transports: ['websocket'], rejectUnauthorized: false });

const OrderList = ({ createSocket, socket }) => {
  const [intervalId, setIntervalId] = useState(null);
  const listContext = useListController();
  const resource = useResourceContext();

  const [orderSummary, setOrderSummary] = useState(null);
  const [tab, setTab] = useState(0);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleTabChange = (_, newValue) => {
    setTab(newValue);
  };

  const fetchSummary = () => {
    fetchWithAuthorization(`${url}/delivery/today`)
      .then((results) => results.json())
      .then((data) => setOrderSummary(data));
  };

  useEffect(() => {
    if (!socket) {
      createSocket(socketIO);
    } else {
      socketIO.connect();

      const updateDeliveryEvent = fromEvent(socketIO, 'updateDeliveryList').pipe(
        throttleTime(10 * 1000, asyncScheduler, throttleConfig),
      );

      const updateOrderEvent = fromEvent(socketIO, 'updatedOrderList').pipe(
        throttleTime(10 * 1000, asyncScheduler, throttleConfig),
      );

      const updateDeliverySubscription = updateDeliveryEvent.subscribe(() => {
        refresh();
        fetchSummary();
        notify('Delivery list was updated', { type: 'success' });
      });

      const updateOrderSubscription = updateOrderEvent.subscribe(() => {
        refresh();
        fetchSummary();
        notify('Delivery list was updated', { type: 'success' });
      });

      return () => {
        updateDeliverySubscription.unsubscribe();
        updateOrderSubscription.unsubscribe();
        socketIO.disconnect();
      };
    }
  }, [socket, createSocket, refresh, notify]);

  return (
    <>
      <Paper square>
        <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
          <MuiTab label="List" />
          <MuiTab label="Map" />
        </Tabs>
      </Paper>
      {tab === 0 && (
        <>
          <List
            pagination={<PostPagination />}
            resource={listResourceMap[resource]}
            actions={<OrderListHeader orderSummary={orderSummary} />}
            queryOptions={{ refetchInterval: 30 * 1000 }}
          >
            <OrderListDatagrid listProps={listContext} />
          </List>
          <OrderModals />
        </>
      )}
      {tab === 1 && <DetailsMap />}
    </>
  );
};

const mapStateToProps = (state) => ({
  socket: state.socket.client,
});

const mapDispatchToProps = (dispatch) => ({
  createSocket: (socket) => dispatch(initSocket(socket)),
});

const connectedOrderList = connect(mapStateToProps, mapDispatchToProps)(OrderList);
export default connectedOrderList;
