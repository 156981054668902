import React from 'react';
import Dialog from '@mui/material/Dialog';

const Modal = ({
  onClose, open, children, ...props
}) => {
  return (
    <Dialog {...props} onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      {children}
    </Dialog>
  );
};

export default Modal;
