import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '80vh',
    marginTop: 10,
    position: 'relative',
  },
  map: {
    width: '100%',
    height: '100%',
  },
  filter: {
    position: 'absolute',
    top: 30,
    left: 30,
  },
  filterItem: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 200,
  },
  busyCourier: {
    padding: 2,
    border: '2px red solid',
    borderRadius: '50%',
  },
}));

export default useStyles;
