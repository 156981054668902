export const DELIVERY_STATUS_NAMES = {
  NEW: 'NEW',
  ACCEPTED: 'ACCEPTED',
  CANCELED: 'CANCELED',
  DELIVERING: 'DELIVERING',
  DELIVERED: 'DELIVERED',
  REFUSED: 'REFUSED',
  COURIER_ASSIGNED: 'COURIER_ASSIGNED',
  COURIER_CONFIRMED: 'COURIER_CONFIRMED',
  COURIER_IS_IN_RESTAURANT: 'COURIER_IS_IN_RESTAURANT',
  COURIER_REJECTED: 'COURIER_REJECTED',
};

export const ORDER_STATUS_IDS = {
  NEW: 7,
  CANCELED: 2,
  CONFIRMED: 3,
  DELIVERING: 5,
  DELIVERED: 6,
  COURIER_CONFIRMED: 9,
  COURIER_IS_IN_RESTAURANT: 10,
  ACCEPTED: 11,
};

export const ROLE_STATUSES = {
  STANDARD: {
    ADMIN: [DELIVERY_STATUS_NAMES.NEW, DELIVERY_STATUS_NAMES.ACCEPTED],
    SUPPLIER_ADMIN: [
      DELIVERY_STATUS_NAMES.NEW,
      DELIVERY_STATUS_NAMES.CONFIRMED,
    ],
    SUPPLIER_BRANCH: [
      DELIVERY_STATUS_NAMES.NEW,
      DELIVERY_STATUS_NAMES.CONFIRMED,
    ],
  },
  SUPPLIER: {
    ADMIN: [DELIVERY_STATUS_NAMES.NEW, DELIVERY_STATUS_NAMES.ACCEPTED],
    SUPPLIER_ADMIN: [
      DELIVERY_STATUS_NAMES.CONFIRMED,
      DELIVERY_STATUS_NAMES.DELIVERING,
    ],
    SUPPLIER_BRANCH: [
      DELIVERY_STATUS_NAMES.CONFIRMED,
      DELIVERY_STATUS_NAMES.DELIVERING,
    ],
  },
};

export const MODAL_STATUSES = [DELIVERY_STATUS_NAMES.CANCELED, DELIVERY_STATUS_NAMES.ACCEPTED];

export const ORDER_STATUSES = [
  { id: 'NEW', name: 'New' },
  { id: 'COURIER_ASSIGNED', name: 'Courier Assigned' },
  { id: 'COURIER_CONFIRMED', name: 'Courier Confirmed' },
  { id: 'COURIER_IS_IN_RESTAURANT', name: 'Courier In Restaurant' },
  { id: 'CANCELED', name: 'Canceled' },
  { id: 'CONFIRMED', name: 'Confirmed' },
  { id: 'DELIVERING', name: 'Delivering' },
  { id: 'DELIVERED', name: 'Delivered' },
  { id: 'ACCEPTED', name: 'Accepted' },
];

export const ORDER_STATUSES_TRANSLATIONS = {
  [DELIVERY_STATUS_NAMES.NEW]: 'new',
  [DELIVERY_STATUS_NAMES.AWAITING_PAYMENT]: 'awaiting_payment',
  [DELIVERY_STATUS_NAMES.ACCEPTED]: 'accepted',
  [DELIVERY_STATUS_NAMES.CANCELED]: 'canceled',
  [DELIVERY_STATUS_NAMES.CONFIRMED]: 'confirmed',
  [DELIVERY_STATUS_NAMES.DELIVERING]: 'delivering',
  [DELIVERY_STATUS_NAMES.DELIVERED]: 'delivered',
  [DELIVERY_STATUS_NAMES.COURIER_ASSIGNED]: 'courier_assigned',
  [DELIVERY_STATUS_NAMES.COURIER_CONFIRMED]: 'courier_confirmed',
  [DELIVERY_STATUS_NAMES.COURIER_REJECTED]: 'courier_rejected',
  [DELIVERY_STATUS_NAMES.COURIER_IS_IN_RESTAURANT]: 'courier_in_restaurant',
  [DELIVERY_STATUS_NAMES.REFUSED]: 'refused',
};

export const ORDER_STATUSES_COLOR = {
  [DELIVERY_STATUS_NAMES.NEW]: '#0055FF',
  [DELIVERY_STATUS_NAMES.AWAITING_PAYMENT]: '#FF4E33',
  [DELIVERY_STATUS_NAMES.ACCEPTED]: '#7DB807',
  [DELIVERY_STATUS_NAMES.CANCELED]: '#FA0064',
  [DELIVERY_STATUS_NAMES.CONFIRMED]: '#7DB807',
  [DELIVERY_STATUS_NAMES.DELIVERING]: '#00A66F',
  [DELIVERY_STATUS_NAMES.DELIVERED]: '#A3A3A3',
  [DELIVERY_STATUS_NAMES.COURIER_ASSIGNED]: '#FFAA00',
  [DELIVERY_STATUS_NAMES.COURIER_CONFIRMED]: '#FFAA00',
  [DELIVERY_STATUS_NAMES.COURIER_IS_IN_RESTAURANT]: '#FFAA00',
  [DELIVERY_STATUS_NAMES.COURIER_REJECTED]: '#FFAABB',
  [DELIVERY_STATUS_NAMES.REFUSED]: '#ef6414',
};
