import { DELIVERY_STATUS_NAMES } from '../../../config/statuses.config';

export const courierAssignStatuses = [
  DELIVERY_STATUS_NAMES.PREPARING,
  DELIVERY_STATUS_NAMES.PREPARED,
  DELIVERY_STATUS_NAMES.CONFIRMED,
  DELIVERY_STATUS_NAMES.DELIVERING,
  DELIVERY_STATUS_NAMES.SCHEDULED,
];

export const courierConfirmedStatuses = [
  DELIVERY_STATUS_NAMES.COURIER_CONFIRMED,
  DELIVERY_STATUS_NAMES.COURIER_IS_IN_RESTAURANT,
];

export const STATUS_BTN_NAME_MAP = {
  [DELIVERY_STATUS_NAMES.PROCESSING]: 'processing',
  [DELIVERY_STATUS_NAMES.CONFIRMED]: 'confirmed',
  [DELIVERY_STATUS_NAMES.PREPARING]: 'preparing',
  [DELIVERY_STATUS_NAMES.PREPARED]: 'prepared',
  [DELIVERY_STATUS_NAMES.DELIVERING]: 'delivering',
  [DELIVERY_STATUS_NAMES.DELIVERED]: 'delivered',
  [DELIVERY_STATUS_NAMES.ACCEPTED]: 'accepted',
  [DELIVERY_STATUS_NAMES.CANCELED]: 'canceled',
  [DELIVERY_STATUS_NAMES.SCHEDULED]: 'scheduled',
};

export const courierSourceDetails = {
  STANDARD: 'STANDARD',
};
