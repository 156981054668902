/* eslint-disable max-len */
const messages = {
  ra: {
    week_day: {
      monday: 'Понедельник',
      tuesday: 'Вторник',
      wednesday: 'Среда',
      thursday: 'Четверг',
      friday: 'Пятница',
      saturday: 'Суббота',
      sunday: 'Воскресенье',
    },
    label: {
      countryName: 'Страна',
      cityName: 'Город',
      streetNumber: 'Номер улицы',
      buildingNumber: 'Номер дома',
      cant_assign_courier: 'Нельзя назначить курьера',
      launch_date: 'Дата запуска',
      amount_lei: 'Cумма ( лей )',
      day: 'день',
      days: 'дней',
      hour: 'час',
      hours: 'часов',
      ago: 'назад',
      selected_count: 'Выбирать %{count} из %{all} элементы',
      photo: 'Kартинка',
      items: 'Позиции',
      id: 'ID',
      label: 'Ярлык',
      action: 'Действие',
      discount: 'Скидка',
      data: 'Действует до',
      filters: 'Фильтры:',
      perks: 'Привилегии:',
      name: 'Имя',
      notes: 'Примечание',
      supplier: 'Поставщик',
      supplierBranch: 'Филиал поставшика',
      country: 'Страна',
      area: 'Район',
      locality: 'Город',
      street: 'Улица',
      house: 'Дом',
      store: 'Store',
      status: 'Статус',
      slug: 'Slug',
      first_name: 'Имя',
      last_name: 'Фамилия',
      minute: 'минутa',
      minutes: 'минут',
      courier_filters: 'Курьерские фильтры',
      estimatedPreparation: 'Расчетное время подготовки',
      phone: 'Телефон',
      busy: 'Занят',
      description: 'Описание',
      cook_time: 'Время приготовления',
      available: 'Доступен',
      available_products: 'Доступные продукты',
      price: 'Цена',
      referrals: 'Pефералы',
      referrer: 'Pеферер',
      weight: 'Вес',
      serving_size: 'Размер порции',
      unit: 'Ед. изм',
      highlight: 'Краткое описание',
      payment_methods: 'Метод оплаты',
      payment_config: 'Конфигурация оплаты',
      schedule: 'Распиание',
      category: 'Категория',
      active: 'Активный',
      inactive: 'Не активный',
      is_multiple: 'Выбор нескольких',
      is_required: 'Обязательно для выбора',
      option: 'Опция',
      maxSelectedOptions: 'Макс. кол-во выбранных опций',
      is_free_option: 'Бесплатная опция',
      address: 'Адресс',
      order_instructions: 'Коментарий к заказу',
      client: 'Клиент',
      courier: 'Курьер',
      payout_policy: 'Политика курьерских выплат',
      payout_setting: 'Настройи выплат курьеров',
      options: 'Опции',
      ordered_at: 'Заказно в',
      order_status: 'Статус заказ',
      actions: 'Действия',
      terminal: 'Терминал',
      supplier_select: 'Выбрать поставщика',
      supplier_branch_select: 'Выбрать филиал поставшика',
      meta_title: 'Meta-title',
      meta_description: 'Meta-description',
      allow_under_age: 'Разрешено несовершеннолетним',
      self_management: 'Самоуправление',
      cover_image: 'Обложка поставщика',
      card_image: 'Карточка товара',
      courier_source: 'Courier source',
      rank: 'Ранг',
      price_range: 'Ценовой диапазое',
      type: 'Тип поставщика',
      delivery_settings: 'Настройки доставки',
      delivery_settings_id: 'ID настройки доставки',
      setting_type: 'Тип настройки',
      fixed: 'Фиксированный',
      progressive: 'Прогрессивный',
      calculation_type: 'Тип расчета',
      fixed_price: 'Фиксированная цена',
      base_price: 'Базовая цена',
      distances: 'Pасстояния',
      modifier: 'Модификатор (%{mdl})',
      delivery_price: 'Стоимость доставки',
      peak_hours: 'Часы пик',
      order_price_ranges: 'Модификаторы цены заказа',
      free_delivery: 'Бесплатная доставка',
      constant_price_modifier: 'Постоянный модификатор цены',
      price_per_km: 'бани/км',
      enabled: 'Включено',
      message: 'Message',
      masked_card: 'Masked CARD',
      rrn: 'RRN',
      sum: 'Сумма',
      created_at: 'Создано',
      order_id: 'ID заказа',
      contact_name: 'Контактное имя',
      user: 'Пользователь',
      user_name: 'Имя пользователя',
      password: 'Пароль',
      role: 'Роль',
      branch: 'Филиал',
      email: 'Email',
      comment: 'Комментарий',
      just_now: 'прямо сейчас',
      filter: 'Фильтр',
      updated: 'Обновлено',
      added: 'Добавлено',
      estimated: 'Предполагаемое',
      actual: 'Действительное',
      external: 'Внешний',
      current_courier: 'Текущий курьер',
      from: 'От',
      to: 'До',
      supplier_id: 'ID Поставщика',
      transport_type: 'Тип транспорта',
      coefficient_value: 'Значение коэффициента',
      uppercase: {
        supplier: 'ПОСТАВЩИК',
        notification: 'ЗАМЕТКА',
        show: 'ПОКАЗ',
        comm: 'КОММ',
        edit: 'ИЗМ',
        address: 'АДРЕС',
        ordered_at: 'ВРЕМЯ',
        price: 'ЦЕНА',
        status: 'СТАТУС',
        actions: 'ДЕЙТСВИЯ',
        courier: 'КУРИЕР',
      },
      unlimitedOptions: '0 или пусто = безлимитное кол-во.',
      unlimited: 'Безлимитно',
      returnToOffice: 'Вернуть в офис',
      doNothing: 'Без действий',
      nextSteps: 'Следующие шаги',
      refusedOrderComment: 'Комментарий к отклоненному заказу',
      refund: 'Вернуть деньги',
      marketing_campaigns: 'Маркетинговые кампании',
      marketing_campaigns_action_type: 'Тип действия кампании',
      marketing_campaign_source_type: 'Тип фильтра кампании',
      marketing_source_filter_type: 'Тип фильтра',
      marketing_action_type: 'Тип акции',
      launchDate: 'Дата запуска',
      effectiveDate: 'Дата вступления в силу',
      run_init: 'Инициировать',
      run_simulate: 'Симулировать',
      validityInHours: 'Действует... (часов)',
      discounts_sent: 'Скидок отправлено',
      discounts_used: 'Скидок использовано',
      discounts_cost: 'Цена скидок',
      ordersValue: 'Цена проданных товаров',
      supplier_store: 'Store',
    },
    tab: {
      summary: 'Общее',
      variants: 'Варианты',
      option_groups: 'Группы опций',
      options: 'Опции',
      courier: 'Курьер',
      suppliers: 'Поставщик',
      supplier_addresses: 'Филиалы поставщиков',
      goods: 'Товары',
      user_address: 'Адрес',
      policy: 'Policy',
      refusedOrders: 'Отклоненные заказы',
      campaign: 'Кампания',
      run: 'Запущенная кампания',
      participants: 'Участники (%{count})',
      statistics: 'Статистика',
    },
    table: {
      column: {
        option_group_name: 'Имя группы опций',
        activate_for_this_good: 'Активировать для этого товара',
        variant_name: 'Имя варианта',
        variant_weight: 'Вес варианта',
        variant_price: 'Цена варианта',
      },
    },
    action: {
      add_option: 'Добавить опцию',
      add_option_group: 'Добавить группу опции',
      add_variant: 'Добавить вариант',
      add_filter: 'Добавить фильтр',
      add_address: 'Добавить адресс',
      add_good: 'Добавить товар',
      add_supplier: 'Добавить поставщика',
      agree: 'Подтвердить',
      disagree: 'Отмена',
      manage_product: 'Управлять товаром',
      menu_save: 'Сохранить меню',
      menu_add_new_section: 'Добавить новую секцию',
      menu_parse_products: 'Загрузить продукты',
      menu_manage_products: 'Управлять продуктами',
      menu_edit_section_title: 'Изменить название секции',
      add: 'Добавить',
      back: 'Назад',
      bulk_actions: '%{smart_count} элементов выбрано',
      cancel: 'Отмена',
      clear_input_value: 'Почисть занчение в поле',
      clone: 'Скопировать',
      confirm: 'Подтвердить',
      create: 'Создать',
      delete: 'Удалить',
      edit: 'Изменить',
      export: 'Выгрузить',
      list: 'Список',
      refresh: 'Обновить',
      remove_filter: 'Удалить фильтр',
      remove: 'удалить',
      save: 'Сохранить',
      search: 'Поиск',
      stop: 'Стоп',
      resume: 'Продолжить',
      show: 'Показать',
      sort: 'Сортировать',
      undo: 'Отменить',
      expand: 'Раскрыть',
      close: 'Закрыть',
      export_csv: 'Скачать как .csv',
      select_section: 'Выбрать раздел',
      remove_section: 'Удалить раздел',
      remove_discount: 'Удалить скидку',
      set_discount: 'задать скидку',
      select_time_range: 'Выбрать промежуток времени',
      process_transaction: 'Обработать транзакцию',
      export_monthly_report: 'Экспорт ежемесячного отчета',
      generate_report: 'Создать отчет',
      give_bonus: 'Дать бонус',
      select_all: 'Выбрать все',
      remove_all: 'удалить все',
      price_edit: 'Изменить цену',
      edit_schedule: 'Изменить расписание',
      use_schedule: 'Использовать расписание',
    },
    status: {
      button: {
        processing: 'Обработать',
        confirmed: 'Подтвердить',
        preparing: 'Готовится',
        prepared: 'Готов',
        delivering: 'Доставляется',
        delivered: 'Доставлен',
        accepted: 'Принять',
        canceled: 'Отменить',
      },
      new: 'Новый',
      accepted: 'Принят',
      canceled: 'Отменен',
      processing: 'Обрабатывается',
      confirmed: 'Подтвержден',
      preparing: 'Готовится',
      prepared: 'Приготовлен',
      delivering: 'Доставляется',
      delivered: 'Доставлен',
      awaiting_payment: 'Ожидание оплаты',
      payment_failed: 'Платеж не прошел',
      courier_assign: 'Курьер назначен',
      courier_assigned: 'Курьер назначен',
      courier_reassign: 'Курьер переназначен',
      courier_confirmed: 'Курьер подтвержден',
      courier_in_restaurant: 'Курьер в ресторане',
      in_progress: 'В процессе',
      scheduled: 'Запланированное',
      refused: 'Отказался',
    },
    boolean: {
      true: 'Да',
      false: 'Нет',
    },
    discount: {
      cannotHandleFilter: 'Фильтры типа %{type} не поддерживаются!',
      cannotHandlePerk: 'Привилегии типа %{type} не поддерживаются!',
      paymentFilter: {
        set: 'Выберите тип оплаты: ',
      },
      sumFilter: {
        set: 'Введите минимальную сумму: ',
      },
      percentPerk: {
        set: 'Введите кол-во процентов: ',
      },
      amountPerk: {
        set: 'Гуляем на все деньги: ',
      },
    },
    page: {
      create: 'Создается %{name}',
      dashboard: 'Доска',
      edit: '%{name} #%{id}',
      error: 'Что то пошле не так',
      list: '%{name}',
      loading: 'Загрузка',
      not_found: 'Не найдено',
      show: '%{name} #%{id}',
      empty: 'Элементы не найдены',
    },
    title: {
      courier_cost: 'Стоимость курьера',
      address_data: 'Данные адреса',
      map: 'Карта',
      order_instruction: 'Комментарий к заказу',
      instruction: 'Комментарий',
      client_data: 'Данные клиента',
      supplier_data: 'Данные поставщика',
      courier_data: 'Данные курьера',
      order_items: 'Элементы заказа',
      total_price: 'Общая стоимость',
      total_discounts: 'Промо',
      order_price: 'Стоимость заказа',
      delivery_price: 'Стоимость доставки',
      time_data: 'Данные времени',
      processing_time: 'Время обработки',
      preparing_time: 'Время готовки',
      delivery_time: 'Время доставки',
      total_time: 'Общее время',
      payment_method: 'Тип оплаты',
      porch: 'Подъезд',
      porch_code: 'Код домофона',
      floor: 'Этаж',
      apartment: 'Кв.',
      today: 'Сегодня',
      total: 'Всего',
      sum: 'Сумма',
      assign_courier: 'Назначить курьера',
      re_assign_courier: 'Переназначить курьера',
      not_confirmed_courier: 'Курьер не подтвердил заказ. Переназначить',
      show_order: 'Показать заказ',
      edit_order: 'Изменить заказ',
      reports: 'Отчеты',
      select_courier: 'Выбрать курьера',
      comment_order: 'Добавить комментарий к заказу',
      order_staff_comment: 'Комментарий персонала',
      status_history: 'История Статусов',
      fiscal_difference: 'Фискальная Разница',
      block: {
        address: 'Адрес',
        order_staff_comment: 'Коментарий персонала',
        order_comment: 'Коментарий к заказу',
        client_data: 'Клиент',
        supplier_data: 'Поставщик',
        courier_data: 'Курьер',
        order_items: 'Элементы заказа',
        price: 'Цена',
        time_data: 'Время',
        payment_method: 'Оплата',
        cancel_reason: 'Причина отмены',
      },
      add_new_item: 'Добавить новый продукт',
      select_item: 'Выберите продукт',
    },
    message: {
      no_number: 'Hету телефонного номера',
      menu_activate: 'Вы уверены что хотите активировать ',
      menu_deactivate: 'Вы уверены что хотите деактивироать ',
      about: 'Подробнее',
      are_you_sure: 'Вы уверены?',
      user_is_not_referral: 'Пользователь не был приглашен',
      bulk_delete_content:
        'Вы уверены что хотите удалить %{name}? |||| Вы уверены что хотите удалить %{smart_count} элементов?',
      bulk_delete_title: 'Удалить %{name} |||| Удалить %{smart_count} %{name}',
      delete_content: 'Вы уверены что хотите удалить этот элемент?',
      delete_title: 'Удалить %{name} #%{id}',
      details: 'Подробнее',
      error: 'Произошла ошибка клиента, и ваш запрос не может быть выполнен.',
      invalid_form: 'Форма не валидна проверьте ошибки.',
      loading: 'Загрузка страницы.',
      no: 'Нет',
      not_found: 'Либо вы ввели неправильный URL, либо перешли по неверной ссылке.',
      yes: 'Yes',
      cancel_order_confirm: 'Вы уверены что хотите отменить этот заказ?',
      yes_cancel_order: 'Да, отменить заказ',
      not_cancel_order: 'Нет, закрыть это окно',
      set_time: 'Установите время приготовления',
      scheduled_at: 'Заказ на',
      invalid_login: 'Неправильный логин или пароль',
    },
    navigation: {
      no_results: 'Не найдено результатов.',
      no_more_results: 'Страница с номером %{page} не существует. Попробуйте предыдущию страницу.',
      page_out_of_boundaries: 'Страница с номером %{page} не существует.',
      page_out_from_end: 'Это больше последней страницы',
      page_out_from_begin: 'Это меньше первой страницы',
      page_range_info: '%{offsetBegin}-%{offsetEnd} из %{total}',
      page_rows_per_page: 'Записей на странице:',
      next: 'Следующая',
      prev: 'Предыдущая',
      min: 'мин',
      distance: 'Дистанция',
      km: 'км',
      m: 'м',
      money: 'бани',
      menu_management: 'Управление Меню',
      marketing: 'Маркетинг',
      cms: 'CMS',
      user_management: 'Управление пользователями',
      delivery_management: 'Настройки доставки',
      supplier_management: 'Управление поставщиками',
      payment_config: 'Настройки платежей',
    },
    auth: {
      user_menu: 'Профиль',
      username: 'Имя пользователя',
      password: 'Пароль',
      sign_in: 'Войти',
      sign_in_error: 'Ошибка авторизации. Пожалуйста, повторите',
      logout: 'Выход',
    },
    notification: {
      updated: 'Элемнет был обновлен |||| %{smart_count} элементов было обновлено',
      created: 'Элемент был создан',
      deleted: 'Элемент был удален |||| %{smart_count} элементa/ов было удалено',
      bad_item: 'Bad item',
      item_doesnt_exist: 'Элемент не существует',
      http_error: 'Ошибка связи с сервером',
      data_provider_error: 'Ошибка дата провайдера. Подробности смотрите в консоли.',
      canceled: 'Действие отменено',
      logged_out: 'Ваша сессия закончилась, пожалуйста, подключитесь повторно.',
      unsaved_changes: 'Несохраненные изменения!',
    },
    validation: {
      required: 'Обязательно',
      minLength: 'Min length %{min} characters',
      maxLength: 'Max length %{max} characters',
      minValue: 'It must be minimal %{min}',
      maxValue: 'It must be maximum %{max}',
      number: 'Number required',
      email: 'Email not valid',
      oneOf: 'Must be one of: %{options}',
      regex: 'Must match a specific format (regexp): %{pattern}',
    },
  },
  resources: {
    reportsDiscount: {
      name: 'Отчет о Cкидках',
    },
    navigation: {
      name: 'Hавигационное меню',
    },
    'delivery/today': {
      name: 'Заказы',
    },
    'delivery/history': {
      name: 'История Заказов',
    },
    supplier: {
      name: 'Поставщики',
    },
    address: {
      name: 'Адреса',
    },
    store: {
      name: 'Stores',
    },
    'store-supplier': {
      name: 'Store Suppliers',
    },
    goods: {
      name: 'Продукты',
    },
    variants: {
      name: 'Варианты',
    },
    category: {
      name: 'Категории',
    },
    tag: {
      name: 'Теги',
    },
    optionGroup: {
      name: 'Группы опций',
    },
    option: {
      name: 'Опции',
    },
    ingredient: {
      name: 'Ингредиенты',
    },
    user: {
      name: 'Пользователи',
    },
    useraddress: {
      name: 'Адреса пользователей',
    },
    orderitem: {
      name: 'Элементы заказа',
    },
    courier: {
      name: 'Курьеры',
    },
    transaction: {
      name: 'Транзакции',
    },
    menu: {
      name: 'Меню',
    },
    paymentConfig: {
      name: 'Настройки оплаты',
    },
    posts: {
      fields: { tag: 'Теги' },
    },
    discount: {
      name: 'Скидки',
    },
    deliverySettings: {
      name: 'Настройки доставки',
    },
    policy: {
      name: 'Курьерские выплаты',
    },
    'marketing-campaigns': {
      name: 'Кампания',
    },
    'marketing-campaign-runs': {
      name: 'Начать кампанию',
    },
    restaurant: {
      name: 'Рестораны',
    },
  },
};

export default messages;
