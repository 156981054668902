import React, { useMemo } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Card } from '@mui/material';
import { ESTIMATED_PROCESSING_TIME } from '../../config';
import formatDateTime from '../../../../../utils/formatDateTime';
import StatusLabel from '../../../../../components/StatusLabel';
import { DELIVERY_STATUS_NAMES as OSN } from '../../../../../config/statuses.config';
import useCommonStyles from '../../styles';
import useStyles from './styles';

const TimeInfo = () => {
  const commonCls = useCommonStyles();
  const styles = useStyles();
  const translate = useTranslate();
  const record = useRecordContext();
  const { preparationDetails, deliveryDetails } = record;

  return (
    <Card className={commonCls.cardStyle}>
      <div className={styles.statusHistory}>
        <div className={styles.sectionTitle}>{translate('ra.title.status_history')}</div>
        <div>
          {record?.deliveryStatusLogs.map(({ deliveryStatus, timestamp }) => {
            const { time } = formatDateTime(timestamp);

            return (
              <div key={timestamp} className={styles.statusHistoryItem}>
                <StatusLabel status={deliveryStatus} className={styles.statusName} />
                <span>: </span>
                <span>{time}</span>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default TimeInfo;
