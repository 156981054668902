import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  active: () => ({
    '&:hover': {
      backgroundColor: 'rgba(76,187,23, 0.6)',
    },
    backgroundColor: 'rgba(76,187,23, 1)',
  }),
  inactive: () => ({
    '&:hover': {
      backgroundColor: 'rgba(255, 32, 47, 0.6)',
    },
    backgroundColor: 'rgba(255, 32, 47, 1)',
  }),
});

export default useStyles;
