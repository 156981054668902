import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  titleWrapper: {
    padding: '25px 15px',
  },
  title: {
    fontSize: '20px',
    opacity: '0.8',
  },
  cancelBtn: {
    color: '#F44336',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },
  commentInput: {
    width: '90%',
  },
  comment: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default useStyles;
