import React from 'react';
import {
  BooleanInput,
  Create,
  minValue,
  number,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  useTranslate,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import { SORT_OPTIONS_CHOICES } from '../../constants';

const validateRequired = required();
const validateMinNumber = (min = 0) => [required(), number(), minValue(min)];

const CourierAutomationSettingsCreate = (props) => {
  const translate = useTranslate();

  return (
    <Create {...props} actions={<CancelButton />} redirect="list">
      <SimpleForm redirect="list">
        <BooleanInput label="ra.action.enable" source="enable" />
        <NumberInput
          label={`${translate('ra.label.maxDistanceNearPickUpLocation')} km`}
          source="maxDistanceNearPickUpLocation"
          validate={validateMinNumber()}
          parse={(v) => v * 1000 || ''}
          format={(v) => v / 1000 || ''}
        />
        <NumberInput
          source="maxDistanceNearDestination"
          label={`${translate('ra.label.maxDistanceNearDestination')} km`}
          validate={validateMinNumber()}
          parse={(v) => v * 1000 || ''}
          format={(v) => v / 1000 || ''}
        />
        <NumberInput
          source="maxDistanceCoincidentalOrders"
          label={`${translate('ra.label.maxDistanceCoincidentalOrders')} km`}
          validate={validateMinNumber()}
          parse={(v) => v * 1000 || ''}
          format={(v) => v / 1000 || ''}
        />
        <NumberInput
          source="maxOrdersPerCourier"
          label={translate('ra.label.maxOrdersPerCourier')}
          validate={validateMinNumber(1)}
        />
        <NumberInput
          source="retryTime"
          label={`${translate('ra.label.retryTime')} sec.`}
          validate={validateMinNumber()}
          parse={(v) => v * 1000 || ''}
          format={(v) => v / 1000 || ''}
        />
        <NumberInput
          source="timeAwaitingCourierConfirmation"
          label={`${translate('ra.label.timeAwaitingCourierConfirmation')} sec.`}
          validate={validateMinNumber()}
          parse={(v) => v * 1000 || ''}
          format={(v) => v / 1000 || ''}
        />
        <NumberInput
          source="timeIgnoreCourier"
          label={`${translate('ra.label.timeIgnoreCourier')} sec.`}
          validate={validateMinNumber()}
          parse={(v) => v * 1000 || ''}
          format={(v) => v / 1000 || ''}
        />
        <NumberInput
          source="multiplierModifier"
          label={`${translate('ra.label.multiplierModifier')} <1`}
          validate={validateMinNumber()}
        />
        <NumberInput
          source="intervalGetCourierLocation"
          label={`${translate('ra.label.intervalGetCourierLocation')} sec.`}
          validate={validateMinNumber()}
          parse={(v) => v * 1000 || ''}
          format={(v) => v / 1000 || ''}
        />
        <SelectInput
          label="ra.label.sortOptions"
          source="sortOptions"
          choices={SORT_OPTIONS_CHOICES}
          validate={validateRequired}
        />
      </SimpleForm>
    </Create>
  );
};

export default CourierAutomationSettingsCreate;
