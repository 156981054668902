export const fetchWithAuthorization = (url, { headers, ...options } = {}) => {
  const token = localStorage.getItem('token');
  const optionHeaders = headers || {};

  return fetch(url, {
    headers: {
      ...optionHeaders,
      Authorization: `Bearer ${token}`,
    },
    ...options,
  });
};

const sendWithAuthorization = (url, { headers, ...options } = {}) => {
  const optionHeaders = headers || {};

  return fetchWithAuthorization(url, {
    headers: {
      ...optionHeaders,
      'Content-Type': 'application/json',
    },
    ...options,
  });
};

export const postWithAuthorization = (url, options = {}) => {
  options.method = 'POST';

  return sendWithAuthorization(url, options);
};

export const deleteWithAuthorization = (url, options = {}) => {
  options.method = 'DELETE';

  return sendWithAuthorization(url, options);
};

export const patchWithAuthorization = (url, options = {}) => {
  options.method = 'PATCH';

  return sendWithAuthorization(url, options);
};

export const putWithAuthorization = (url, options = {}) => {
  options.method = 'PUT';

  return sendWithAuthorization(url, options);
};
