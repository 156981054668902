import React from 'react';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useRefresh, useUpdate } from 'react-admin';
import url from '../../../config/connection';
import { patchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import AssignCourierButton from '../AssignCourierButton';

const DeliveryActions = ({
  isCurrentUserAdmin,
  isCurrentCourierAssignStatus,
  isStandardCourierSource,
  courierConfirmed,
  record,
}) => {
  const refresh = useRefresh();
  const [update] = useUpdate();

  const unassignCourier = (orderId) => {
    update(
      'order',
      {
        id: orderId,
        data: { ...record, courier: null },
        previousData: record,
      },
      {
        onSuccess: () => {
          patchWithAuthorization(`${url}/delivery/unassign`, {
            body: JSON.stringify({ deliveryRequestId: orderId }),
          }).then(() => {
            refresh();
          });
        },
      },
    );
  };

  const hasCourier = Boolean(record.courier);
  const canAssign = isCurrentUserAdmin && isCurrentCourierAssignStatus && isStandardCourierSource;

  return (
    <>
      {canAssign && (
        <>
          {hasCourier && (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                unassignCourier(record.id);
              }}
            >
              <ClearIcon />
            </IconButton>
          )}
          <AssignCourierButton
            isReAssign={hasCourier}
            isCourierConfirmed={courierConfirmed}
            order={record}
          />
          {/* <RefuseOrderButton order={record} /> */}
        </>
      )}
      {/* <DeliveryColumn record={record} /> */}
    </>
  );
};

export default DeliveryActions;
