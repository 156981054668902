import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const OrganizationCard = ({ handleClickOpen, organization }) => {
  const { slug, name, id, geo, deletedAt } = organization;

  return (
    <Card sx={{ minWidth: 200, margin: '10px' }}>
      <CardActionArea
        sx={{ minHeight: 150, backgroundColor: '#b9b8b85c' }}
        onClick={() => handleClickOpen(slug, id, geo, deletedAt)}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {slug}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default OrganizationCard;
