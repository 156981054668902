const RESOURCES_NAMES = {
  DELIVERY: 'delivery/today',
  DELIVERY_HISTORY: 'delivery/history',
  DELIVERY_REQUEST: 'delivery/request-delivery',
  // SUPPLIER: 'supplier',
  // ADDRESS: 'address',
  USER: 'user',
  COURIER: 'courier',
  COURIER_AUTOMATION_SETTINGS: 'courier-auto-settings',
  DELIVERY_SETTINGS: 'deliverySettings',
  POLICY: 'policy',
  WEB_HOOKS: 'webhook',
  ORGANIZATION: 'organization',
  API_KEYS: 'api-access',
  RESTAURANTS: 'restaurant',
};

export default RESOURCES_NAMES;
