import React from 'react';
import {
  SimpleForm,
  Create,
  TextInput,
  required,
  minLength,
  regex,
  email,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import PhoneInput from '../../../components/PhoneInput';
import { USERNAME_REGEX } from '../../../components/PhoneInput/utils';
import ResourceTitle from '../../../components/ResourceTitle';

const validateRequired = required();
const validateUsernameRegex = regex(USERNAME_REGEX.pattern, USERNAME_REGEX.message);
const validateUsername = [validateRequired, minLength(2), validateUsernameRegex];
const validateEmail = [validateRequired, email()];

const UserCreate = (props) => {
  return (
    <Create
      {...props}
      title={<ResourceTitle field="username" />}
      actions={<CancelButton />}
      redirect="list"
    >
      <SimpleForm>
        <TextInput
          label="ra.label.user_name"
          source="username"
          sx={{ maxWidth: '300px', width: '100%' }}
          validate={validateUsername}
        />
        <TextInput
          label="ra.label.email"
          source="email"
          sx={{ maxWidth: '300px', width: '100%' }}
          validate={validateEmail}
        />
        <TextInput
          label="ra.label.first_name"
          source="firstName"
          sx={{ maxWidth: '300px', width: '100%' }}
        />
        <TextInput
          label="ra.label.last_name"
          source="lastName"
          sx={{ maxWidth: '300px', width: '100%' }}
        />
        <PhoneInput source="phone" sx={{ maxWidth: '300px', width: '100%' }} shouldValidate />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
