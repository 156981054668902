import React from 'react';
import { Link } from 'react-router-dom';
import { Button, useTranslate } from 'react-admin';
import { withStyles } from '@mui/styles';

const styles = {
  button: {
    marginTop: '1em',
  },
};

const ReportLink = () => {
  const translate = useTranslate();

  return (
    <Button
      component={Link}
      to="/report"
      label={translate('ra.title.reports')}
      style={{
        color: '#E0777D',
      }}
      title={translate('ra.title.reports')}
    />
  );
};

export default withStyles(styles)(ReportLink);
