import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import { ReactComponent as AssignIcon } from './icons/assignIcon.svg';
import { ReactComponent as ReAssignIcon } from './icons/reassignIcon.svg';
import { ReactComponent as NotConfirmedIcon } from './icons/notConfirmedIcon.svg';
import { ReactComponent as CantAssign } from './icons/cantAssign.svg';
import { setCourierAssignOrder } from '../../../redux/customReducers/utils';
import useStyles from './styles';

const AssignCourierButton = ({ isReAssign, isCourierConfirmed, order, setOrder }) => {
  const courier = useMemo(() => order.courier, [order]);
  const styles = useStyles();
  const translate = useTranslate();

  const canAssignStatusList = {
    NEW: true,
    DELIVERING: false,
    DELIVERED: false,
    ACCEPTED: true,
    CANCELED: false,
    REFUSED: false,
    COURIER_ASSIGNED: true,
    COURIER_CONFIRMED: false,
    COURIER_REJECTED: true,
    COURIER_IS_IN_RESTAURANT: false,
  };

  const canAssign = canAssignStatusList[order.deliveryStatus];

  let button = {
    tooltip: 'ra.title.assign_courier',
    icon: AssignIcon,
  };

  if (isReAssign) {
    button = {
      tooltip: 'ra.title.re_assign_courier',
      icon: ReAssignIcon,
    };
  }

  if (isReAssign && !isCourierConfirmed) {
    button = {
      tooltip: 'ra.title.not_confirmed_courier',
      icon: NotConfirmedIcon,
    };
  }

  const canReassign = {
    NEW: true,
    DELIVERING: false,
    DELIVERED: false,
    ACCEPTED: true,
    CANCELED: false,
    REFUSED: false,
    COURIER_ASSIGNED: true,
    COURIER_CONFIRMED: false,
    COURIER_REJECTED: true,
    COURIER_IS_IN_RESTAURANT: false,
  };

  if (order.deliveryUserId && canReassign[order.deliveryStatus]) {
    button = {
      tooltip: 'ra.title.re_assign_courier',
      icon: ReAssignIcon,
    };
  }

  const Icon = button.icon;

  if (canAssign) {
    return (
      <Tooltip
        title={
          <>
            <div>{translate(button.tooltip)}</div>
            {!!courier && isReAssign && (
              <div>
                {`${translate('ra.label.current_courier')}: ${courier.firstName} ${courier.lastName}`}
              </div>
            )}
          </>
        }
      >
        <IconButton
          className={styles.root}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setOrder(order);
          }}
        >
          <Icon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={

        <div>
          {`${translate('ra.label.cant_assign_courier')}`}
        </div>

      }
    >
      <IconButton
        className={styles.root}
        size="small"
      >
        <CantAssign />
      </IconButton>
    </Tooltip>
  );
};

const mapDispatch = { setOrder: setCourierAssignOrder };

export default connect(null, mapDispatch)(AssignCourierButton);
