import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import ActivationButton from '../ActivationButton';
import { useTableStyles } from '../../styles';

const CourierPayoutPolicyList = () => {
  const organizationId = localStorage.getItem('organizationId');

  const style = useTableStyles();

  return (
    <List filter={{ organizationId }}>
      <Datagrid
        className={style.tableMain}
        rowClick="show"
        bulkActionButtons={<ListDeleteConfirmationButton />}
      >
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.name" source="name" />
        <TextField label="ra.label.courier_logout_interval" source="courierLogoutInterval" />
        <TextField label="ra.label.bill_photo_config" source="billPhotoConfiguration" />
        <ActivationButton label="ra.label.active" source="isActive" />
      </Datagrid>
    </List>
  );
};

export default CourierPayoutPolicyList;
