import React from 'react';
import { TextField } from '@mui/material';

const TextInput = ({ name, label, control, disabled, onChange, value, type }) => {
  return (
    <TextField
      variant="standard"
      autoComplete={type === 'password' ? 'current-password' : 'username'}
      type={type || 'text'}
      autoFocus
      disabled={disabled}
      label={label}
      onChange={onChange}
      value={value}
      name={name}
      fullWidth
    />
  );
};

export default TextInput;
