import React from 'react';
import { IconButton } from '@mui/material';
import { ReactComponent as ClientIcon } from '../icons/Client.svg';
import useStyles from './styles';

const ClientPlacemark = () => {
  const styles = useStyles();

  return (
    <div>
      <IconButton className={styles.icon}>
        <ClientIcon />
      </IconButton>
    </div>
  );
};

export default ClientPlacemark;
