import WebHooksCreate from './components/Create';
import WebHooksEdit from './components/Edit';
import WebHooksList from './components/List';
import WebHooksShow from './components/Show';

export default {
  list: WebHooksList,
  create: WebHooksCreate,
  edit: WebHooksEdit,
  show: WebHooksShow,
};
