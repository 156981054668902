import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      minWidth: '25px',
      '& > *': {
        minWidth: '25px',
      },
    },
  });
});

export default useStyles;
