import React, { useEffect, useState } from 'react';
import { RecordContextProvider } from 'react-admin';
import cn from 'classnames';
import { TableCell, TableRow } from '@mui/material';
import { connect } from 'react-redux';
import { setExpandedOrderDropdown } from '../../../../../redux/customReducers/utils';
import useStyles from './styles';
import OrderDropdown from '../../../OrderDropdown';

const computeColSpan = (children) => {
  const colNum = React.Children.toArray(children).filter((child) => !!child).length;

  return colNum;
};

const DatagridRow = ({ ...props }) => {
  const {
    id,
    children,
    className,
    style,
    record,
    expandedIds,
    setExpandedOrderDropdown: setExpanded,
  } = props;
  const [colSpan, setColSpan] = useState(computeColSpan(children));
  const styles = useStyles();

  const isExpanded = expandedIds[id];

  useEffect(() => {
    const newColSpan = computeColSpan(children);
    if (newColSpan !== colSpan) {
      setColSpan(newColSpan);
    }
  }, [children, colSpan]);

  return (
    <RecordContextProvider value={record}>
      <TableRow
        key={id}
        style={style}
        classes={cn(className, styles.row)}
        onClick={() => setExpanded(id)}
      >
        {React.Children.map(children, (field) => {
          return <TableCell key={`${id}-${field?.props.label}`}>{field}</TableCell>;
        })}
      </TableRow>
      {isExpanded && (
        <TableRow key={`${id}-expand`} id={`${id}-expand`}>
          <TableCell colSpan={colSpan} className={styles.dropdown}>
            <OrderDropdown record={record} />
          </TableCell>
        </TableRow>
      )}
    </RecordContextProvider>
  );
};

const mapState = (state) => ({
  expandedIds: state.utils.expandedOrderDropdowns,
});

const mapDispatch = { setExpandedOrderDropdown };

export default connect(mapState, mapDispatch)(DatagridRow);
