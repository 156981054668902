const SET_ORDER_COMMENT_ID = 'SET_ORDER_COMMENT_ID';
const SET_EXPANDED_ORDER_DROPDOWN = 'SET_EXPANDED_ORDER_DROPDOWN';
const SET_ORDER_GEO = 'SET_ORDER_GEO';
const SET_COURIER_ASSIGN_ORDER = 'SET_COURIER_ASSIGN_ORDER';
const SET_REFUSED_ORDER = 'SET_REFUSED_ORDER';

const initialState = {
  orderComment: {
    orderId: null,
  },
  expandedOrderDropdowns: {},
  orderGeo: null,
  courierAssignOrder: null,
  refuseOrder: null,
};

export const setOrderCommentId = (id) => ({ type: SET_ORDER_COMMENT_ID, payload: id });

export const setOrderGeo = (geo) => ({ type: SET_ORDER_GEO, payload: geo });

export const setCourierAssignOrder = (order) => ({
  type: SET_COURIER_ASSIGN_ORDER,
  payload: order,
});

export const setRefusedOrder = (order) => ({
  type: SET_REFUSED_ORDER,
  payload: order,
});

export const setExpandedOrderDropdown = (id) => ({
  type: SET_EXPANDED_ORDER_DROPDOWN,
  payload: id,
});

// eslint-disable-next-line default-param-last
export default (state = initialState, { payload, type }) => {
  const actions = {
    [SET_ORDER_COMMENT_ID]: () => ({
      ...state,
      orderComment: {
        ...state.orderComment,
        orderId: payload,
      },
    }),
    [SET_EXPANDED_ORDER_DROPDOWN]: () => ({
      ...state,
      expandedOrderDropdowns: {
        ...state.expandedOrderDropdowns,
        [payload]: !state.expandedOrderDropdowns[payload],
      },
    }),
    [SET_ORDER_GEO]: () => ({
      ...state,
      orderGeo: payload,
    }),
    [SET_COURIER_ASSIGN_ORDER]: () => ({
      ...state,
      courierAssignOrder: payload,
    }),
    [SET_REFUSED_ORDER]: () => ({
      ...state,
      refuseOrder: payload,
    }),
    default: () => state,
  };

  const actionHandler = actions[type] || actions.default;

  return actionHandler();
};
