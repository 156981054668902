import React from 'react';
import {
  Button,
  Edit,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useEditController,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import ResourceTitle from '../../../components/ResourceTitle';
import CancelButton from '../../../components/CancelButton';
import url from '../../../config/connection';
import {
  deleteWithAuthorization,
  patchWithAuthorization,
} from '../../../utils/fetchWithAuthorization';
import LatLngInput from '../../../components/LatLngInput';

const validateRequired = required();

const EditToolbar = (props) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const translate = useTranslate();

  const isActive = Boolean(!record?.deletedAt);

  const deactivateOrganizationHandler = async () => {
    if (isActive) {
      await deleteWithAuthorization(`${url}/organization/${record?.id}`);
      refresh();
    } else {
      await patchWithAuthorization(`${url}/organization/${record?.id}/recover`);
      refresh();
    }
  };

  return (
    <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton alwaysEnable={isActive} disabled={!isActive} />
      <Button
        label={translate(
          isActive ? 'ra.action.deactivate_organization' : 'ra.action.reactivate_organization',
        )}
        color={isActive ? 'error' : 'primary'}
        variant="contained"
        onClick={deactivateOrganizationHandler}
      />
    </Toolbar>
  );
};

const OrganizationEdit = () => {
  const { record } = useEditController();

  if (!record) {
    return 'Loading...';
  }

  return (
    <Edit title={<ResourceTitle field="firstName" />} actions={<CancelButton />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput label="ra.label.name" source="name" validate={validateRequired} />
        <LatLngInput />
      </SimpleForm>
    </Edit>
  );
};

export default OrganizationEdit;
