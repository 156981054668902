import React from 'react';
import {
  FunctionField,
  Show,
  TabbedShowLayout,
  Tab,
  BooleanField,
  useTranslate,
  TextField,
} from 'react-admin';
import SessionTable from '../SessionTable';

const CourierAutomationSettingsShow = () => {
  const translate = useTranslate();

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="ra.tab.courier_automation_settings">
          <FunctionField
            label={`${translate('ra.label.maxDistanceNearPickUpLocation')} km`}
            render={({ maxDistanceNearPickUpLocation }) => maxDistanceNearPickUpLocation / 1000}
          />
          <FunctionField
            label={`${translate('ra.label.maxDistanceNearDestination')} km`}
            render={({ maxDistanceNearDestination }) => maxDistanceNearDestination / 1000}
          />
          <FunctionField
            label={`${translate('ra.label.maxDistanceCoincidentalOrders')} km`}
            render={({ maxDistanceCoincidentalOrders }) => maxDistanceCoincidentalOrders / 1000}
          />
          <TextField
            label={translate('ra.label.maxOrdersPerCourier')}
            source="maxOrdersPerCourier"
          />
          <FunctionField
            label={`${translate('ra.label.retryTime')} sec.`}
            render={({ retryTime }) => retryTime / 1000}
          />
          <FunctionField
            source="timeAwaitingCourierConfirmation"
            label={`${translate('ra.label.timeAwaitingCourierConfirmation')} sec.`}
            render={({ timeAwaitingCourierConfirmation }) => timeAwaitingCourierConfirmation / 1000}
          />
          <FunctionField
            label={`${translate('ra.label.timeIgnoreCourier')} sec.`}
            render={({ timeIgnoreCourier }) => timeIgnoreCourier / 1000}
          />
          <TextField
            source="multiplierModifier"
            label={`${translate('ra.label.multiplierModifier')} <1`}
          />
          <FunctionField
            label={`${translate('ra.label.intervalGetCourierLocation')} sec.`}
            render={({ intervalGetCourierLocation }) => intervalGetCourierLocation / 1000}
          />
          <TextField label="ra.label.sortOptions" source="sortOptions" />
          <BooleanField label="ra.action.enable" source="enable" />
        </Tab>
        <Tab label="ra.tab.sessions_table">
          <SessionTable />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CourierAutomationSettingsShow;
